import { Box, CssBaseline, CssVarsProvider, extendTheme } from "@mui/joy";
import React, { useEffect, useState } from "react";
import contants from "../configs/constants";
import Header from "../Vodcast/components/Header";
import theme from "../configs/theme";
import SelectVideoDialog from "../Vodcast/UploadVideoDialogs/SelectVideoDialog";
import HeaderProfessional from "../Professional/hederPopup.component";
import ProHeader from "../Professional/Professional_header";
import constants from "../configs/constants";
import useUserValidate from "../components/Hooks/useUserValidate";

function CommonLayout({
  children,
  showUpload,
  setShowUpload,
  setVideoStatus,
  onUploadedCallback = null,
}) {
  useUserValidate();
  // const [showUplaod, setShowUpload] = useState(false);
  // useEffect(() => {
  //   if (openUploadFlow) {
  //     setShowUpload(true);
  //   }
  // }, [openUploadFlow]);

  const [show, setShow] = useState(false);

  return (
    <CssVarsProvider disableTransitionOnChange theme={theme} defaultMode="dark">
      <CssBaseline />
      {/* <Header /> */}
      <ProHeader
        styles={{ backgroundColor: constants.colors.secondary }}
        isVodcast={true}
      />

      {showUpload && (
        <SelectVideoDialog
          onClose={() => setShowUpload(false)}
          onSuccess={onUploadedCallback}
          setVideoStatus={setVideoStatus}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          overflow: "hidden",
          overflowY: "scroll",
          backgroundColor: contants.colors.secondary,
          // paddingTop: { xs: 10, sm: 10, md: 10, lg: 12 },
        }}
      >
        <div
          id="scrollableDiv"
          style={{
            overflowY: "scroll",
            height: "100vh",
            paddingBottom: "20vh",
          }}
        >
          {children}
        </div>
      </Box>
    </CssVarsProvider>
  );
}

export default CommonLayout;
