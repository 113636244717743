import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useRecoilState } from "recoil";
import { videoListState, playState } from "../atoms";

function Video(props) {
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [play, setPlay] = useRecoilState(playState);
  const [time, setTime] = useState(0);
  const videoNode = useRef(null);

  useEffect(() => {
    document
      .getElementById("editorTimer")
      .addEventListener("timeupdate", (event) => {
        setTime(document.getElementById("editorTimer").currentTime * 1000);
      });
  }, []);

  // useEffect(() => {
  //   if (videoNode.current) {
  //     const player = videojs(videoNode.current, {
  //       autoplay: false,
  //       preload: "auto",
  //       sources: [
  //         {
  //           src: videoList[0]?.src,
  //           type: "video/mp4",
  //         },
  //       ],
  //     });

  //     // Cleanup the player on unmount
  //     return () => {
  //       if (player) {
  //         player.dispose();
  //       }
  //     };
  //   }
  // }, []);

  useEffect(() => {
    if (videoNode.current) {
      const player = videojs(videoNode.current);

      // Find the video that should be currently playing
      const currentVideo = videoList.find(
        (video) => time >= video?.startTime && time <= video?.endTime
      );

      if (currentVideo) {
        const currentSrc = player.currentSrc();
        if (currentSrc !== currentVideo.src) {
          // Avoid changing the source if it's the same
          player.src({
            src: currentVideo.src,
            type: "video/mp4",
          });
        }

        // Adjust video's current time based on the `time` state, the video's `startTime`, and `trimmStart`
        const adjustedTime =
          (time - currentVideo.startTime + currentVideo.trimmStart) / 1000;
        const currentTimeDifference = Math.abs(
          player.currentTime() - adjustedTime
        );

        // Update the player's time only if the difference exceeds the threshold
        if (currentTimeDifference > 0.3) {
          setPlay(false);
          player.currentTime(adjustedTime);
        }
      }

      if (time > videoList[videoList.length - 1]?.endTime) {
        player.currentTime(0);
      }

      // Pause the video if it reaches the trimmEnd time
      /* player.on('timeupdate', () => {
        if (currentVideo && player.currentTime() * 1000 >= (currentVideo.endTime - currentVideo.trimmEnd)) {
          player.pause();
          setPlay(false);
        }
      });*/
    }
  }, [time, videoList]);

  useEffect(() => {
    if (videoNode.current) {
      const player = videojs(videoNode.current);
      if (play) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [play]);

  return (
    <React.Fragment>
      <div>
        <video
          ref={videoNode}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default Video;
