import React, { useEffect, useState } from "react";
import { videoListState, elementState } from "../atoms";
import { useRecoilState } from "recoil";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import CustomModal from "./CustomModal";

const VideoEditor = () => {
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [isRemoving, setIsRemoving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [element, setElement] = useRecoilState(elementState);

  useEffect(() => {
    if (isRemoving) {
      const interval = setInterval(() => {
        if (progress < 99) {
          setProgress(progress + 0.5);
        } else {
          setProgress(10);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  });

  async function removeBackground() {
    let blob = await fetch(videoList[element].src).then((r) => r.blob());
    var formData = new FormData();
    formData.append("video", blob, "video");
    if (isRemoving === false) {
      setIsRemoving(true);
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/rbvideo", formData, {
          responseType: "blob",
        })
        .then((response) => {
          let vL = cloneDeep(videoList);
          vL[element].src = URL.createObjectURL(response.data);
          vL[element].endTime = 5000;
          setVideoList(vL);
          setIsRemoving(false);
        });
    }
  }

  function closeModal() {
    setIsRemoving(false);
  }

  return (
    <div>
      {/* <CustomModal
        isOpen={isRemoving}
        onRequestClose={closeModal}
        title="Removing Video Background"
        message="Unlike other platforms, we provide this service completely free of charge. If you appreciate our service, consider sharing our website on social media!"
        estimatedTime={180}
      /> */}

      {/* {videoList[element] != undefined ?
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Button onClick={() => removeBackground()} variant="primary">{<div>Remove Background</div>}</Button>{' '}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <video style={{ maxWidth: "80%", }} src={videoList[element].src}></video>
                    </div>
                </div>
                : null
            } */}

      <br />
    </div>
  );
};

export default VideoEditor;
