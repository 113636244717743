// const CommentsBox = () => {

// }

// export default CommentsBox

import React, { useEffect, useState } from "react";
import { getData, postData, postData1 } from "../../../utils/httpMethods";
import { toast } from "react-toastify";
import { createNotification } from "../../../components/Hooks/CreateNotification";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { BsFillSendFill } from "react-icons/bs";
import { MdOutlineReply, MdDeleteForever, MdOutlineEdit } from "react-icons/md";

let socket;

const CommentsBox = ({
  currentUser,
  comments = [],
  onUpdate,
  setComments,
  vodcastId,
}) => {
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [visibleReplies, setVisibleReplies] = useState(null);
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");

  useEffect(() => {
    if (document.getElementsByClassName("postComment")[0]) {
      document.getElementsByClassName("postComment")[0].placeholder =
        "Write your comment";
    }
    if (document.getElementsByClassName("postBtn")[0]) {
      document.getElementsByClassName("postBtn")[0].style =
        "background: transparent; border: none;padding:0px";
      document.getElementsByClassName("postBtn")[0].innerHTML =
        '<img src="/send-plane-fill.png" alt="sent-it" style="width:20px;height:20px" />';
    }

    if (document.getElementsByClassName("replySection.replyBtn")[0]) {
      document.getElementsByClassName("replySection.replyBtn")[0].style =
        "display:none";
    }

    socket = io(
      (process.env.REACT_SOCKET_APP_BASE_URL ?? "http://localhost:3006") +
        "/socket_connection"
    );

    socket.on("notify_connect", () => console.log("Connected"));

    socket.emit("notify_setup", user);
  }, [user]);

  const handleToggleReplies = (commentId) => {
    setVisibleReplies(visibleReplies === commentId ? null : commentId);
  };

  const handleReplyToComment = (commentId) => {
    setReplyToCommentId(replyToCommentId === commentId ? null : commentId);
  };

  useEffect(() => {
    if (comments.length > 0) {
      let cdatas = comments.map((i) => ({
        userId: i?.user_id?._id,
        comId: i._id,
        fullName: i?.user_id?.full_name,
        avatarUrl:
          i?.user_id?.pic !== ""
            ? i?.user_id?.pic
            : `https://ui-avatars.com/api/name=${i?.user_id?.full_name}&background=random`,
        text: i.comment,
        replies: i?.replyComment?.map((r) => ({
          userId: r?.user_id?._id,
          comId: r._id,
          fullName: r?.user_id?.full_name,
          avatarUrl:
            r?.user_id?.pic !== ""
              ? r?.user_id?.pic
              : `https://ui-avatars.com/api/name=${r?.user_id?.full_name}&background=random`,
          text: r.comment,
        })),
      }));
      setData(cdatas);
    }
  }, [comments]);

  function uploadComment() {
    if (commentText === "") return toast.warn("Please add any text!");

    createNotification(
      currentUser?._id,
      "Comment",
      `${user?.full_name} commented on your post`
    );

    let FD = new FormData();
    FD.append("comment", commentText);

    postData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/comment-post/${vodcastId}`,
      FD,
      "POST"
    ).then((data) => {
      if (data.status === true) {
        toast.success("Successfully commented");
        setComments(data.data);
        onUpdate && onUpdate();
        setCommentText("");
      } else {
        toast.error(data.message);
      }
    });
  }

  function replyComment(commentId) {
    if (replyText === "") return toast.warn("Please add any text!");

    createNotification(
      commentId,
      "Comment",
      `${user?.full_name} replied to your comment`
    );

    let FD = new FormData();
    FD.append("comment_id", commentId);
    FD.append("post_id", vodcastId);
    FD.append("replyComment", { user_id: user?._id, comment: replyText });

    const body = {
      comment_id: commentId,
      post_id: vodcastId,
      replyComment: { user_id: user?._id, comment: replyText },
    };

    postData1(
      `${process.env.REACT_APP_BASE_URL}/vodcast/reply-comment`,
      JSON.stringify(body)
    )
      .then((data) => {
        if (data.status) {
          toast.success("Successfully replied");
          onUpdate && onUpdate();
          setReplyText("");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const deleteComment = (id) => {
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/delete-comment/${vodcastId}/${id}`,
      "DELETE"
    )
      .then(({ data, status }) => {
        // if (status === true) {
        //   onUpdate && onUpdate();
        //   toast.success(data.message);
        // }
        console.log(data, "delete comment");
        if (data?.parentOfDeleteId) {
          deleteReply({
            reply_id: data?.comIdToDelete,
            comment_id: data?.parentOfDeleteId,
          });
        } else {
          deleteComment(data?.comIdToDelete);
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteReply = ({ reply_id, comment_id }) => {
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/delete-comment-reply/${vodcastId}/${comment_id}/${reply_id}`,
      "DELETE"
    )
      .then(({ data, status }) => {
        if (status === true) {
          onUpdate && onUpdate();
          toast.success(data.message);
        }
      })
      .catch((e) => console.log(e));
  };

  if (!currentUser) {
    return <></>;
  }

  return (
    <div className="w-full flex flex-col gap-6 justify-center items-center py-3 px-4">
      <div className="w-full bg-[#1F1F1F] h-auto py-6 px-4 rounded-md ">
        <div className="flex gap-4 justify-center ">
          <div className="w-[148px] h-[48px] flex justify-center items-center rounded-md bg-[#262626]">
            <span className="text-sm">{data.length} Comments</span>
          </div>
          <input
            type="text"
            name="comment"
            id="comment"
            className="w-[480px] h-[48px] bg-transparent border-[#3E3E3E] border-2 rounded-lg px-4 placeholder:text-sm"
            placeholder="Enter comment"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <button
            className="w-[48px] h-[48px]  flex justify-center items-center"
            onClick={uploadComment}
          >
            <BsFillSendFill className="text-[#471CF1]" />
          </button>
        </div>
      </div>
      <div className="w-full max-h-96 flex flex-col gap-4 overflow-y-auto custom-scrollbar">
        {data.map((comment) => (
          <div
            key={comment.comId}
            className="w-full bg-[#1F1F1F] h-auto py-4 px-8 rounded-md "
          >
            <div className="flex py-2 gap-4 justify-between items-center ">
              <div className="w-full flex gap-2">
                <div className="w-[48px] h-[48px] flex justify-center items-center rounded-full bg-[#262626]">
                  <img
                    src={comment.avatarUrl}
                    alt="profile"
                    className="rounded-full w-[48px] h-[48px] object-cover"
                    width={100}
                    height={100}
                  />
                </div>
                <div className="w-1/2 h-auto flex flex-col justify-center gap-2 items-start px-4">
                  <h1 className="text-base">{comment.text}</h1>
                  <div className="flex gap-3">
                    <p className="text-sm text-[#767676]">{comment.fullName}</p>
                    <p
                      className="text-sm text-[#cecece] cursor-pointer"
                      onClick={() => handleToggleReplies(comment.comId)}
                    >
                      {comment.replies.length} Reply
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-1">
                {/* <button
                    className="w-[48px] h-[48px]  flex justify-center items-center"
                    onClick={() => handleReplyToComment(comment.comId)}
                  >
                    <MdOutlineEdit className="text-[#929292] text-2xl" />
                  </button> */}

                <button
                  className="w-[48px] h-[48px]  flex justify-center items-center"
                  onClick={() => handleReplyToComment(comment.comId)}
                >
                  <MdOutlineReply className="text-[#929292] text-2xl" />
                </button>
                <button
                  className="w-[48px] h-[48px]  flex justify-center items-center"
                  onClick={() => deleteComment(comment.comId)}
                >
                  <MdDeleteForever className="text-[#424242] text-2xl" />
                </button>
              </div>
            </div>
            {visibleReplies === comment.comId && (
              <div className="ml-8 max-h-40 overflow-y-auto custom-scrollbar">
                {comment.replies.map((reply) => (
                  <div
                    key={reply.comId}
                    className="flex py-4 gap-4 justify-between items-center "
                  >
                    <div className="w-full flex gap-2">
                      <div className="w-[48px] h-[48px] flex justify-center items-center rounded-full bg-[#262626]">
                        <img
                          src={reply.avatarUrl}
                          alt="profile"
                          className="rounded-full w-[48px] h-[48px] object-cover"
                          width={100}
                          height={100}
                        />
                      </div>
                      <div className="w-1/2 h-auto flex flex-col justify-center gap-2 items-start px-4">
                        <h1 className="text-base">{reply.text}</h1>
                        <div className="flex gap-3">
                          <p className="text-sm text-[#767676]">
                            {reply.fullName}
                          </p>
                          <p className="text-sm text-[#cecece] cursor-pointer">
                            replied to you
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {replyToCommentId === comment.comId && (
              <div className="flex gap-4 pt-6 w-full border-t-2 border-[#222222] justify-center ">
                <input
                  type="text"
                  name="reply"
                  id="reply"
                  className="w-[480px] h-[48px] bg-transparent border-[#3E3E3E] border-2 rounded-lg px-4"
                  placeholder="reply to comment"
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                />
                <button
                  className="w-[128px] h-[48px] gap-2  flex justify-center items-center"
                  onClick={() => replyComment(comment.comId)}
                >
                  <MdOutlineReply className="text-[#e2e2e2]" />
                  <span className="text-[#e2e2e2]">reply</span>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

//     <CommentSection
//       currentUser={{
//         currentUserId: currentUser?._id,
//         currentUserImg:
//           currentUser?.pic ||
//           `https://ui-avatars.com/api/name=${currentUser?.full_name}&background=random`,
//         // currentUserProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
//         currentUserFullName: currentUser?.full_name,
//       }}
//       // logIn={{
//       //   loginLink: "http://localhost:3001/",
//       //   signupLink: "http://localhost:3001/",
//       // }}
//       commentData={data}
//       advancedInput={true}
//       hrStyle={{ border: "1px solid #ffffff40" }}
//       titleStyle={{ color: "#f2f2f2", fontSize: 12 }}
//       customNoComment={() => <div className="no-com">No comments wohoooo!</div>}
//       imgStyle={{ borderRadius: "100%", aspectRatio: 2 }}
//       inputStyle={{
//         textDecorationLine: "none",
//         borderBottomWidth: 0,
//         marginLeft: 10,
//       }}
//       formStyle={{
//         backgroundColor: "transparent",
//         border: "0.5px solid #3E3E3E",
//         paddingTop: 10,
//         paddingBottom: 10,
//       }}
//       submitBtnStyle={{ border: "1px solid black", backgroundColor: "black" }}
//       cancelBtnStyle={{
//         border: "1px solid gray",
//         backgroundColor: "gray",
//         color: "white",
//       }}
//       removeEmoji={true}
//       overlayStyle={{ backgroundColor: "#1F1F1F", color: "white" }}
//       replyInputStyle={{
//         textDecorationLine: "none",
//         borderBottomWidth: 0,
//         marginLeft: 5,
//         fontSize: 12,
//       }}
//       onSubmitAction={(data) => uploadComment(data)}
//       onReplyAction={replyComment}
//       onDeleteAction={(data) => {
//         console.log(data, "delete comment");
//         if (data?.parentOfDeleteId) {
//           deleteReply({
//             reply_id: data?.comIdToDelete,
//             comment_id: data?.parentOfDeleteId,
//           });
//         } else {
//           deleteComment(data?.comIdToDelete);
//         }
//       }}
//       currentData={(data) => {
//         // console.log("curent data", data);
//       }}
//     />

// userId,
//         comId,
//         avatarUrl,
//         userProfile?,
//         fullName,
//         text,
//         replies,
//         commentId,

export default CommentsBox;
