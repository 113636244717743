import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import swal from "sweetalert";

function ThreeDotPopup({ setShow, val, setEditPostShow, setReload, setPage }) {
	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	function Delete() {
		setShow(false);
		swal({
			text: "You want to delete this post ?",
			buttons: true,
			dangerMode: true,
		}).then((confirmed) => {
			if (confirmed) {
				getData(`${process.env.REACT_APP_BASE_URL}/post/delete/` + val._id, "DELETE").then((data) => {
					if (data.code == 200) {
						toast.success("Post Deleted");
						setPage(0);
						setReload(true);
					} else {
						toast.error("incorrect");
					}
				});
			}
		});
	}
	
	return (
		<div className="post-three-dot-popup">
			<div className="close-popup-three-dot" onClick={() => setShow(false)}>
				X
			</div>
			<center>
				<RWebShare
					data={{
						text: "MusicBook",
						url: "/share/" + val._id,
						title: "MusicBook",
					}}
					onClick={() => setShow(false)}>
					<div>Share</div>
				</RWebShare>
				<div
					onClick={() => {
						setEditPostShow(true);
						setShow(false);
					}}>
					Edit
				</div>
				<div className="text-danger" onClick={Delete}>
					Delete
				</div>
			</center>
		</div>
	);
}
export default ThreeDotPopup;
