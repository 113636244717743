import React, { useState } from "react";
import { useSelector } from "react-redux";
import Star0 from "../images/Star0.svg";
import Star1 from "../images/Star1.svg";
import Star2 from "../images/Star2.svg";
import Star3 from "../images/Star3.svg";
import Star4 from "../images/Star4.svg";
import Star5 from "../images/Star5.svg";
// import { createNotification } from "./Hooks/CreateNotification";
import axios from "./Hooks/axios";

export const RatingBtn = ({ postUserId, postId, totalRating, setPostRating, is_rated, rated_count, setProfileRating }) => {
	const { user, userId } = useSelector((state) => state.auth);
	const [Rating, setRating] = useState(rated_count);
	const [TotalRating, setTotalRating] = useState(totalRating === undefined ? 0 : totalRating);
	const [ratingHoverState, setRatingHoverState] = useState(null);

	const handelStarClick = (count) => {
		setRating(count);
		// createNotification(postUserId, "Rating", `${user?.full_name} rated ${count} for your post`);
		const formData = new FormData();
		formData.append("user_id", userId);
		formData.append("post_id", postId);
		formData.append("rating", count);
		axios
			.put("/post/ratePost", formData)
			.then(({ data }) => {
				setTotalRating(data.postRating.rating_data[0].totalrating);
				setPostRating(data.postRating.rating);
				setProfileRating(data.profile[0].rating);
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className="PostStar">
			{Rating === 1 ? (
				<img className="starImg" src={Star1} />
			) : Rating === 2 ? (
				<img className="starImg" src={Star2} />
			) : Rating === 3 ? (
				<img className="starImg" src={Star3} />
			) : Rating === 4 ? (
				<img className="starImg" src={Star4} />
			) : Rating === 5 ? (
				<img className="starImg" src={Star5} />
			) : (
				<img className="starImg" src={Star0} />
			)}
			<p>{TotalRating}</p>

			<div className="RatingPopupContainer">
				<div
					title="1"
					onClick={() => handelStarClick(1)}
					onMouseEnter={() => setRatingHoverState(1)}
					onMouseLeave={() => setRatingHoverState(null)}>
					{ratingHoverState === 1 ? <img src={Star1} alt="Star 1" /> : <img src={Star0} alt="Star 0" />}
				</div>
				<div
					title="2"
					onClick={() => handelStarClick(2)}
					onMouseEnter={() => setRatingHoverState(2)}
					onMouseLeave={() => setRatingHoverState(null)}>
					{ratingHoverState === 2 ? <img src={Star2} alt="Star 2" /> : <img src={Star0} alt="Star 0" />}
				</div>
				<div
					title="3"
					onClick={() => handelStarClick(3)}
					onMouseEnter={() => setRatingHoverState(3)}
					onMouseLeave={() => setRatingHoverState(null)}>
					{ratingHoverState === 3 ? <img src={Star3} alt="Star 3" /> : <img src={Star0} alt="Star 0" />}
				</div>
				<div
					title="4"
					onClick={() => handelStarClick(4)}
					onMouseEnter={() => setRatingHoverState(4)}
					onMouseLeave={() => setRatingHoverState(null)}>
					{ratingHoverState === 4 ? <img src={Star4} alt="Star 4" /> : <img src={Star0} alt="Star 0" />}
				</div>
				<div
					title="5"
					onClick={() => handelStarClick(5)}
					onMouseEnter={() => setRatingHoverState(5)}
					onMouseLeave={() => setRatingHoverState(null)}>
					{ratingHoverState === 5 ? <img src={Star5} alt="Star 5" /> : <img src={Star0} alt="Star 0" />}
				</div>
			</div>
		</div>
	);
};
