import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { saveUser } from "../Redux/slices/AuthSlice";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import eye from "../images/eye-solid.svg";

function LoginProfessional() {
	useValidateWithoutLogin();
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState("");
	const [Pass, setPass] = useState("");
	const [rememberMe, setRememberMe] = useState(true);

	async function postdata(url = "", data = {}, methods = "") {
		// Default options are marked with *
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	function LoginBtn() {
		if (email == "" || Pass == "") return toast.warn("Please Enter Correct Credentials");

		setLoader(true);

		let data = {
			email: email,
			password: Pass,
			user_type: "professional",
		};

		postdata(`${process.env.REACT_APP_BASE_URL}/auth/login`, data, "POST").then((data) => {
			if (data.status == true) {
				setLoader(false);
				dispatch(saveUser({ ...data, rememberMe }));
				window.open("/vodcast", "_self");
			} else {
				setLoader(false);
				toast.error("incorrect credentials");
			}
		});
	}

	const [eyeV, setEyeV] = useState("password");
	function visible() {
		if (eyeV == "password") setEyeV("text");
		else setEyeV("password");
	}

	return (
		<div className="professional-login">
			<div className="left">
				{/* <div className="pgNo">01</div> */}
				<div className="leftHeading">Teach your audiance what they want to learn</div>
				{/* <div className="left-text">Lorem ipsum dolor sit amet consectetur adipisicing elit</div> */}
				<br />
				<br />
				<br />
				<br />
				<div className="dots">
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="card professional-card">
				<div className="card-body">
					<h1 className="professional-card-title">LOGIN</h1>
					<input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
					<input className="eye-pass" type={eyeV} placeholder="Password" onChange={(e) => setPass(e.target.value)} />
					<img src={eye} className="eye" onClick={visible} style={{ cursor: "pointer" }} alt="" />
					<br />
					<div style={{ display: "flex", justifyContent: "space-around" }}>
						<div className="check-box-remember">
							<input
								type="checkbox"
								style={{ height: "15px", marginTop: "10px", marginLeft: "15px" }}
								checked={rememberMe}
								value={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
							/>
						</div>
						<div className="remember-me">
							<p className="card-text">
								Remember me &nbsp;&nbsp; <a href="/Professional-forgetPassword">forget Password </a>
							</p>
						</div>
					</div>
					{/* <center>{loader ? <div className="ring2"></div> : <></>}</center> */}
					{/* <p className="card-text">Remember me &nbsp;&nbsp;&nbsp;&nbsp; <a href="/forgetPassword">Forget password?</a></p> */}
					<button className="loginWithEmail" disabled={loader} onClick={LoginBtn}>
						{loader ? (
							<ThreeCircles
								height="30"
								width="100%"
								color="#4fa94d"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
								ariaLabel="three-circles-rotating"
								outerCircleColor=""
								innerCircleColor=""
								middleCircleColor=""
							/>
						) : (
							"Continue"
						)}
					</button>
					<p className="card-text">
						I don't Have Account? <a href="/Professional-register1">Create New</a>
					</p>
				</div>
			</div>
		</div>
	);
}
export default LoginProfessional;
