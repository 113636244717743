import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "reactjs-popup/dist/index.css";
import { CropEasy } from "../components/crop/utils/CropEasy";
import { createNotification } from "../components/Hooks/CreateNotification";
import { useSelector } from "react-redux";

function CreatePost({ modelProp, setReload }) {
	const { userId } = useSelector((state) => state.auth);
	const [caption, setCaption] = useState("");
	const [file, setFile] = useState([]);
	const [filetype, setFiletype] = useState("");
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [clearDisable, setClearDisable] = useState(true);
	const [postUploadBtn, setPostUploadBtn] = useState(false);

	const onImageChange = (e) => {
		const file = e.target.files[0];
		if (!["jpg", "png", "jpeg"].includes(file.name.split(".")[1].toLowerCase()))
			return toast.error("This type of file not supported  in this button");
		if (file) {
			setFile(file);
			setFiletype("image");
			setPhotoURL(URL.createObjectURL(file));
			setOpenCrop(true);
			setClearDisable(false);
		}
		e.target.value = null;
	};

	const onImageChange2 = (e) => {
		if (!["mp4"].includes(e.target.files[0].name.split(".")[1].toLowerCase()))
			return toast.error("This type of file not supported  in this button");
		setFile(e.target.files[0]);
		setFiletype("video");
		setClearDisable(false);
		e.target.value = null;
	};

	const onImageChange3 = (e) => {
		if (!["mp3"].includes(e.target.files[0].name.split(".")[1].toLowerCase()))
			return toast.error("This type of file not supported  in this button");
		setFile(e.target.files[0]);
		setFiletype("audio");
		setClearDisable(false);
		e.target.value = null;
	};

	const handelClearAction = () => {
		console.log("clicked");
		setFiletype("");
		setFile([]);
		setCaption("");
		setClearDisable(true);
	};

	const [uploadingPercent, setUploadingPercent] = useState(0);
	const [visibleProgress, setVisibleProgress] = useState("0%");

	function Upload() {
		if (caption == "" && file.length == 0) return toast.warn("Please add a caption or image/video/audio");

		setPostUploadBtn(true);

		if (filetype == "video") {
			let formData = new FormData();
			formData.append("media", file);
			formData.append("caption", caption);
			formData.append("tags", "post,video");
			formData.append("user_id", localStorage.getItem("user_id"));
			formData.append("type", filetype);
			formData.append("upload_from", "web");

			setVisibleProgress("100%");

			axios
				.post(`${process.env.REACT_APP_BASE_URL}/post/create`, formData, {
					headers: { authorization: localStorage.getItem("auth_token") },
					onUploadProgress: (data) => {
						setUploadingPercent(Math.round((data.loaded / data.total) * 100));
					},
				})
				.then((success) => {
					modelProp(false);
					createNotification(userId, "Post", "Video Uploaded Successfully");
					toast.success("Post Created Successfully");
					setReload(true);
					setUploadingPercent(0);
					setVisibleProgress("0%");
					setCaption("");
					setFile([]);
					setFiletype("");
					setPhotoURL("");
					setPostUploadBtn(false);
					// window.open("/professional-home", "_self");
				})
				.catch((error) => {
					toast.error("error");
					setVisibleProgress("0");
				});
		} else {
			let formData = new FormData();
			formData.append("media", file);
			formData.append("caption", caption);
			formData.append("tags", "post,video");
			formData.append("user_id", localStorage.getItem("user_id"));
			formData.append("type", filetype);

			setVisibleProgress("100%");

			axios
				.post(`${process.env.REACT_APP_BASE_URL}/post/create`, formData, {
					headers: { authorization: localStorage.getItem("auth_token") },
					onUploadProgress: (data) => {
						setUploadingPercent(Math.round((data.loaded / data.total) * 100));
					},
				})
				.then((success) => {
					modelProp(false);
					if (filetype === "") createNotification(userId, "Post", "Caption Uploaded Successfully");
					if (filetype === "audio") createNotification(userId, "Post", "Audio Uploaded Successfully");
					if (filetype === "image") createNotification(userId, "Post", "Image Uploaded Successfully");
					toast.success("Post Created Successfully");
					setReload(true);
					setUploadingPercent(0);
					setVisibleProgress("0%");
					setCaption("");
					setFile([]);
					setFiletype("");
					setPhotoURL("");
					setPostUploadBtn(false);
					// window.open("/professional-home", "_self");
				})
				.catch((error) => {
					toast.error("error");
					setVisibleProgress("0");
				});
		}
	}

	return (
		<>
			{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
			<div className=" cpr">
				<nav className="navbar navbar-expand-lg cpr-header">
					<div>Create Post</div>
					<p>Description</p>
					<div
						className="my-custom-clear-btn"
						style={{
							background: "rgb(20, 101, 168)",
							color: "#fff",
							display: filetype ? "flex" : "none",
						}}>
						<button
							className="btn"
							onClick={handelClearAction}
							disabled={clearDisable}
							style={{ border: filetype ? "none" : "", color: "#fff" }}>
							Clear
						</button>
					</div>
					<textarea
						value={caption}
						onChange={(e) => setCaption(e.target.value)}
						className="create-post-mobile-input"
						placeholder="Description"></textarea>
				</nav>
				<nav className="navbar navbar-expand-lg cpr-body">
					<div
						className="upload-btn-wrapper create-post-mobile-btn"
						style={{
							background: filetype == "image" ? "rgb(20, 101, 168)" : "",
							border: filetype == "image" ? "none" : "",
						}}>
						<button className="cpr-btn">Image</button>
						<input onChange={onImageChange} accept="image/*" type="file" name="myfile" />
					</div>
					<div
						className="upload-btn-wrapper create-post-mobile-btn"
						style={{
							background: filetype == "video" ? "rgb(20, 101, 168)" : "",
							border: filetype == "video" ? "none" : "",
						}}>
						<button className="cpr-btn">Video</button>
						<input onChange={onImageChange2} accept="video/mp4" type="file" name="myfile" />
					</div>
					<div
						className="upload-btn-wrapper create-post-mobile-btn"
						style={{
							background: filetype == "audio" ? "rgb(20, 101, 168)" : "",
							border: filetype == "audio" ? "none" : "",
						}}>
						<button className="cpr-btn">Audio</button>
						<input onChange={onImageChange3} accept=".mp3,audio/*" type="file" name="myfile" />
					</div>
					<div className="upload-btn-wrapper create-post-mobile-btn">
						<button onClick={Upload} disabled={postUploadBtn} className="cpr-btn">
							Upload
						</button>
					</div>
				</nav>
				<div className="progress" style={{ opacity: visibleProgress }}>
					<div
						className="progress-bar"
						role="progressbar"
						style={{ width: `${uploadingPercent}%` }}
						aria-valuenow={uploadingPercent}
						aria-valuemin="0"
						aria-valuemax="100">
						{uploadingPercent}%
					</div>
				</div>
			</div>
		</>
	);
}

export default CreatePost;
