import React from "react";
import Style from "../style/third.module.css";
import { Link } from "react-router-dom";

function ForthSection({ gotoDownload }) {
  return (
    <div className="w-full sm:h-screen h-auto flex justify-center align-middle">
      <div className="grid sm:grid-rows-2 grid-rows-1 sm:gap-0 gap-14 px-1 md:px-10  ">
        <div className="h-auto sm:px-0 px-6 flex flex-col gap-4 pt-8 sm:flex-nowrap flex-wrap">
          <p className="text-2lg uppercase font-medium tracking-wide ">
            About Artist
          </p>
          <h1 className="text-4xl font-semibold pt-6">
            Your artistic journey is about to <br /> get the recognition it
            deserves.
          </h1>
          <div className="flex gap-6 pt-6">
            <Link
              className={Style.NavButtonA}
              target="_self"
              to="/login"
              rel="noopener noreferrer"
            >
              Get Started
            </Link>
            {/* <Link className={Style.NavButton} to={'https://play.google.com/store/apps/details?id=com.music.book&pcampaignid=web_share'}>Download</Link> */}
            <div className={Style.NavButton} onClick={gotoDownload}>
              Download
            </div>
          </div>
        </div>
        <div className="w-full flex justify-evenly sm:gap-12 gap-4 align-middle sm:flex-nowrap flex-wrap">
          <div className="flex flex-col gap-6">
            <p>
              Connect with fellow <br /> enthusiasts{" "}
            </p>
            <p>
              Post your poems, lyrics, Shayari, <br /> and music
            </p>
          </div>
          <div>
            <img
              src={"/Personalvodcast.png"}
              style={{ width: 400 }}
              width={400}
              height={120}
              alt="Personal"
            ></img>
          </div>
          <div className="flex flex-col gap-6">
            <p>
              Create unique content to <br /> showcase your talent
            </p>
            <p>
              {" "}
              Earn views, likes, shares <br /> & Rewards too!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForthSection;
