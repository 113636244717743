import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import userDefault from "../images/userDefaultImage.jpg";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";

const Comment = ({
  comment,
  handleReply,
  handleEdit,
  handleDelete,
  handleLike,
  user,
  userId,
  setCommentCount,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [showLess, setShowLess] = useState(false);
  const inputRef = useRef(null);

  const UploadReply = () => {
    if (replyText === "") return toast.warn("Please add any text!");
    let data = {
      comment_id: comment.id,
      post_id: comment.post_id,
      replyComment: {
        user_id: userId,
        comment: replyText,
      },
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/post/reply-comment`, data, {
        headers: {
          authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.data.status) {
          setReplyText("");
          setIsReplying(false);
          handleReply(comment.id, replyText);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="border-b border-gray-700 mb-2 pb-2">
      <div className="flex items-start">
        <div className="w-10 h-10 mr-4">
          <img
            src={comment.user_id ? comment.user_id.pic : userDefault}
            alt={userDefault}
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <div className="flex-1">
          <p className="text-gray-200">{comment.comment}</p>
          <div className="flex space-x-4 mt-2 text-sm text-gray-400">
            <div
              className="flex items-center space-x-1 cursor-pointer"
              onClick={() => handleLike(comment.id)}
            >
              {comment.isLiked ? "👍" : "👍"}
              <span>{comment.likeCount}</span>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setIsReplying(!isReplying)}
            >
              <span>Reply</span>
            </div>
            {(comment.user_id?._id === userId ||
              comment.created_by === userId) && (
              <>
                <div
                  className="cursor-pointer"
                  onClick={() => handleEdit(comment.id)}
                >
                  <span>Edit</span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => handleDelete(comment.id)}
                >
                  <span>Delete</span>
                </div>
              </>
            )}
          </div>
          {isReplying && (
            <div className="mt-2">
              <textarea
                className="w-full bg-gray-800 text-gray-200 p-2 rounded"
                rows="2"
                placeholder="Write a reply..."
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                ref={inputRef}
              />
              <button
                className="mt-1 px-4 py-2 bg-blue-600 text-white rounded"
                onClick={UploadReply}
              >
                Reply
              </button>
            </div>
          )}
          {comment.replies.length > 0 && (
            <div className="mt-2">
              {showLess
                ? comment.replies.map((reply, index) => (
                    <Comment
                      key={index}
                      comment={reply}
                      handleReply={handleReply}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleLike={handleLike}
                      user={user}
                      userId={userId}
                      setCommentCount={setCommentCount}
                    />
                  ))
                : comment.replies
                    .slice(0, 1)
                    .map((reply, index) => (
                      <Comment
                        key={index}
                        comment={reply}
                        handleReply={handleReply}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleLike={handleLike}
                        user={user}
                        userId={userId}
                        setCommentCount={setCommentCount}
                      />
                    ))}
              {comment.replies.length > 1 && (
                <button
                  className="text-sm text-blue-500 mt-1"
                  onClick={() => setShowLess(!showLess)}
                >
                  {showLess ? "Show less" : "Show more"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const { userId, user } = useSelector((state) => state.auth);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    setCommentLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/post/get-post-comment/POST_ID?offset=0`
      )
      .then(({ data }) => {
        if (data.status) {
          setCommentLoading(false);
          setComments(data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [reload]);

  const handleReply = (id, text) => {
    const newComments = comments.map((comment) => {
      if (comment.id === id) {
        comment.replies.push({
          id: Date.now(),
          text,
          likeCount: 0,
          isLiked: false,
          replies: [],
        });
      }
      return comment;
    });
    setComments(newComments);
  };

  const handleEdit = (id) => {
    // Add your edit functionality here
  };

  const handleDelete = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/post/delete-comment/POST_ID/${id}`
      )
      .then(({ data }) => {
        if (data.status) {
          setReload(Math.random());
          toast.success(data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleLike = (id) => {
    const newComments = comments.map((comment) => {
      if (comment.id === id) {
        comment.isLiked = !comment.isLiked;
        comment.likeCount += comment.isLiked ? 1 : -1;
      }
      return comment;
    });
    setComments(newComments);
  };

  const UploadComment = () => {
    if (commentText === "") return toast.warn("Please add any text!");
    let data = {
      comment: commentText,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/post/comment-post/POST_ID`,
        data,
        {
          headers: {
            authorization: localStorage.getItem("auth_token"),
          },
        }
      )
      .then(({ data }) => {
        if (data.status) {
          toast.success("Successfully commented");
          setComments(data.data);
          setCommentText("");
          setReload(Math.random());
        } else {
          toast.error(data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container mx-auto p-4 bg-gray-900 text-white min-h-screen">
      <h1 className="text-2xl mb-4">Comments</h1>
      {commentLoading ? (
        <div className="flex justify-center">
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperClass="blocks-wrapper"
            colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
          />
        </div>
      ) : comments.length === 0 ? (
        "No comments"
      ) : (
        <div className="space-y-4">
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              handleReply={handleReply}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleLike={handleLike}
              user={user}
              userId={userId}
              setCommentCount={(count) => {}}
            />
          ))}
        </div>
      )}
      <div className="mt-4">
        <textarea
          className="w-full bg-gray-800 text-gray-200 p-2 rounded"
          rows="2"
          placeholder="Add a comment..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <button
          className="mt-1 px-4 py-2 bg-blue-600 text-white rounded"
          onClick={UploadComment}
        >
          Comment
        </button>
      </div>
    </div>
  );
};

export default CommentSection;

// import React, { useEffect, useRef, useState } from "react";
// import { ColorRing } from "react-loader-spinner";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import axios from "../components/Hooks/axios";
// import userDefault from "../images/userDefaultImage.jpg";
// import { createNotification } from "./Hooks/CreateNotification";

// const Comment = ({ visible, val, setCommentCount }) => {
// 	const [commentText, setCommentText] = useState("");
// 	const [comments, setComments] = useState(val?.comments);
// 	const [cmtReplyState, setCmtReplyState] = useState(false);
// 	const inputRef = useRef(null);
// 	const [commentId, setCommentId] = useState("");
// 	const [commentId2, setCommentId2] = useState("");
// 	const [commentLoading, setCommentLoading] = useState(false);
// 	const { userId, user } = useSelector((state) => state.auth);
// 	const [reload, setReload] = useState(0);
// 	const [showLess, setShowLess] = useState(false);
// 	const [threeDotPopupShow, setThreeDotPopupShow] = useState(false);
// 	const [activeCommentId, setActiveCommentId] = useState("");

// 	useEffect(() => {
// 		setCommentLoading(true);
// 		axios
// 			.get(`post/get-post-comment/${val?._id}?offset=0`)
// 			.then(({ data }) => {
// 				if (data.status) {
// 					setCommentLoading(false);
// 					setComments(data.data);
// 				}
// 			})
// 			.catch((e) => {
// 				console.log(e);
// 			});
// 	}, [reload]);

// 	async function postData(url = "", data = {}, methods = "") {
// 		const response = await fetch(url, {
// 			method: methods,
// 			headers: {
// 				"Content-Type": "application/json",
// 				authorization: localStorage.getItem("auth_token"),
// 			},
// 			body: JSON.stringify(data),
// 		});
// 		return await response.json();
// 	}

// 	function UploadComment() {
// 		if (commentText === "") return toast.warn("Please add any text!");
// 		if (cmtReplyState) {
// 			// createNotification(val?.created_by?._id, "Comment", `${user?.full_name} comment in ${count} for your post`);
// 			let data = {
// 				comment_id: commentId,
// 				post_id: val._id,
// 				replyComment: {
// 					user_id: userId,
// 					comment: commentText,
// 				},
// 			};
// 			postData(`${process.env.REACT_APP_BASE_URL}/post/reply-comment`, data, "POST")
// 				.then((data) => {
// 					if (data.status) {
// 						setCommentText("");
// 						setCmtReplyState(false);
// 						setReload(Math.random());
// 					}
// 				})
// 				.catch((e) => {
// 					console.log(e);
// 				});
// 		} else {
// 			createNotification(val?.created_by?._id, "Comment", `${user?.full_name} comment in your post`);
// 			let data = {
// 				comment: commentText,
// 			};
// 			postData(`${process.env.REACT_APP_BASE_URL}/post/comment-post/${val._id}`, data, "POST").then((data) => {
// 				if (data.status === true) {
// 					toast.success("Successfully commented");
// 					setComments(data.data);
// 					setCommentText("");
// 					setCommentCount(data.data.length);
// 					setReload(Math.random());
// 				} else {
// 					console.log("incorrect");
// 					toast.error(data.message);
// 				}
// 			});
// 		}
// 	}

// 	const handelDeleteComment = (cmt) => {
// 		axios
// 			.delete(`/post/delete-comment/${cmt.post_id}/${cmt._id}`)
// 			.then(({ data }) => {
// 				if (data.status) {
// 					setReload(Math.random());
// 					toast.success(data.message);
// 				}
// 			})
// 			.catch((e) => console.log(e));
// 	};

// 	function commentsContainer(cmt, i) {
// 		return (
// 			<div className="align" key={i}>
// 				<div className="mainComment">
// 					<div className="postProfileImageContainer" style={{ marginRight: "2%", marginLeft: "2%" }}>
// 						{cmt.user_id ? (
// 							<img src={cmt?.user_id?.pic} alt="" />
// 						) : (
// 							<img
// 								src={userDefault}
// 								alt="userDefault"
// 								style={{ marginTop: "0px", width: "100%", height: "100%" }}
// 							/>
// 						)}
// 					</div>
// 					<div className="cmtPostUserName">
// 						<h5>{cmt?.user_id?.full_name}</h5>
// 						<div className="innerPDiv">
// 							<p className="text-small">{cmt.comment}</p>
// 						</div>
// 					</div>
// 					<button
// 						className="replyCMT"
// 						style={{ background: cmtReplyState && commentId === cmt._id ? "#42f" : "" }}
// 						onClick={() => {
// 							if (cmtReplyState) inputRef.current.blur();
// 							else inputRef.current.focus();
// 							setCmtReplyState(!cmtReplyState);
// 							setCommentId(cmt?._id);
// 						}}>
// 						Reply
// 					</button>
// 					{/* {console.log(val.created_by._id === userId)}
// 					{console.log(cmt?.user_id?._id === userId)} */}
// 					{(cmt?.user_id?._id === userId || val.created_by._id === userId) && (
// 						<button
// 							onClick={() => {
// 								setThreeDotPopupShow(!threeDotPopupShow);
// 								setActiveCommentId(cmt?._id);
// 							}}
// 							className="commentThreeDot">
// 							⋮
// 						</button>
// 					)}
// 					{threeDotPopupShow && activeCommentId === cmt?._id && (
// 						<div className="cmtThreeDotPopup">
// 							<button
// 								onClick={() => {
// 									setThreeDotPopupShow(false);
// 									handelDeleteComment(cmt);
// 								}}>
// 								Delete Comment
// 							</button>
// 							{/* <button
// 								onClick={() => {
// 									setThreeDotPopupShow(false);
// 								}}>
// 								Report
// 							</button> */}
// 						</div>
// 					)}
// 				</div>

// 				{showLess && commentId2 === cmt?._id
// 					? cmt?.replyComment?.map((cmt, index) => (
// 							<div key={index} className="cmtReplyContainer">
// 								<div className="cmtReplyImgBox">
// 									<img src={cmt?.user_id?.pic} alt="" />
// 								</div>
// 								<div className="cmtReplyTextBox">
// 									<h6>{cmt?.user_id?.full_name}</h6>
// 									<p className="text-small">{cmt?.comment}</p>
// 								</div>
// 							</div>
// 					  ))
// 					: cmt?.replyComment?.slice(0, 1).map((data, index) => (
// 							<div key={index} className="cmtReplyContainer">
// 								<div className="cmtReplyImgBox">
// 									<img src={data?.user_id?.pic} alt="" />
// 								</div>
// 								<div className="cmtReplyTextBox">
// 									<h6>{data?.user_id?.full_name}</h6>
// 									<p className="text-small">{data?.comment}</p>
// 								</div>
// 							</div>
// 					  ))}

// 				{cmt?.replyComment?.length > 1 && (
// 					<button
// 						className="showLessBtn"
// 						onClick={() => {
// 							setCommentId2(cmt?._id);
// 							setShowLess(!showLess);
// 						}}>
// 						{showLess && commentId2 === cmt?._id ? "show less" : "show more"}
// 					</button>
// 				)}
// 			</div>
// 		);
// 	}

// 	return (
// 		<div className="comment-area" style={{ display: visible }}>
// 			{commentLoading ? (
// 				<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
// 					<ColorRing
// 						visible={true}
// 						height="100"
// 						width="100"
// 						ariaLabel="blocks-loading"
// 						wrapperClass="blocks-wrapper"
// 						colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
// 					/>
// 				</div>
// 			) : comments?.length === 0 ? (
// 				"No comments"
// 			) : (
// 				<div className="comments">{comments?.map(commentsContainer)}</div>
// 			)}
// 			<div className="display-flex">
// 				<input
// 					type="text"
// 					placeholder="Type your comment.."
// 					className="comment-box"
// 					ref={inputRef}
// 					value={commentText}
// 					onChange={(e) => setCommentText(e.target.value)}
// 					onKeyDown={(e) => {
// 						if (e.key === "Enter") UploadComment();
// 					}}
// 				/>
// 				<button className="comment-btn primary-bg" onClick={UploadComment}>
// 					✎
// 				</button>
// 			</div>
// 		</div>
// 	);
// };

// export default Comment;
