import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ProgressBar from "react-bootstrap/ProgressBar";
// import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, RedditIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import axios from "axios";

const CustomModal = ({
  isOpen,
  onRequestClose,
  url,
  title,
  message,
  estimatedTime,
  downloadLink,
}) => {
  const [progress, setProgress] = useState(0);
  const [exportComplete, setExportComplete] = useState(false);
  const [showEmbedCode, setShowEmbedCode] = useState(false);

  const downloadVideo = async (videoUrl) => {
    try {
      const response = await fetch(videoUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const blobURL = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", "edit-videos-online.com.mp4"); // Or derive a filename from the videoUrl or some other source
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(downloadLink);
  };

  const copyEmbedCodeToClipboard = () => {
    const embedCode = getEmbedCode();
    navigator.clipboard.writeText(embedCode);
  };

  const handleDeleteVideo = async () => {
    const filename = downloadLink.split("/").pop();
    try {
      const response = await axios.post(
        "https://backend.edit-videos-online.com/deletevideo",
        { filename: filename }
      );
      if (response.status === 200) {
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getEmbedCode = () => {
    return `<video width="560" height="315" controls><source src="${downloadLink}" type="video/mp4">Your browser does not support the video tag.</video>`;
  };

  useEffect(() => {
    if (!isOpen) return;

    const startTime = new Date().getTime();
    let progressInterval = setInterval(() => {
      const elapsedTime = new Date().getTime() - startTime;
      let logProgress = Math.min(
        95,
        (elapsedTime / (elapsedTime + estimatedTime * 300)) * 100
      );
      setProgress(logProgress);
    }, 1000);

    return () => clearInterval(progressInterval);
  }, [isOpen, estimatedTime]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Exporting Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark overlay
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#181818", // Dark background
          color: "#ffffff", // White text
          borderRadius: "15px", // Rounded borders
          padding: "30px",
          width: "80%", // Medium-sized modal
          height: "80%", // Medium-sized modal
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #ffffff", // White border
          boxShadow: "0px 0px 10px 1px #0a8dff", // Blue box shadow for a high-tech feel
        },
      }}
    >
      {!downloadLink ? (
        <>
          <h2>{title}</h2>
          <ProgressBar
            now={progress}
            label={`${Math.round(progress)}%`}
            variant="info"
            style={{
              width: "80%",
              margin: "30px 0",
              backgroundColor: "#0a8dff",
            }}
          />
          <p style={{ marginTop: "15px" }}>{message}</p>
        </>
      ) : (
        <>
          <video controls width="80%" src={downloadLink}></video>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
              width: "80%",
            }}
          >
            <Button
              variant="primary"
              onClick={() => downloadVideo(downloadLink)}
            >
              Download Video
            </Button>
          </div>

          {/*<div style={{ marginTop: '20px', width: '80%' }}>
            <Button onClick={() => setShowEmbedCode(!showEmbedCode)}>Add video to your website</Button>
            <Button onClick={handleDeleteVideo} variant="danger" style={{ marginLeft: '1rem' }}>Delete Video</Button>

            {showEmbedCode && (
              <>
                <h4 style={{ marginTop: '15px' }}>Embed Code:</h4>
                <FormControl 
                  as="textarea"
                  value={getEmbedCode()} 
                  readOnly
                  rows={3}
                  style={{ marginBottom: '15px' }}
                />
              </>
            )}


          </div>
            */}

          <div></div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {/* <FacebookShareButton url={downloadLink} style={{ margin: '0 10px' }}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={downloadLink} style={{ margin: '0 10px' }}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <RedditShareButton url={downloadLink} style={{ margin: '0 10px' }}>
          <RedditIcon size={32} round />
        </RedditShareButton>

        <WhatsappShareButton url={downloadLink} style={{ margin: '0 10px' }}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton> */}
          </div>
        </>
      )}
    </Modal>
  );
};

export default CustomModal;
