import { createClient } from "pexels";

const client = createClient(
  "okMdKmhWrVrICVpsLfI0VzvbI02594ZkfbefcY0JEXjaXpLoHxeLp9P0"
);

export const searchVideos = async (query = "podcast") => {
  const videos = await client.videos.search({ query, per_page: 1 });
  return videos;
};

export const getVideos = async (page = 1) => {
  const videos = await client.videos.popular({ page: page, per_page: 8 });
  return videos;
};

export const getVideosById = async (id) => {
  const video = await client.videos.show({ id: id });
  return video;
};
