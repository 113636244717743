import React from "react";
import Style from "../style/second.module.css";
import { Link } from "react-router-dom";

function SecondSection({ gotoDownload, navigate }) {
  return (
    <div className="w-full h-screen">
      <div className={Style.Backdrop}>
        <div className={Style.Container}>
          <p className="text-2lg uppercase font-medium tracking-wide ">
            Latest update
          </p>
          {/* <h2 className="sm:text-4xl text-3xl sm:w-96 w-auto font-semibold pt-6">
            Now publish your music vodcast to million of watchers.
          </h2> */}
          <h2 className="sm:text-4xl text-3xl sm:w-96 font-semibold pt-6">
            New Vodcast feature allows you to switch to Podcast instantly for
            uninterrupted experience on the go.
          </h2>
          <div className="flex gap-6 pt-6">
            <Link
              className={Style.NavButtonA}
              target="_self"
              to="/login"
              rel="noopener noreferrer"
            >
              Get Started
            </Link>
            {/* <Link className={Style.NavButton} to={'https://play.google.com/store/apps/details?id=com.music.book&pcampaignid=web_share'}>Download</Link> */}

            <div className={Style.NavButton} onClick={gotoDownload}>
              Download
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
