import * as React from "react";
import Button from "@mui/joy/Button";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import constants from "../../configs/constants";
import {
  Box,
  CircularProgress,
  DialogContent,
  Input,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import { RiCheckboxCircleLine, RiFileFill } from "react-icons/ri";
import UploadingView from "./LoadingView";
import SuccessView from "./SuccessView";
import { getData, postData } from "../../utils/httpMethods";

export default function ReportVideoDialog({ open, onClose, user, vodcastId }) {
  const fileSelectorRef1 = React.useRef();
  const fileSelectorRef2 = React.useRef();

  const [showAddDetails, setShowAddDetails] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [reasonsLoading, setReasonsLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [reasons, setReasons] = React.useState([]);
  const [selectedReason, setSelectedReason] = React.useState(null);
  const [selectedFile1, setSelectedFile1] = React.useState(null);
  const [selectedFile2, setSelectedFile2] = React.useState(null);

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [creatorName, setCreatorName] = React.useState("");

  const isCustom = selectedReason === "custom";

  const onSelected1 = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setSelectedFile1(file);
  };

  const onSelected2 = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setSelectedFile2(file);
  };

  React.useEffect(() => {
    setReasonsLoading(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/admin/get-vodcast-report-reasons`,
      "GET"
    ).then((res) => {
      setReasonsLoading(false);
      if (res.status == true) {
        setReasons(res.data);

        // setCountData(res.data?.count);
      } else {
        console.log("incorrect");
      }
    });
  }, []);

  const submitReport = () => {
    if (isCustom) {
      if (!title || !description) {
        return alert("Fields are required");
      }
    }
    if (!isCustom) {
      if (!selectedReason) {
        return alert("Fields are required");
      }
    }

    setLoading(true);
    let FD = new FormData();
    FD.append("report_user_id", user?._id);
    FD.append("vodcast_id", vodcastId);
    isCustom && FD.append("report_title", title);
    isCustom && FD.append("report_description", description);
    isCustom && FD.append("file1", selectedFile1);
    isCustom && FD.append("file2", selectedFile1);
    !isCustom && FD.append("report_reason", selectedReason);
    postData(`${process.env.REACT_APP_BASE_URL}/report/add-vodcast-report`, FD)
      .then((res) => {
        setLoading(false);
        if (res.status == true) {
          setUploaded(true);
        } else {
          alert("FAILED TO REPORT");
        }
      })
      .catch((err) => console.log("UPLOAD ERROR", err));
  };

  if (loading) {
    return <UploadingView />;
  }

  if (uploaded) {
    return <SuccessView onClose={onClose} />;
  }

  return (
    <div
      style={{
        zIndex: 9999,
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: constants.colors.secondary,
          opacity: 0.7,
        }}
      />
      <input
        type="file"
        ref={fileSelectorRef1}
        style={{ display: "none" }}
        onChange={onSelected1}
      />
      <input
        type="file"
        ref={fileSelectorRef2}
        style={{ display: "none" }}
        onChange={onSelected2}
      />

      <ModalDialog
        slotProps={{
          root: {
            style: {
              top: "45%",
            },
          },
        }}
        sx={{
          maxWidth: { xs: 400, sm: 500 },
          minWidth: { xs: "80%", sm: 400, md: 500, lg: 500 },
          minHeight: 300,
          borderRadius: "xs",
          p: 3,
          boxShadow: "xs",
          background: "#222020",
          outlineWidth: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogContent sx={{ width: "100%" }}>
          <ModalClose onClick={onClose} />

          <Box
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Stack sx={{ flex: 1, width: "80%", gap: 0.5, mt: 2 }}>
              <Typography level="h3" fontSize="md">
                Report
              </Typography>
              <Typography level="h3" fontSize="xs" sx={{ mb: 3 }}>
                Please fill the below information
              </Typography>
              {isCustom && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mb: 2, flex: 1, justifyContent: "space-between" }}
                >
                  <Button
                    onClick={() => fileSelectorRef1.current.click()}
                    color="neutral"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      height: 70,
                      borderWidth: 1,
                      borderColor: "#414040",
                      background: "#242424",
                      fontSize: 12,
                    }}
                  >
                    {selectedFile1 ? (
                      <Stack direction="row">
                        <RiFileFill size={20} />
                        <Typography
                          sx={{
                            flex: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                          fontSize={8}
                        >
                          {selectedFile1.name}
                        </Typography>
                      </Stack>
                    ) : (
                      "Upload Document"
                    )}
                  </Button>
                  <Button
                    onClick={() => fileSelectorRef2.current.click()}
                    color="neutral"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      height: 70,
                      borderWidth: 1,
                      borderColor: "#414040",
                      background: "#242424",
                      fontSize: 12,
                    }}
                  >
                    {selectedFile2 ? (
                      <Stack direction="row">
                        <RiFileFill size={20} />
                        <Typography
                          sx={{
                            flex: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                          fontSize={8}
                        >
                          {selectedFile2.name}
                        </Typography>
                      </Stack>
                    ) : (
                      "Upload Document"
                    )}
                  </Button>
                </Stack>
              )}
              {isCustom && (
                <Input
                  size="sm"
                  placeholder="Title"
                  color="neutral"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              )}
              {isCustom && (
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  size="sm"
                  color="neutral"
                  placeholder="Description"
                  slotProps={{
                    input: {
                      multiple: true,
                      style: { minHeight: 60, verticalAlign: "top" },
                    },
                  }}
                />
              )}
              {isCustom && (
                <Input
                  size="sm"
                  placeholder="Creator"
                  color="neutral"
                  // disabled={true}
                  onChange={(e) => setCreatorName(e.target.value)}
                  value={creatorName}
                />
              )}
              {!isCustom && (
                <Select
                  size="sm"
                  color="neutral"
                  value={selectedReason}
                  placeholder="Choose your reason"
                  onChange={(e, newValue) => setSelectedReason(newValue)}
                >
                  {reasonsLoading && <CircularProgress size="sm" />}
                  {reasons.map((item, index) => (
                    <Option key={index} value={item?._id}>
                      {<RiCheckboxCircleLine size={18} color="#fff" />}
                      {item?.type_title === "Vodcast contain Nudity  language"
                        ? "Vodcast contain Nudity"
                        : item?.type_title}
                    </Option>
                  ))}
                  <Option value="custom">
                    {<RiCheckboxCircleLine size={18} color="#fff" />} Other
                    report
                  </Option>
                </Select>
              )}
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 5,
                gap: 2,
              }}
            >
              <Button
                onClick={submitReport}
                size="sm"
                color="primary"
                sx={{
                  borderRadius: 50,

                  fontSize: { xs: 14 },
                  background: constants.colors.primary,
                }}
              >
                CONTINUE
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </ModalDialog>
      {/* </Modal> */}
    </div>
  );
}
