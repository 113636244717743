import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../components/Hooks/axios";
import styles from "../css/Account.module.scss";
import { ColorRing } from "react-loader-spinner";

const AlbumProfile = ({ page, setPage }) => {
	const navigate = useNavigate();
	const [albumData, setAlbumData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [noData, setNoData] = useState(false)

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`${process.env.REACT_APP_BASE_URL}/album/get?offset=${page}&user_id=${localStorage.getItem("user_id")}`)
			.then(({ data }) => {
				if (data.status == true) {
					if(data.data.length ===0)setNoData(true)
					setIsLoading(false);
					setAlbumData((prev) => [...prev, ...data.data]);
				} else {
					console.log("incorrect");
				}
			});
	}, [page]);

	const AlbumItem = (data, index) => {
		return (
			<div key={index} className={styles.AlbumItem} onClick={() => navigate(`/album/${data._id}`)}>
				<img src={data.cover_photo} alt={data.title} />
				<div className={styles.box}>
					<h4>{data.title}</h4>
					<p>{data.description} </p>
				</div>
			</div>
		);
	};

	return (
		<>
		{albumData.map(AlbumItem)}
			{isLoading && (
				<div
				style={{
						with: "100%",
						height: "100px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<ColorRing
						visible={true}
						height="100"
						width="100"
						ariaLabel="blocks-loading"
						wrapperClass="blocks-wrapper"
						colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
						/>
				</div>
			)}
			{noData && <p style={{ textAlign: "center" }}>No More Album</p>}
		</>
	);
};

export default AlbumProfile;
