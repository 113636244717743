import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../components/Hooks/axios";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { setMessage } from "../Redux/slices/TempSlice";

function Notification({ setNotificationShow }) {
	const { userId } = useSelector((state) => state.auth);
	const [allNotification, setAllNotification] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [reload, setReload] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userId === "") return;
		// setIsLoading(true);
		axios
			.get(`/notificationUser/notifications/${userId}`)
			.then(({ data }) => {
				if (data.status) {
					setAllNotification(data.data);
					setIsLoading(false);
					// console.log(data)
				}
			})
			.catch((e) => {
				setIsLoading(false);
				console.log(e);
			});
	}, [userId, reload]);

	const handelSeen = (id) => {
		axios
			.put(`/notificationUser/notifications/seen/${id}`)
			.then(({ data }) => {
				if (data.status) {
					setReload(Math.random())
				}
			})
			.catch((e) => {
				setIsLoading(false);
				console.log(e);
			});
	}

	const handelClear = () => {
		axios
			.delete(`/notificationUser/notifications/${userId}`)
			.then(({ data }) => {
				if (data.status) {
					setReload(Math.random());
					toast.success(data.message);
				}
			})
			.catch((e) => {
				setIsLoading(false);
				console.log(e);
			});
	};

	return (
		<div className="UserNotification">
			<div className="UserNotificationPopupBox">
				<h3 className="display-flex">
					<div onClick={() => setNotificationShow(false)} style={{ cursor: "pointer" }}>
						◄
					</div>
					&nbsp;
					<div>NOTIFICATIONS</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<button className=" mb-2 btn btn-outline-secondary" onClick={() => handelClear()}>
						Clear All
					</button>
				</h3>

				<div className="UserNotificationBox">
					{isLoading ? (
						<div className="loading">
							<ColorRing
								visible={true}
								height="100"
								width="100"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : allNotification.length === 0 ? (
						"No Notification"
					) : (
						[...allNotification].reverse().map((data, index) => (
							<div
								key={index}
								onClick={() => {
									handelSeen(data._id)
									if (data.title.split("-")[0] === "Message") {
										dispatch(
											setMessage({
												open: true,
												id: data.title.split("-")[1],
											})
										);
									}
								}}
								className="notificationBoxDiv"
								style={{ background: data.is_seen ? "" : "#2d68ff" }}>
								<p>{data.message}</p>

								{!data.is_seen && (
									<button
										onClick={(e) => {
											e.stopPropagation();
											handelSeen(data._id);
										}}>
										Mark as Seen
									</button>
								)}
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
}
export default Notification;
