import React from "react";
import { FaTimes } from "react-icons/fa";

const ProgressBar = ({ progress, onCancel }) => {
  return (
    <div className="fixed top-0 left-0 w-full bg-black bg-opacity-80 flex items-center justify-center py-2 z-50">
      <div className="flex items-center w-4/5 space-x-3">
        <div className="w-full bg-gray-300 rounded-full h-2.5 overflow-hidden">
          <div
            className="bg-green-500 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <span className="text-white">{progress}%</span>
        <FaTimes className="text-white cursor-pointer" onClick={onCancel} />
      </div>
    </div>
  );
};

export default ProgressBar;
