import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useValidateWithoutLogin = () => {
	const navigate = useNavigate();
	useEffect(() => {
		console.log(localStorage.getItem("auth_token") + ' ' + sessionStorage.getItem("auth_token"));
		if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
			if (localStorage.getItem("user_type") == "professional" || sessionStorage.getItem("user_type") == "professional")
				return navigate("/vodcast");
			else if (localStorage.getItem("user_type") == "user" || sessionStorage.getItem("user_type") == "user")
				return navigate("/vodcast");
		}
	}, []);
};

export default useValidateWithoutLogin;
