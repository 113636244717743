const constants = {
  API_URL: "http://localhost:3005",
  colors: {
    primary: "#471CF1",
    secondary: "#1C1C1C",
    disabled: "#333333",
  },
  images: {
    logo: require("../assets/images/logo.png"),
  },
  dummy: {
    banner: require("../assets/dummy/banner.png"),
  },
};

export default constants;
