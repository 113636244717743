import { MdNotStarted } from "react-icons/md";
import {
  BsFillPlayFill,
  BsFillPauseFill,
  BsZoomOut,
  BsZoomIn,
  BsSkipForwardCircle,
  BsSkipBackwardCircle,
  BsScissors,
} from "react-icons/bs";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import Button from "react-bootstrap/Button";
import {
  zoomState,
  playState,
  timeState,
  videoListState,
  zoomInState,
  zoomOutState,
  editState,
  textListState,
  elementState,
} from "../atoms";
import { GrBackTen, GrForwardTen, GrZoomOut, GrZoomIn } from "react-icons/gr";
import { TbArrowAutofitWidth } from "react-icons/tb";
import Export from "./Export";

import React, { useState, useEffect, useContext, useRef } from "react";
// import { AppContext } from "../components/VideoEditorWrapper";
import { IoMdFastforward } from "react-icons/io";
import Timer from "../Timer.mp4";
import cloneDeep from "lodash/cloneDeep";
import CustomModal from "./CustomModal";
import Seekbar from "../../../Vodcast/components/VodcastPlayer/Seekbar";
import { Box, IconButton, Stack, Tooltip } from "@mui/joy";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiCursorLine,
  RiFileAddLine,
  RiImageAddLine,
  RiPauseLine,
  RiPlayLine,
  RiScissorsCutLine,
  RiText,
  RiVideoAddLine,
} from "react-icons/ri";
import { Grid } from "react-loader-spinner";

function ControlBar() {
  const [zoom, setZoom] = useRecoilState(zoomState);
  const [play, setPlay] = useRecoilState(playState);
  const [internalTime, setInternalTime] = useState(0);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [time, setTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [zoomIn, setZoomIn] = useRecoilState(zoomInState);
  const [zoomOut, setZoomOut] = useRecoilState(zoomOutState);
  const [edit, setEdit] = useRecoilState(editState);
  const [textList, setTextList] = useRecoilState(textListState);
  const [element, setElement] = useRecoilState(elementState);

  useEffect(() => {
    let intervalId = null;
    intervalId = setInterval(() => {
      let video = document.getElementById("editorTimer");
      setTime(video.currentTime * 1000);
    }, 20);

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    let video = document.getElementById("editorTimer");
    if (play === true) {
      video.play();
    }
    if (play === false) {
      video.pause();
    }
  }, [play]);

  function changeZoom(e) {
    //setZoom(e)
  }

  function playpause() {
    setPlay(!play);
  }

  function millisecondsToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs;
  }

  function autoFit() {
    setZoom(videoList[videoList.length - 1].endTime);
  }

  const handleClose = () => {
    setShowModal(false);
  };

  function newText() {
    //const newArr = JSON.parse(JSON.stringify(textList));

    let newArr = [...textList];
    newArr.push({
      id: textList.length,
      group: 0,
      text: " AddedText ",
      x: 0,
      y: 0,
      startTime: time,
      endTime: time + 5000,
      color: "#aabbcc",
      backgroundColor: "rgba(70,70,70,0.7)",
      size: 40,
      visible: false,
      font: "Arial",
    });
    setTextList(newArr);
    setElement(textList.length);
    setEdit("text");
  }

  async function getFileFromUrl(url, name, defaultType = "video/mp4") {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  useEffect(() => {}, [videoList]);

  async function setVideo(part1, part2) {
    /* var video = document.createElement('video');
     video.src = URL.createObjectURL(e)
   
     function setDuration() {
       let duration = video.duration * 1000
       let vl = cloneDeep(videoList)
   
       let starttime = 0
       if (vl.length > 0) {
         starttime = vl[vl.length - 1].endTime
       }

   
       vl.push({ 
         id: vl.length, 
         "src": URL.createObjectURL(e), 
         file: e, 
         duration: duration, 
         "x": null, 
         "y": null, 
         startTime: starttime, 
         endTime: starttime + duration, 
         trimmStart: 0.0, 
         trimmEnd: 0.0 
       })
   
       setVideoList(vl)
       setZoom(10000)
     }
   
     video.addEventListener("loadedmetadata", setDuration);*/

    let currentVideoId = 0;
    for (let i = 0; i < videoList.length; i++) {
      if (videoList[i].startTime < time && videoList[i].endTime > time) {
        currentVideoId = i;
      }
    }

    var part1video = document.createElement("video");
    part1video.src = URL.createObjectURL(part1);

    var part2video = document.createElement("video");
    part2video.src = URL.createObjectURL(part2);

    let vl = [];

    function setDuration(p) {
      return new Promise((resolve) => {
        let duration = p.duration * 1000;

        let starttime = videoList[videoList.length - 1].startTime;
        if (vl.length > 0) {
          starttime = vl[vl.length - 1].endTime;
        }

        vl.push({
          id: currentVideoId + vl.length,
          src: p.src,
          file: p,
          duration: duration,
          x: null,
          y: null,
          startTime: starttime,
          endTime: starttime + duration,
          trimmStart: 0.0,
          trimmEnd: 0.0,
        });

        resolve();
      });
    }
    let newVideoList = cloneDeep(videoList);

    async function handleLoadedMetadata(partVideo) {
      return new Promise((resolve) => {
        partVideo.addEventListener("loadedmetadata", async () => {
          await setDuration(partVideo);
          resolve();
        });
      });
    }

    await handleLoadedMetadata(part1video);
    await handleLoadedMetadata(part2video);

    newVideoList.splice(currentVideoId, 1, vl[0], vl[1]);

    setVideoList(newVideoList);
  }

  function getCurrentVideoId() {
    for (let i = 0; i < videoList.length; i++) {
      if (videoList[i].startTime < time && videoList[i].endTime > time) {
        return i;
      }
    }
    return null;
  }

  function findStartBySrc(src) {
    // Assuming videoList is accessible in the scope of this function
    const video = videoList.find((video) => video.src === src);

    // If a video was found, return its startTime property; otherwise, return null
    return video ? video.startTime : null;
  }

  function cut() {
    if (time > 0) {
      let vl = cloneDeep(videoList);
      let videoid = getCurrentVideoId();

      let clonedObject = cloneDeep(vl[videoid]);

      //old object
      vl[videoid].trimmEnd = vl[videoid].endTime - time;
      vl[videoid].endTime = time;

      let offset = findStartBySrc(clonedObject.src);
      //new object
      clonedObject.startTime = time;
      clonedObject.trimmStart = time - offset;

      vl.splice(videoid + 1, 0, clonedObject);

      vl.forEach((item, index) => {
        item.id = index;
      });

      setVideoList(vl);
    }
  }

  console.log(videoList?.[0]);

  return (
    <div>
      <CustomModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title="Splitting Video"
        message="This function splits your video at the current position."
        estimatedTime={60}
      />

      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 0.5 }}
      >
        <Stack direction="row" spacing={1}>
          {/* <Tooltip title="Split Tool" variant="solid" placement="top-start">
            <IconButton
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
              onClick={() => cut()}
            >
              <RiScissorsCutLine />
            </IconButton>
          </Tooltip> */}
          {/* <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
            <RiFileAddLine />
          </IconButton> */}
          {/* <Tooltip title="Add Video" variant="solid" placement="top-start">
            <IconButton
              onClick={() => setEdit("pexelvideos")}
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
            >
              <RiVideoAddLine />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Add Image" variant="solid" placement="top-start">
            <IconButton
              onClick={() => setEdit("pexelimages")}
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
            >
              <RiImageAddLine />
            </IconButton>
          </Tooltip> */}
          {/* <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
            <RiCursorLine />
          </IconButton> */}
          {/* <Tooltip title="Add Text" variant="solid" placement="top-start">
            <IconButton
              onClick={() => newText()}
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
            >
              <RiText />
            </IconButton>
          </Tooltip> */}
        </Stack>
        <Stack direction="row">
          <IconButton
            onClick={() => playpause()}
            sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
          >
            {play ? <RiPauseLine size={20} /> : <RiPlayLine size={20} />}
          </IconButton>
        </Stack>
        <Stack direction="row">
          <Tooltip title="Undo" variant="solid" placement="top-start">
            <IconButton
              disabled
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
            >
              <RiArrowGoBackLine />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo" variant="solid" placement="top-start">
            <IconButton
              disabled
              sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}
            >
              <RiArrowGoForwardLine />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Seekbar
        duration={videoList[videoList.length - 1]?.endTime}
        currentTime={time}
      />
      <video
        muted
        autoPlay
        style={{ maxWidth: "50px", display: "none" }}
        id="editorTimer"
        src={Timer}
      ></video>
    </div>
  );
}

export default ControlBar;

{
  /* <div
        style={{
          position: "relative",
          backgroundColor: "#141725",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-Between",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: "1vh", width: "15vw" }}>
          {videoList[videoList.length - 1] !== undefined ? (
            <h3
              style={{
                color: "#F7F7F7",
                fontSize: window.innerWidth <= 800 ? "6px" : "16px",
              }}
            >
              {millisecondsToTime(time)} /{" "}
              {millisecondsToTime(videoList[videoList.length - 1].endTime)}
            </h3>
          ) : null}
        </div>
        <Slider waria-label="Volume" value={zoom} onChange={e => changeZoom(e.target.value)} onChangeCommitted={() => resetTimeline()} min={1000} max={100000} style = {{margin:"1.5vh", width:"30%"}}/>
        <div>
          <Button
            style={{ color: "#F7F7F7", justifyContent: "center" }}
            variant="muted"
            onClick={() => playpause()}
          >
            {play ? (
              <BsFillPauseFill size={window.innerWidth <= 800 ? 20 : 40} />
            ) : (
              <BsFillPlayFill size={window.innerWidth <= 800 ? 20 : 40} />
            )}
          </Button>{" "}
          <video
            autoPlay
            style={{ maxWidth: "50px", display: "none" }}
            id="editorTimer"
            src={Timer}
          ></video>
        </div>
       <div>
                    <Button style={{ color: "#F7F7F7" }} variant="muted" onClick={() => { changeZoom(zoom + 2000) }}><BsZoomOut /></Button>{' '}
                    <Button style={{ color: "#F7F7F7" }} variant="muted" onClick={() => { changeZoom(zoom - 2000) }}><BsZoomIn /></Button>{' '}
                </div>
        <div>
          <Button
            id="zoomIn"
            variant="muted"
            onClick={() => {
              setZoomOut(zoomOut + 1);
            }}
          >
            <BsZoomOut style={{ color: "#F7F7F7" }} size={20} />
          </Button>{" "}
          <Button
            variant="muted"
            onClick={() => {
              setZoomIn(zoomIn + 1);
            }}
          >
            <BsZoomIn style={{ color: "#F7F7F7" }} size={20} />
          </Button>{" "}
          <Button
            variant="muted"
            onClick={() => {
              autoFit();
            }}
          >
            <TbArrowAutofitWidth style={{ color: "#F7F7F7" }} size={20} />
          </Button>{" "}
        </div>
        <Button
          variant="muted"
          onClick={() => {
            cut();
          }}
        >
          <BsScissors style={{ color: "#F7F7F7" }} size={20} />
        </Button>{" "}
        <div style={{ marginRight: "1vh" }}>{/* <Export /> </div>
      </div> */
}
