import React, { Suspense } from "react";
import { editState } from "../atoms";
import { useRecoilState } from "recoil";

import TextEditor from "./TextEditor";
import Subtitles from "./Subtitles";
import ImageEditor from "./ImageEditor";
import Settings from "./Settings";
import VideoEditor from "./VideoEditor";
import AiVoice from "./AiVoice";
import AudioEditor from "./AudioEditor";

const PexelVideos = React.lazy(() => import("./PexelVideos"));
const PexelImages = React.lazy(() => import("./PexelImages"));

const Editor = () => {
  const [edit, setEdit] = useRecoilState(editState);

  return (
    <div
      style={{
        backgroundColor: "#131313",
        width: "100%",
        height: "95%",
        borderRadius: "0px",
        overflow: "scroll",
        padding: 10,
        position: "relative",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          transform: "rotate(270deg)",
          position: "absolute",
          opacity: 0.1,
          mixBlendMode: "screen",
        }}
        src={require("../../../images/logoM.png")}
      />
      {edit != null ? (
        {
          text: <TextEditor />,
          // 'upload': <Upload />,
          pexelvideos: (
            <Suspense fallback={<div>Loading...</div>}>
              <PexelVideos />
            </Suspense>
          ),
          pexelimages: (
            <Suspense fallback={<div>Loading...</div>}>
              <PexelImages />
            </Suspense>
          ),
          video: <VideoEditor />,
          image: <ImageEditor />,
          subtitles: <Subtitles />,
          settings: <Settings />,
          aivoice: <AiVoice />,
          audio: <AudioEditor />,
        }[edit]
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Editor;
