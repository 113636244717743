import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  CssVarsProvider,
  Grid,
  IconButton,
  Input,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos } from "../../services/pexels";
import {
  RiArrowLeftLine,
  RiBox1Line,
  RiBoxingLine,
  RiLineChartLine,
  RiSearchLine,
  RiUpload2Line,
  RiUploadLine,
  RiVideoLine,
} from "react-icons/ri";
import { FiBox } from "react-icons/fi";
import VideoThumbnailCard from "../components/VideoThumbnailCard";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams } from "react-router-dom";
import { humanizeString } from "../../utils/humanizeString";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../utils/httpMethods";
import { useSelector } from "react-redux";

function VodcastSectionPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [videosData, setVideosData] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [totalCount, setTotalCount] = useState(-1);
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);

  const [activeCategory, setActiveCategory] = useState("Music Learning");

  const { user } = useSelector((state) => state.auth);

  const isPro = user?.user_type === "professional";

  const allTags = [
    "Lifestyle",
    "Culture",
    "General Topics",
    "Traditional",
    "Modern",
    "Rap",
    "LoFi",
    "Others",
  ];

  const [loadingMore, setLoadingMore] = useState(false);
  const [title, setTitle] = useState("");

  const sections = ["new_release", "trendings"];

  useEffect(() => {
    setLoadingCategory(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/admin/get-vodcast-categories`,
      "GET"
    ).then((res) => {
      setLoadingCategory(false);
      if (res.status == true) {
        setCategory(res?.data);
      } else {
        console.log("incorrect");
      }
    });
  }, []);

  const loadMore = () => {
    if (totalCount > (page - 1) * 10) {
      return;
    }
    !loading && setPage((p) => p + 1);
  };

  useEffect(() => {
    if (id) {
      setTitle(humanizeString(id));
      if (sections.includes(humanizeString(id))) {
        setTitle(humanizeString(id));
      } else {
        // navigate("/vodcast");
      }
    }
  }, [id]);

  const fetchData = useCallback(() => {
    if (category.length === 0) {
      return;
    }

    page == 1 && setData({});
    page == 1 && setLoading(true);
    page > 1 && setLoadingMore(true);
    if (id === "new_release") {
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/new-release?offset=${(page - 1) * 10
        }`,
        "GET"
      ).then((res) => {
        setLoading(false);
        setLoadingMore(false);
        if (res.status == true) {
          setData((d) => ({ ...d, [page]: res.data }));
          setTotalCount(res.total_count);
        } else {
          console.log("incorrect");
        }
      });
    } else {
      if (id === "trendings") {
        getData(
          `${process.env.REACT_APP_BASE_URL}/vodcast/trendings?offset=${(page - 1) * 10
          }`,
          "GET"
        ).then((res) => {
          setLoading(false);
          setLoadingMore(false);
          if (res.status == true) {
            setData((d) => ({ ...d, [page]: res.data }));
            setTotalCount(res.total_count);
          } else {
            console.log("incorrect");
          }
        });
      } else {
        let c_id = category?.find(
          (i) => i.name === id.replace("%20", " ")
        )?._id;
        console.log(c_id);
        getData(
          `${process.env.REACT_APP_BASE_URL
          }/vodcast/get-vodcast-by-category/${c_id}?offset=${(page - 1) * 10}`,
          "GET"
        ).then((res) => {
          setLoading(false);
          setLoadingMore(false);
          if (res.status == true) {
            setData((d) => ({ ...d, [page]: res.data }));
            setTotalCount(res.total_count);
            if (res.total_count === 0) {
              setData({});
              setTotalCount(0);
              setPage(1);
            }
          } else {
            console.log("incorrect");
          }
        });
      }
    }
  }, [id, page, category]);

  useEffect(() => {
    if (category.length === 0) {
      return;
    }
    setPage(1);

    page == 1 && setData({});
    page == 1 && setLoading(true);
    page > 1 && setLoadingMore(true);
    if (id === "new_release") {
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/new-release?offset=${(page - 1) * 10
        }`,
        "GET"
      ).then((res) => {
        setLoading(false);
        setLoadingMore(false);
        if (res.status == true) {
          setData((d) => ({ ...d, [page]: res.data }));
          setTotalCount(res.total_count);
        } else {
          console.log("incorrect");
        }
      });
    } else {
      if (id === "trendings") {
        getData(
          `${process.env.REACT_APP_BASE_URL}/vodcast/trendings?offset=${(page - 1) * 10
          }`,
          "GET"
        ).then((res) => {
          setLoading(false);
          setLoadingMore(false);
          if (res.status == true) {
            setData((d) => ({ ...d, [page]: res.data }));
            setTotalCount(res.total_count);
          } else {
            console.log("incorrect");
          }
        });
      } else {
        let c_id = category?.find(
          (i) => i.name === id.replace("%20", " ")
        )?._id;
        console.log(c_id);
        getData(
          `${process.env.REACT_APP_BASE_URL
          }/vodcast/get-vodcast-by-category/${c_id}?offset=${(page - 1) * 10}`,
          "GET"
        ).then((res) => {
          setLoading(false);
          setLoadingMore(false);
          if (res.status == true) {
            setData((d) => ({ ...d, [page]: res.data }));
            setTotalCount(res.total_count);
            if (res.total_count === 0) {
              setData({});
              setTotalCount(0);
              setPage(1);
            }
          } else {
            console.log("incorrect");
          }
        });
      }
    }
  }, [id, page, category]);

  return (
    <CommonLayout
      showUpload={showUpload}
      setShowUpload={setShowUpload}
      onUploadedCallback={fetchData}
    >
      <InfiniteScroll
        dataLength={Object.values(data)?.flat().length}
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <Grid
          container
          lg={12}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Grid xs={6} sm={6} md={6} lg={8}>
            <Stack direction={"row"}>
              <IconButton
                onClick={() => navigate("/vodcast")}
                sx={{ ":hover": { background: "transparent" } }}
              >
                <RiArrowLeftLine size={30} />
              </IconButton>
              <Breadcrumbs>
                <Link
                  fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}
                  color="neutral"
                  href="/vodcast"
                >
                  Home
                </Link>
              </Breadcrumbs>
            </Stack>
            <BigTitle shimmer={loading}>{title || "Vodcast"}</BigTitle>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={4}
            sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}
          >
            {isPro && (
              <ActionButton
                onClick={() => navigate("/vodcast/manage")}
                showIconForMobile
                shimmer={loading}
                title="Manage Vodcast"
                sx={{ backgroundColor: "#303030", color: "#A1A1A1" }}
                startDecorator={<FiBox size={16} />}
              // startDecorator={<RiBoxingLine size={16} />}

              />
            )}
            {isPro && (
              <ActionButton
                onClick={() => {
                  setShowUpload(true);
                }}
                showIconForMobile
                shimmer={loading}
                title="Upload Vodcast"
                sx={{
                  backgroundColor: constants.colors.primary,
                  color: "#FFFFFF",
                }}
                startDecorator={<RiUploadLine size={16} />}
              />
            )}
          </Grid>
        </Grid>

        {/* <Grid
          container
          lg={12}
          marginY={3}
          alignItems="center"
          sx={{ overflow: "hidden", overflowX: "scroll" }}
          // paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Stack
            direction="row"
            spacing={2}
            paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
            sx={{}}
          >
            {loading &&
              [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                <Skeleton key={index} variant="inline" loading={loading}>
                  <VideoThumbnailCard data={item} key={index} />
                </Skeleton>
              ))}
            {!loading &&
              data &&
              Object.values(data)
                ?.flat()
                .map((item, index) => (
                  <VideoThumbnailCard data={item} key={index} />
                ))}
          </Stack>
        </Grid> */}
        <Grid
          container
          lg={12}
          marginY={5}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={2} sx={{ mb: { xs: 2, sm: 0 } }}>
            {category?.map((item, index) => (
              <Skeleton
                key={index}
                variant="inline"
                sx={{ borderRadius: 30 }}
                loading={loading}
              >
                <Button
                  onClick={() => navigate(`/vodcast/${item.name}`)}
                  sx={[
                    {
                      minHeight: { xs: 30, sm: 30, md: 35 },
                      borderRadius: 30,
                      backgroundColor: "#303030",
                      color: "#515151",
                      fontSize: { xs: 10, sm: 12, md: 14, lg: 14 },
                    },
                    item.name === id?.replace("%20", " ") && {
                      backgroundColor: constants.colors.primary,
                      color: "#fff",
                    },
                  ]}
                >
                  {item.name}
                </Button>
              </Skeleton>
            ))}
          </Stack>
          <Skeleton
            variant="inline"
            sx={{ borderRadius: 30, my: { xs: 1 } }}
            loading={loading}
          >
            <Grid xs={12} sm={3} md={3} lg={2.5}>
              <Input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value !== "") {
                      navigate(`/vodcast/search?value=${e.target.value}`);
                    }
                  }
                }}
                variant="outlined"
                placeholder="Search"
                sx={{
                  borderRadius: { xs: 16, sm: 16 },
                  background: "transparent",
                  borderColor: "#565656",
                  color: "#fff",
                  fontSize: "sm",
                  "--Input-placeholderOpacity": 0.2,
                }}
                startDecorator={<RiSearchLine />}
              />
            </Grid>
          </Skeleton>
        </Grid>
        <PaddingContainer>
          <Grid container spacing={2}>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard shimmer={loading} data={item} key={index} />
              ))}
            {!loading &&
              data &&
              Object.values(data)
                ?.flat()
                ?.map((item, index) => (
                  <VideoListCard data={item} key={index} />
                ))}
          </Grid>
        </PaddingContainer>
        {totalCount == 0 && (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              py: 4,
            }}
          >
            <RiVideoLine size={80} style={{ opacity: 0.3 }} />
            <Typography level="title-lg" sx={{ color: "#FFFFFF4A", mt: 5 }}>
              NO RELATED VODCAST FOUND
            </Typography>
          </Container>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
          }}
        >
          {loadingMore ? (
            <CircularProgress variant="soft" />
          ) : (
            Object.values(data)?.flat().length < totalCount && (
              <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                END OF CONTENT
              </Typography>
            )
          )}
        </Container>
      </InfiniteScroll>
    </CommonLayout>
  );
}

export default VodcastSectionPage;
