import {
  Button,
  Checkbox,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import LogoComponent from "../AltLandingPage/components/LogoComponent";
import { GrGoogle } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
import LoadingModal from "../components/LoadingModal";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import jwt_decode from "jwt-decode";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { saveUser } from "../Redux/slices/AuthSlice";

const RegistrationPage = () => {
  useValidateWithoutLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gLoginRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [loader, setLoader] = useState(false);
  const [Pass1, setPass1] = useState("");
  const [Pass2, setPass2] = useState("");
  const [file, setFile] = useState([]);
  const [checkBox, setCheckBox] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    setIsValid(validateEmail(event.target.value));
  };

  const validatePass = (pass) => {
    const passRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return passRegex.test(pass);
  };

  const handleChangePass = (event) => {
    setPass1(event.target.value);
    setIsValid1(validatePass(event.target.value));
  };

  const [eyeV, setEyeV] = useState("password");
  function visible() {
    if (eyeV == "password") setEyeV("text");
    else setEyeV("password");
  }
  const [eyeV2, setEyeV2] = useState("password");
  function visible2() {
    if (eyeV2 == "password") setEyeV2("text");
    else setEyeV2("password");
  }

  async function Register() {
    if (email === "" || name === "")
      return toast.warn("Please fill all fields!");

    if (isValid == false) return toast.warn("Choose a Correct Email");

    if (Pass1.length < 8)
      return toast.warn("Please enter minimum 8 characters");

    if (isValid1 === false)
      return toast.warn("Please enter a special character !");

    if (Pass1 != Pass2) return toast.warn("write correct Password");

    console.log("Coming");
    /* if (checkBox == false)
      return toast.warn("Please Accept Terms and Conditions"); */
    setLoader(true);
    setBtnDisable(true);
    let formData = new FormData();
    formData.append("image", file);
    formData.append("email", email);
    formData.append("password", Pass1);
    formData.append("full_name", name);
    formData.append("user_type", type);

    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/register`,
      {
        method: "POST",
        body: formData,
      }
    );
    let result = await response.json();
    setLoader(false);
    toast.success(result.message);

    if (result.status == true) {

      dispatch(saveUser({ ...result, rememberMe: true }));
      window.open("/vodcast", "_self");
    }
    // if (result.status == true) navigate("/login");
    else setBtnDisable(false);
  }

  async function postData(url = "", data = {}, methods = "") {
    const response = await fetch(url, {
      method: methods,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  function handelCallBackResponse(response) {
    let userObject = jwt_decode(response.credential);
    let data = {
      email: userObject.email,
      password: userObject.jti,
      ...(userObject.family_name
        ? { full_name: userObject.given_name + " " + userObject.family_name }
        : { full_name: userObject.given_name }),
      image: userObject.picture,
      user_type: type,
    };
    postData(
      `${process.env.REACT_APP_BASE_URL}/auth/google-register`,
      data,
      "POST"
    ).then((data) => {
      if (data.status == true) {
        dispatch(saveUser({ ...data, rememberMe: true }));

        window.open("/vodcast", "_self");
      } else {
        console.log(data);
        toast.warn("incorrect credentials");
      }
    });
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window?.google?.accounts?.id?.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handelCallBackResponse,
      });
      window?.google?.accounts?.id?.renderButton(
        document.getElementById("signInDiv"),
        {
          theme: "dark",
          size: "large",
          width: gLoginRef.current.offsetWidth,
        }
      );
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Grid
      container
      sx={{ background: "#09041C", height: "100vh", width: "100%" }}
    >
      <LoadingModal open={loader} />
      <Grid md={4} xs={0} sm={0} lg={6} sx={{ height: "100%" }}>
        <img
          src="/auth_image.png"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        container
        md={8}
        lg={6}
        sm={12}
        xs={12}
        sx={{
          overflow: "scroll",
          height: "100%",
          px: { xs: 2, sm: 4, md: 8 },
          py: { xs: 2, sm: 3, md: 3 },
        }}
      >
        <Grid xs={12} md={10}>
          <Stack sx={{ height: "100%" }}>
            <LogoComponent />
            <Stack
              sx={{ flex: 1, justifyContent: "center" }}
              spacing={{ xs: 4, md: 4 }}
            >
              <Stack>
                <Typography
                  textColor={"#fff"}
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: { xs: "16px", md: "20px" },
                  }}
                >
                  CREATE YOUR ACCOUNT
                </Typography>
                <Typography
                  textColor={"#fff"}
                  sx={{
                    py: { xs: 2, md: 2 },
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    fontSize: { xs: "20px", md: "40px" },
                    lineHeight: { xs: "20px", md: "40px" },
                  }}
                >
                  Welcome to Music book
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Select
                  onChange={(e, v) => setSearchParams({ type: v })}
                  defaultValue={type || "user"}
                  value={type || "user"}
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  slotProps={{
                    listbox: {
                      size: "md",
                      sx: {
                        color: "#fff",
                        background: "#000000",
                        " & .Mui-selected": {
                          background: "blue!important",
                        },
                      },
                    },
                    root: {
                      sx: {
                        height: "40px",
                        background: "transparent",
                        borderColor: "#fff",
                        color: "#fff",
                        fontSize: { xs: 12, md: 14, lg: 16 },
                        ":hover": {
                          background: "transparent",
                        },
                      },
                    },
                    button: {
                      sx: {
                        borderColor: "#fff",
                        color: "#fff",
                        fontSize: { xs: 12, md: 14, lg: 16 },
                      },
                    },
                  }}
                >
                  <Option
                    slotProps={{
                      root: {
                        sx: {
                          color: "#fff",
                        },
                      },
                    }}
                    value="user"
                  >
                    Personal
                  </Option>
                  <Option
                    slotProps={{
                      root: {
                        sx: {
                          color: "#fff",
                        },
                      },
                    }}
                    value="professional"
                  >
                    Professional
                  </Option>
                </Select>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  size="lg"
                  type="text"
                  placeholder="Enter your name"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />

                <Input
                  onChange={handleChange}
                  value={email}
                  size="lg"
                  type="email"
                  placeholder="Enter your email"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />
                <Input
                  size="lg"
                  type={eyeV}
                  endDecorator={
                    eyeV === "password" ? (
                      <RiEyeOffLine size={18} onClick={visible} />
                    ) : (
                      <RiEyeLine size={18} onClick={visible} />
                    )
                  }
                  onChange={handleChangePass}
                  value={Pass1}
                  placeholder="New Password"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />
                <Input
                  size="lg"
                  type={eyeV2}
                  endDecorator={
                    eyeV2 === "password" ? (
                      <RiEyeOffLine size={18} onClick={visible2} />
                    ) : (
                      <RiEyeLine size={18} onClick={visible2} />
                    )
                  }
                  onChange={(e) => setPass2(e.target.value)}
                  value={Pass2}
                  placeholder="Retype Password"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />

                <Stack spacing={1.5}>
                  <Button
                    onClick={Register}
                    color="neutral"
                    sx={{
                      background: "#471CF1",
                      borderRadius: "10px",
                      fontSize: { xs: 14 },
                    }}
                    size="lg"
                  >
                    CREATE AN ACCOUNT
                  </Button>
                  <Typography
                    color="neutral"
                    sx={{
                      fontSize: 12,
                      textAlign: "center",
                      lineHeight: "12px",
                    }}
                  >
                    OR
                  </Typography>
                  <Button
                    onClick={() => {
                      document.getElementById("signInDiv")?.click();
                    }}
                    slotProps={{
                      root: {
                        ref: gLoginRef,
                      },
                    }}
                    startDecorator={<FcGoogle />}
                    color="neutral"
                    sx={{
                      background: "#160B42",
                      borderRadius: "10px",
                      fontSize: { xs: 14 },
                    }}
                    size="lg"
                  >
                    <div
                      id="signInDiv"
                      style={{
                        // width: gLoginRef.current?.offsetWidth  || "100%",
                        position: "absolute",
                        opacity: 0,
                      }}
                    />
                    SIGN UP WITH GOOGLE ACCOUNT
                  </Button>
                </Stack>
              </Stack>
              <Typography
                color="neutral"
                sx={{ fontSize: { xs: 14, md: 12 }, textAlign: "center" }}
              >
                Already have an account ?
                <Button
                  onClick={() => {
                    navigate("/login");
                  }}
                  /* slotProps={{
                    root: {
                      sx: {
                        padding: 0,
                        paddingLeft: "5px",
                        margin: 0,
                        minHeight: 0,
                        color: "#FFFFFFdd",
                        ":hover": {
                          background: "transparent",
                          color: "#ffffff90",
                        },
                      },
                    },
                  }} */
                  sx={{
                    padding: 0,
                    paddingLeft: "5px",
                    margin: 0,
                    minHeight: 0,
                    color: "#FFFFFFdd",
                    ":hover": {
                      background: "transparent",
                      color: "#ffffff90",
                    },
                    "&.MuiButton-root": {
                      // This targets the root class of the Button component
                      // Additional styles for the root class if needed
                    },
                  }}
                  variant="plain"
                >
                  Log In
                </Button>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegistrationPage;
