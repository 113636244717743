import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import swal from "sweetalert";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { CropEasy } from "../components/crop/utils/CropEasy";
import eye from "../images/eye-solid.svg";

function Register1Professional() {
	useValidateWithoutLogin();
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [isValid1, setIsValid1] = useState(true);
	const [btnDisable, setBtnDisable] = useState(false);
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [Pass1, setPass1] = useState("");
	const [Pass2, setPass2] = useState("");
	const [file, setFile] = useState([]);
	const [checkBox, setCheckBox] = useState(false);

	const validateEmail = (email) => {
		const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		return emailRegex.test(email);
	};

	const handleChange = (event) => {
		setEmail(event.target.value);
		setIsValid(validateEmail(event.target.value));
	};

	const validatePass = (pass) => {
		const passRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		return passRegex.test(pass);
	};

	const handleChangePass = (event) => {
		setPass1(event.target.value);
		setIsValid1(validatePass(event.target.value));
	};

	const [eyeV, setEyeV] = useState("password");
	function visible() {
		if (eyeV == "password") setEyeV("text");
		else setEyeV("password");
	}
	const [eyeV2, setEyeV2] = useState("password");
	function visible2() {
		if (eyeV2 == "password") setEyeV2("text");
		else setEyeV2("password");
	}

	async function Register() {
		if (email === "" || name === "") return toast.warn("Please fill all fields!");

		if (isValid == false) return toast.warn("Choose a Correct Email");

		if (Pass1.length < 8) return toast.warn("Please enter minimum 8 characters");

		if (isValid1 === false) return toast.warn("Please enter a special character !");

		if (Pass1 != Pass2) return toast.warn("write correct Password");

		if (checkBox == false) return toast.warn("Please Accept Terms and Conditions");

		setBtnDisable(true);
		let formData = new FormData();
		formData.append("image", file);
		formData.append("email", email);
		formData.append("password", Pass1);
		formData.append("full_name", name);
		formData.append("user_type", "professional");

		let response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
			method: "POST",
			body: formData,
		});
		let result = await response.json();
		toast.success(result.message);

		if (result.status == true) navigate("/Professional-login");
		else setBtnDisable(false);
	}

	// const [preview, setPreview] = useState("");

	// const handleFileInputChange = (e) => {
	// 	const file = e.target.files[0];
	// 	if (file) {
	// 		setFile(file);
	// 		// setImage(URL.createObjectURL(file));
	// 		setPhotoURL(URL.createObjectURL(file));
	// 		setOpenCrop(true);
	// 	}
	// 		const reader = new FileReader();

	// 		reader.onloadend = () => {
	// 			setPreview(reader.result);
	// 		};

	// 	if (file) {
	// 		reader.readAsDataURL(file);
	// 	} else {
	// 		setPreview("");
	// 	}
	// 	e.target.value = null;
	// };

	return (
		<div className="professional-login">
			{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
			<div className="left">
				<div className="leftHeading">Teach your audiance what they want to learn</div>
				<br />
				<br />
				<br />
				<br />
				<div className="dots">
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="Registercard card professional-card">
				<div className="card-body">
					<h1 className="professional-card-title">REGISTER</h1>
					<br />
					{/* <div>
						{photoURL ? (
							<img src={photoURL} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						) : (
							<img src={image} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						)}
						<input type="file" id="image-input" onChange={handleFileInputChange} />
					</div> */}
					<input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
					<input type="email" placeholder="Email" onChange={handleChange} />
					<input className="eye-pass" type={eyeV} placeholder="Password" onChange={handleChangePass} />
					<img src={eye} className="eye-register" onClick={visible} style={{ cursor: "pointer" }} alt="" />
					<input className="eye-pass" type={eyeV2} placeholder="Password" onChange={(e) => setPass2(e.target.value)} />
					<img src={eye} className="eye2-register" onClick={visible2} style={{ cursor: "pointer" }} alt="" />{" "}
					<div className="display-flex">
						<div>
							<input
								type="checkbox"
								onChange={(event) => setCheckBox(event.target.checked)}
								style={{ height: "15px", marginTop: "10px", marginLeft: "15px" }}
							/>
						</div>
						<div style={{ marginLeft: "15px" }}>
							<p className="card-text">
								I Agree to <Link to="/professional-privacy">terms & conditions </Link>and{" "}
								<Link to="/professional-privacy">privacy policy</Link>
							</p>
						</div>
					</div>
					<button className="loginWithEmail" disabled={btnDisable} onClick={Register}>
						{btnDisable ? "" : "Continue"}
						<ThreeCircles
							height="30"
							width="100%"
							color="#4fa94d"
							wrapperStyle={{}}
							wrapperClass=""
							visible={btnDisable}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					</button>
					<p className="card-text">
						I already have an account <Link to="/Professional-login">Login</Link>
					</p>
				</div>
			</div>
		</div>
	);
}
export default Register1Professional;
