import React, { useRef, useState } from "react";
import Style from "../style/contact.module.css";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useMediaQuery } from "@material-ui/core";

function ContactSection() {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_edw5zwh",
        "template_kles9pm",
        form.current,
        "QrtWL2Dt7o33Y5cjI"
      )
      .then(
        (result) => {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "You have subscribed successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          setLoading(false);
          Swal.fire({
            title: "Failed",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
  };

  const isMobile = useMediaQuery("(max-width:640px)");
  return (
    <div className={Style.ContentSection}>
      <div
        className="flex justify-evenly align-middle md:gap-0 gap-6 md:px-1 px-6"
        style={{ flexWrap: isMobile ? "wrap" : "nowrap" }}
      >
        <div className="Style.Content sm:px-0 px-6">
          {/* <h2 className="text-4xl font-semibold">News Letter</h2> */}
          <h2 className="text-4xl font-semibold">Music Book</h2>
          <p className="text-slate-400 w-2/3 pt-6 ">
            Get Latest update, offer about music events and live events all
            directly to your inbox. You can unsubscribe anytime.
          </p>
          <div className="pt-3 flex flex-col">
            <a to={"mailto:support@musicbook.co.in"}>
              Email Us : support@musicbook.co.in
            </a>
            <a to={"tel:919019187374"}>Call Us : +91 9019187374</a>
          </div>
        </div>
        {/* <div className="w-full flex justify-center align-middle">
          <form
            className="flex flex-col justify-center align-middle gap-6"
            // action="/" method="post"
            ref={form}
            onSubmit={sendEmail}
          >
            <input
              className={Style.inputName}
              type="text"
              name="from_name"
              id="name"
              placeholder="Enter your name"
              required
            />
            <input
              className={Style.inputEmail}
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              required
            />
            {loading ? (
              <div className={Style.loadingButton}>
                <span className={Style.loader}></span>
              </div>
            ) : (
              <input
                className={Style.inputButton}
                type="submit"
                value="Subscribe"
              />
            )}
          </form>
        </div> */}
      </div>
      <br />

      <div className="h-20 w-full border-t-2 border-slate-800">
        <div className="w-full pt-10 flex justify-evenly align-middle sm:flex-nowrap flex-wrap sm:px-0 px-3 sm:gap-0 gap-6">
          <div className="flex gap-6 uppercase tracking-wider">
            <Link className="text-xs sm:text-md text-center" to={"/About"}>
              About Us
            </Link>
            <Link className="text-xs sm:text-md text-center" to={"/Privacy"}>
              Privacy Policy
            </Link>
            <Link className="text-xs sm:text-md text-center" to={"/Support"}>
              Support
            </Link>
          </div>
          <div className="flex gap-6">
            <Link to={"https://www.instagram.com/musicbook.co.in"}>
              <FaInstagram className="text-2xl" />
            </Link>
            <Link
              to={"https://www.facebook.com/profile.php?id=100091842444276"}
            >
              <FaFacebook className="text-2xl" />
            </Link>
            <Link to={"https://www.youtube.com/@musicbook7881"}>
              <FaYoutube className="text-2xl" />
            </Link>
          </div>
        </div>
        <div className="flex justify-center align-middle  pt-16 pb-6">
          <h5 className="text-center text-slate-400 text-sm">
            Design & Developed By
          </h5>
          <div className="pl-2 mb-1 font-semibold text-sm">
            <Link to={"https://www.uxmdesign.online/"}>UXM Co</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
