import React, { useEffect, useState } from "react";
import { AiFillMessage } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { ColorRing } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../Redux/slices/TempSlice";
import axios from "../components/Hooks/axios";
import styles from "../css/FollowList.module.scss";

const FollowList = ({ followType, setFollowShow, userId }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (followType === "Followers") {
			axios
				.get(`/post/get-follower-list/${userId}`)
				.then(({ data }) => {
					if (data.status) {
						setList(data);
						setLoading(false);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else if (followType === "Following") {
			axios
			.get(`/post/get-following-list/${userId}`)
			.then(({ data }) => {
				if (data.status) {
						setLoading(false);
						setList(data);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, []);

	return (
		<div className={styles.FollowListContainer} onClick={() => setFollowShow(false)}>
			<div className={styles.FollowList} onClick={(e) => e.stopPropagation()}>
				<button onClick={() => setFollowShow(false)}>
					<RxCross2 />
				</button>

				<h3>{followType === "Following" ? "Followers" : "Following"}</h3>

				<div className={styles.list}>
					{loading ? (
						<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<ColorRing
								visible={true}
								height="100"
								width="100"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : followType === "Following" ? (
						list?.data?.length === 0 ? (
							"No Followers"
						) : (
							list?.data?.map((data, index) => {
								if (data.FollowingData.length !== 0)
									return (
										<div key={index}>
											<div className={styles.pic}>
												<img src={data?.FollowingData[0]?.pic} alt="" />
											</div>
											<p
												className={styles.name}
												onClick={() => navigate(`/profile/${data?.FollowingData[0]?._id}`)}>
												{data?.FollowingData[0]?.full_name}
											</p>
											<p className={styles.star}>{data?.FollowingData[0]?.rating} Star</p>
											{console.log(data)}
											<AiFillMessage
												className={styles.Icon}
												onClick={() =>
													dispatch(
														setMessage({
															open: true,
															id: data?.FollowingData[0]?._id,
														})
													)
												}
											/>
										</div>
									);
							})
						)
					) : list?.data?.length === 0 ? (
						"No Following"
					) : (
						list?.data?.map((data, index) => {
							if (data.FollowerData.length !== 0)
								return (
									<div key={index}>
										<div className={styles.pic}>
											<img src={data?.FollowerData[0]?.pic} alt="" />
										</div>
										<p
											className={styles.name}
											onClick={() => navigate(`/profile/${data?.FollowerData[0]?._id}`)}>
											{data?.FollowerData[0]?.full_name}
										</p>
										<p className={styles.star}>{data?.FollowerData[0]?.rating} Star</p>
										<AiFillMessage
											className={styles.Icon}
											onClick={() =>
												dispatch(
													setMessage({
														open: true,
														id: data?.FollowerData[0]?._id,
													})
												)
											}
										/>
									</div>
								);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export default FollowList;
