import { Link } from "react-router-dom";
import React from "react";
import Style from "../style/nav.module.css";
import { Typography } from "@mui/joy";
// import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button} from "@nextui-org/react";

function Navbar({ gotoDownload, navigate }) {
  return (
    <header className={Style.header}>
      <div className="w-full flex justify-between pl-3 pr-3 sm:pl-6 sm:pl-6 align-middle">
        <Link to={"/"}>
          {/* <img
              src={"/logo-new.png"}
              style={{ width: 140 }}
              width={140}
              height={30}
              alt="Music Book Logo "
            ></img> */}
          <div
            // onClick={() => window.open("/vodcast", "_self")}
            style={{
              height: "100%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img
              className={Style.logonew}
              src={"logoM.png"}
              alt=""
              style={{}}
            />
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                level="h4"
                fontSize={{ xs: 12, sm: 14, md: 16 }}
                sx={{ color: "#fff" }}
              >
                Music book
              </Typography>
              <Typography
                style={{
                  color: "#fff",
                }}
                fontSize={{ xs: 8, sm: 12 }}
              >
                Live every beat of your life
              </Typography>
            </div>
          </div>
        </Link>
        {/* <div className='navitem'>
                <ul className={Style.NavMenu}>
                    <li>
                        <Link to={'/'}>About Us</Link>
                    </li>
                    <li>
                        <Link to={'/'}>How It Works ?</Link>
                    </li>
                    <li>
                        <Link to={'/'}>Contact Us</Link>
                    </li>
                </ul>
            </div> */}
        <div onClick={gotoDownload} className={Style.NavButton}>
          <Typography fontSize={{ xs: 12, sm: 12, md: 12 }} textColor={"#fff"}>
            Download Now
          </Typography>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
