import React from "react";
import Form from "react-bootstrap/Form";
import { canvasState, scaleState } from "../atoms";
import { useRecoilState } from "recoil";
import cloneDeep from "lodash/cloneDeep";

function Settings(props) {
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const [scale, setScale] = useRecoilState(scaleState);

  const videoFormats = [
    { platform: "TikTok", width: 1080, height: 1920 },
    { platform: "YouTube", width: 1920, height: 1080 },
    { platform: "YouTube Shorts", width: 1080, height: 1920 },
    { platform: "Instagram Reels", width: 1080, height: 1920 },
    // ... add more as needed
  ];

  const handleChangeFormat = (format) => {
    let selectedFormat = videoFormats.find((f) => f.platform === format);
    if (selectedFormat) {
      let c = cloneDeep(canvas);
      c.width = selectedFormat.width;
      c.height = selectedFormat.height;
      setCanvas(c);
    }
  };

  const changeWidth = (e) => {
    let c = cloneDeep(canvas);
    c.width = e;
    setCanvas(c);
  };

  const changeHeight = (e) => {
    let c = cloneDeep(canvas);
    c.height = e;
    setCanvas(c);
  };

  const controlStyle = {
    margin: "10px 0",
    padding: "8px 12px",
    border: "1px solid #e1e1e1",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.05)",
  };

  return (
    <div>
      <Form.Control
        as="select"
        style={controlStyle}
        onChange={(e) => handleChangeFormat(e.target.value)}
      >
        <option value="">Select Platform</option>
        {videoFormats.map((format, index) => (
          <option key={index} value={format.platform}>
            {format.platform} ({format.width}x{format.height})
          </option>
        ))}
      </Form.Control>
      <Form.Control
        value={canvas.width}
        type="number"
        style={controlStyle}
        onChange={(e) => changeWidth(e.target.value)}
      />
      <Form.Control
        value={canvas.height}
        type="number"
        style={controlStyle}
        onChange={(e) => changeHeight(e.target.value)}
      />
    </div>
  );
}

export default Settings;
