import { Typography } from "@mui/joy";
import React from "react";
import { Link } from "react-router-dom";
import Style from "../style/nav.module.css";

const LogoComponent = () => {
  return (
    <Link to={"/"} style={{ alignSelf: "baseline" }}>
      {/* <img
              src={"/logo-new.png"}
              style={{ width: 140 }}
              width={140}
              height={30}
              alt="Music Book Logo "
            ></img> */}
      <div
        // onClick={() => window.open("/vodcast", "_self")}
        style={{
          height: "100%",
          cursor: "pointer",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",

          gap: 10,
        }}
      >
        <img className={Style.logonew} src={"logoM.png"} alt="" style={{}} />
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography
            level="h4"
            fontSize={{ xs: 12, sm: 14, md: 16 }}
            sx={{ color: "#fff" }}
          >
            Music book
          </Typography>
          <Typography
            style={{
              color: "#fff",
            }}
            fontSize={{ xs: 8, sm: 12 }}
          >
            Live every beat of your life
          </Typography>
        </div>
      </div>
    </Link>
  );
};

export default LogoComponent;
