import React, { useEffect, useState, useRef, createContext } from "react";

import { RecoilRoot, useRecoilState } from "recoil";
import VideoEditorWrapper from "./components/VideoEditorWrapper";
import { AspectRatio } from "@mui/joy";

function CodinnoVideoEditor({ videoFile }) {
  return (
    <RecoilRoot>
      <VideoEditorWrapper videoFile={videoFile} />
    </RecoilRoot>
  );
}

export default CodinnoVideoEditor;
