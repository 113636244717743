import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  CssVarsProvider,
  Divider,
  Dropdown,
  Grid,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos, getVideosById } from "../../services/pexels";
import {
  RiArrowLeftLine,
  RiCloseCircleLine,
  RiDeleteBin3Fill,
  RiDraftLine,
  RiEdit2Fill,
  RiHome3Line,
  RiHomeLine,
  RiShareForwardLine,
} from "react-icons/ri";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import VodcastPlayer from "../components/VodcastPlayer/index.js";
import VideoToolsRow from "./VideoToolsRow";
import ProfileCard from "../components/ProfileCard";
import ReportVideoDialog from "../ReportDialogs/ReportVideoDialog";
import { getData } from "../../utils/httpMethods";
import CommentsBox from "../components/CommentsBox";
import axios from "axios";
import Swal from "sweetalert2";
import EditDialog from "./EditDialog";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RWebShare } from "react-web-share";
import CommonViewLayout from "../../layouts/CommonViewLayout";
import { logout } from "../../Redux/slices/AuthSlice";

function VodcastOpenViewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { vodcast_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingOthers, setLoadingOthers] = useState(true);
  const [loadingOthersMore, setLoadingOthersMore] = useState(false);
  const [totalCount, setTotalCount] = useState(-1);
  const [data, setData] = useState(null);
  const [otherVideos, setOtherVideos] = useState([]);
  const [countData, setCountData] = useState(null);
  const [isShownMore, setIsShownMore] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [postRating, setPostRating] = useState(data?.rating);
  const [profileRating, setProfileRating] = useState(data?.created_by?.rating);
  const [followState, setFollowState] = useState(data?.is_follow);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [page, setPage] = useState(1);

  const [loadingMore, setLoadingMore] = useState(false);

  const isOwn =
    user?.user_type === "professional" &&
    localStorage.getItem("user_id") === data?.created_by?._id;

  useEffect(() => {
    setPostRating(data?.rating);
    setProfileRating(data?.created_by?.rating);
    setFollowState(data?.created_by?.is_followed);
  }, [data]);

  const loadMore = () => {
    !loading && !loadingOthersMore && !loadingOthers && setPage((p) => p + 1);
  };

  const fetchComments = useCallback(() => {
    setCommentLoading(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast-comment/${vodcast_id}?offset=0`,
      "GET"
    )
      .then(({ data, status }) => {
        if (status === true) {
          setCommentLoading(false);
          setComments(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [vodcast_id]);

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    if (vodcast_id) {
      setLoadingOthers(true);
      setLoading(true);
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast/view/${vodcast_id}`,
        "GET"
      ).then((res) => {
        setLoading(false);
        if (res.status == true) {
          if (res.data?.data?.length > 0) {
            setData(res.data?.data?.[0]);
          } else {
            setDeleted(true);
          }
          // setCountData(res.data?.count);
        } else {
        }
      });
    }
  }, [vodcast_id]);

  const fetchData = useCallback(() => {
    if (data?.created_by) {
      page === 1 && setLoadingOthers(true);
      page > 1 && setLoadingOthersMore(true);
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast-by-the-user/${data?.created_by?._id
        }?offset=${(page - 1) * 10}`,
        "GET"
      ).then((res) => {
        if (res.status == true) {
          const videos = res?.data?.filter((i) => i._id !== vodcast_id);

          setOtherVideos((d) => ({ ...d, [page]: videos }));
          setTotalCount(res.total_count);
        } else {
          console.log("incorrect");
        }

        setLoadingOthers(false);
        setLoadingOthersMore(true);
      });
    }
  }, [data, page]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const handleApp = () => {
    // Detect user agent to check for Android or iOS
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // If the user is on Android, redirect to Google Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.musicbook.app&pcampaignid=web_share";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // If the user is on iOS, redirect to Apple App Store
      window.location.href =
        "https://apps.apple.com/in/app/music-book/id1665164896";
    } else {
      // Default action (optional)
      alert(
        "Unsupported platform. Please open the store link on an Android or iOS device."
      );
    }
  };

  const handelRegister = () => {
    navigate("/login");
  };

  return (
    <CommonViewLayout>
      {showReportDialog && (
        <ReportVideoDialog
          user={data?.created_by}
          onClose={() => setShowReportDialog(false)}
          vodcastId={data?._id}
        />
      )}

      <InfiniteScroll
        // dataLength={
        //   (videosData && Object.values(videosData)?.flat()?.length) || 0
        // }
        dataLength={Object.values(otherVideos)?.flat().length}
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {!deleted ? (
          <PaddingContainer>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid xs={12} md={7}>
                <div>
                  {loading ? (
                    <div>Loading...</div> // Shimmer or loading state
                  ) : (
                    <VodcastPlayer shimmer={loading} data={data} />
                  )}
                  {!user && (
                    <div>
                      You are not logged in. Some features might be unavailable.
                    </div>
                  )}
                </div>
                {/* <VodcastPlayer shimmer={loading} data={data} /> */}
                <div className="md:py-4 py-6 px-3 border-t-2 border-[#161616] mt-6">
                  <p className="opacity-75 uppercase text-sm tracking-wider">
                    Title
                  </p>
                  <h2 className="text-2xl">{data?.title}</h2>
                </div>
                <br />
                <div className="flex flex-col md:flex-row justify-center gap-4 items-center">
                  <button
                    onClick={handleApp}
                    className="w-[280px] h-[58px] bg-indigo-800 rounded-full text-white md:hidden block"
                  >
                    Open App
                  </button>
                  <button
                    onClick={handelRegister}
                    className="w-[280px] h-[58px] bg-indigo-800 rounded-full text-white md:block hidden"
                  >
                    Register
                  </button>
                  <button className="w-[280px] h-[58px] bg-gray-800 rounded-full flex justify-center items-center gap-3 text-white">
                    Share {""}
                    <RWebShare
                      data={{
                        text: "Musicbook",
                        url:
                          "https://musicbook.co.in/vodcast/view/" + data?._id,
                        title: "Musicbook",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <IconButton
                        variant="solid"
                        sx={{
                          minWidth: 30,
                          minHeight: 30,
                          width: 30,
                          aspectRatio: 1,
                          borderRadius: 15,
                          backgroundColor: "#F5F5F512",
                        }}
                      >
                        <RiShareForwardLine size={14} />
                      </IconButton>
                    </RWebShare>
                  </button>
                </div>
              </Grid>
            </Grid>
          </PaddingContainer>
        ) : (
          <PaddingContainer></PaddingContainer>
        )}
      </InfiniteScroll>
    </CommonViewLayout>
  );
}

export default VodcastOpenViewPage;
