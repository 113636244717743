import React from 'react'

const DocumentFolderIcon = () => {
  return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.3008 8.53125H10.9688C10.4839 8.53125 10.0189 8.33864 9.67607 7.9958C9.33323 7.65296 9.14062 7.18797 9.14062 6.70312V1.37109C9.14062 1.33069 9.12457 1.29194 9.096 1.26337C9.06743 1.2348 9.02869 1.21875 8.98828 1.21875H5.48438C4.83791 1.21875 4.21792 1.47556 3.7608 1.93268C3.30368 2.3898 3.04688 3.00978 3.04688 3.65625V15.8438C3.04688 16.4902 3.30368 17.1102 3.7608 17.5673C4.21792 18.0244 4.83791 18.2812 5.48438 18.2812H14.0156C14.6621 18.2812 15.2821 18.0244 15.7392 17.5673C16.1963 17.1102 16.4531 16.4902 16.4531 15.8438V8.68359C16.4531 8.64319 16.4371 8.60444 16.4085 8.57587C16.3799 8.5473 16.3412 8.53125 16.3008 8.53125Z"
				fill="#7E7E7E"
			/>
			<path
				d="M15.9664 7.18262L10.4892 1.70549C10.4786 1.6949 10.4651 1.68769 10.4503 1.68478C10.4356 1.68187 10.4203 1.68338 10.4064 1.68911C10.3926 1.69485 10.3807 1.70457 10.3723 1.71704C10.3639 1.7295 10.3594 1.74417 10.3594 1.75919V6.70312C10.3594 6.86474 10.4236 7.01974 10.5379 7.13402C10.6521 7.2483 10.8071 7.3125 10.9688 7.3125H15.9127C15.9277 7.31243 15.9424 7.30793 15.9548 7.29956C15.9673 7.29118 15.977 7.27931 15.9828 7.26543C15.9885 7.25155 15.99 7.23629 15.9871 7.22155C15.9842 7.20682 15.977 7.19328 15.9664 7.18262Z"
				fill="#7E7E7E"
			/>
		</svg>
  );
}

export default DocumentFolderIcon
