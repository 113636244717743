import {
  Box,
  Button,
  Input,
  ModalDialog,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import React, { useEffect } from "react";
import constants from "../../configs/constants";
import Swal from "sweetalert2";
import { postData } from "../../utils/httpMethods";

function EditDialog({ onClose, data, onSuccess }) {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [credits, setCredits] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  useEffect(() => {
    setTitle(data?.title || "");
    setDescription(data?.captions || "");
    setCredits(data?.credits || "");
  }, [data]);

  const saveDetails = () => {
    if (!title || !description) {
      return Swal.fire({
        title: "Required field cannot be blank",
        color: "#fff",
        background: "#232323",
      });
    }
    setSaving(true);
    let FD = new FormData();
    FD.append("title", title);
    FD.append("caption", description);

    FD.append("user_id", localStorage.getItem("user_id"));

    FD.append("credits", credits);
    postData(`${process.env.REACT_APP_BASE_URL}/vodcast/edit/${data?._id}`, FD)
      .then((res) => {
        setSaving(false);
        if (res.status == true) {
          onClose();
          onSuccess(res?.data);
        } else {
          alert("FAILED TO UPLOAD");
        }
      })
      .catch((err) => console.log("UPLOAD ERROR", err));
  };

  return (
    <div
      style={{
        zIndex: 9999,
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: constants.colors.secondary,
          opacity: 0.7,
        }}
      />
      <ModalDialog
        slotProps={{
          root: {
            style: {
              top: "35%",
            },
          },
        }}
        sx={{
          maxWidth: { xs: 400, sm: 500 },
          minWidth: { xs: "80%", sm: 400, md: 500, lg: 500 },
          minHeight: 300,
          borderRadius: "xs",
          p: 3,
          boxShadow: "xs",
          display: "flex",
          flexDirection: "column",
          background: "#222020",
          outlineWidth: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Stack sx={{ flex: 1, width: "80%", gap: 0.5, mt: 2 }}>
            <Typography level="h4" fontSize="sm" sx={{ mb: 1 }}>
              Edit Details
            </Typography>

            <Input
              size="sm"
              placeholder={`Title *`}
              color="neutral"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Textarea
              size="sm"
              color="neutral"
              placeholder="Description *"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              slotProps={{
                input: {
                  multiple: true,
                  style: {
                    minHeight: 60,
                    verticalAlign: "top",
                    whiteSpace: "pre-wrap",
                  },
                },
              }}
            />

            <Input
              value={credits}
              onChange={(e) => setCredits(e.target.value)}
              size="sm"
              placeholder="Music Credits"
              color="neutral"
              sx={{ mt: 2 }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 5,
              gap: 2,
            }}
          >
            {!saving && (
              <Button
                onClick={onClose}
                size="sm"
                color="neutral"
                sx={{
                  borderRadius: 50,
                  px: 3,
                  fontSize: { xs: 14 },
                  ":hover": {
                    background: "#121212",
                  },
                }}
              >
                CANCEL
              </Button>
            )}
            <Button
              loading={saving}
              onClick={saveDetails}
              size="sm"
              color="primary"
              sx={{
                borderRadius: 50,
                px: 5,
                fontSize: { xs: 14 },
                background: constants.colors.primary,
              }}
            >
              UPDATE
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </div>
  );
}

export default EditDialog;
