import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useProfessionalValidate = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem("auth_token")) {
			return navigate("/");
		}
		if (localStorage.getItem("user_type") != "professional") {
			return navigate("/vodcast");
		}
	}, []);
};

export default useProfessionalValidate;
