import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  CssVarsProvider,
  Grid,
  IconButton,
  Input,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos } from "../../services/pexels";
import {
  RiArrowLeftLine,
  RiBookmarkLine,
  RiUploadLine,
  RiVideoLine,
} from "react-icons/ri";

import VideoThumbnailCard from "../components/VideoThumbnailCard";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams } from "react-router-dom";
import { humanizeString } from "../../utils/humanizeString";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../utils/httpMethods";

function MyVodcastPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [videosData, setVideosData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [totalCouunt, setTotalCount] = useState(-1);

  const [showUpload, setShowUpload] = useState(false);

  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = () => {
    setPage((p) => p + 1);
  };

  const fetchData = useCallback(() => {
    page == 1 && setData({});
    page == 1 && setLoading(true);
    page > 1 && setLoadingMore(true);

    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/get-my-vodcast?offset=${(page - 1) * 10
      }`,
      "GET"
    ).then((res) => {
      setLoading(false);
      setLoadingMore(false);
      if (res.status == true) {
        setData((d) => ({ ...d, [page]: res.data }));
        setTotalCount(res.total_count);
        if (res.total_count === 0) {
          setData({});
          setTotalCount(0);
          setPage(1);
        }
      } else {
        console.log("incorrect");
      }
    });
  }, [page]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonLayout
      showUpload={showUpload}
      setShowUpload={setShowUpload}
      onUploadedCallback={fetchData}
    >
      <InfiniteScroll
        dataLength={
          (videosData && Object.values(videosData)?.flat()?.length) || 0
        }
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <Grid
          container
          lg={12}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Grid xs={6} sm={6} md={6} lg={8}>
            <Stack direction="row">
              <IconButton
                onClick={() => navigate("/vodcast")}
                sx={{ ":hover": { background: "transparent" } }}
              >
                <RiArrowLeftLine size={30} />
              </IconButton>
              <Breadcrumbs>
                {["Home"].map((item) => (
                  <Link
                    key={item}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}
                    color="neutral"
                    href="/vodcast"
                  >
                    {item}
                  </Link>
                ))}
                <Typography fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}>
                  My Vodcast
                </Typography>
              </Breadcrumbs>
            </Stack>
            <BigTitle shimmer={loading}>MY VODCAST</BigTitle>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 1,
              mb: 5,
            }}
          >
            <ActionButton
              showIconForMobile
              onClick={() => navigate("/vodcast/manage/draft")}
              shimmer={loading}
              title="Draft Video"
              sx={{ backgroundColor: "#303030", color: "#A1A1A1" }}
              startDecorator={<RiBookmarkLine size={16} />}
            />
            <ActionButton
              showIconForMobile
              shimmer={loading}
              onClick={() => setShowUpload(true)}
              title="Upload Vodcast"
              sx={{
                backgroundColor: constants.colors.primary,
                color: "#FFFFFF",
              }}
              startDecorator={<RiUploadLine size={16} />}
            />
          </Grid>
        </Grid>

        <PaddingContainer>
          <Grid container spacing={2}>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard shimmer={loading} data={item} key={index} />
              ))}
            {!loading &&
              data &&
              Object.values(data)
                ?.flat()
                ?.map((item, index) => (
                  <VideoListCard data={item} key={index} />
                ))}
          </Grid>
        </PaddingContainer>
        {totalCouunt == 0 && (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              py: 4,
            }}
          >
            <RiVideoLine size={80} style={{ opacity: 0.3 }} />
            <Typography level="title-lg" sx={{ color: "#FFFFFF4A", mt: 5 }}>
              You have 0 vodcast. Upload and manage your vodcast here
            </Typography>
          </Container>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
          }}
        >
          {loadingMore ? (
            <CircularProgress variant="soft" />
          ) : (
            Object.values(data).flat().length < totalCouunt && (
              <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                END OF CONTENT
              </Typography>
            )
          )}
        </Container>
      </InfiniteScroll>
    </CommonLayout>
  );
}

export default MyVodcastPage;
