import React from "react";
import { Link } from "react-router-dom";
import Style from "../style/hero.module.css";
import { Typography } from "@mui/joy";

function HeroSection({ gotoDownload, navigate }) {
  return (
    <div className={Style.hero}>
      <div className={Style.HeroContaner}>
        <div
          className="flex flex-row justify-between px-5 sm:flex-nowrap sm:px-20"
          style={{ width: "100%" }}
        >
          <div className={Style.heroContent}>
            <h1>
              Discover the new way <br /> to enjoy music{" "}
            </h1>
            <p className="text-gray-300 sm:text-xl text-sm w-fit pt-6 sm:text-left text-left">
              An oasis where the sweet melodies of music weave seamlessly with
              the lingering echoes of poetry, creating a symphony of artistry
              and emotion
            </p>
            {/* <br /> */}
            <div className="md:hidden  flex flex-row sm:flex-row flex-wrap sm:flex-nowrap sm:justify-items-start justify-center align-middle sm:gap-10 gap-1">
              <Link target="_self" to={gotoDownload} rel="noopener noreferrer">
                <img
                  onClick={gotoDownload}
                  className="sm:w-60 w-80 "
                  src={"/personal.png"}
                  width={240}
                  style={{ width: 240 }}
                  alt="Go To Personal"
                ></img>
              </Link>
              <Link target="_self" to={gotoDownload} rel="noopener noreferrer">
                <img
                  onClick={gotoDownload}
                  className="sm:w-60 w-80"
                  src={"/professional.png"}
                  width={240}
                  style={{ width: 240 }}
                  alt="Go To Professional"
                ></img>
              </Link>
            </div>
            {/* mobile responsive  */}
            <div className="md:flex hidden flex-row sm:flex-row flex-wrap sm:flex-nowrap sm:justify-items-start justify-center align-middle sm:gap-10 gap-1">
              <Link
                target="_self"
                to="/login?type=user"
                rel="noopener noreferrer"
              >
                <img
                  className="sm:w-60 w-80 "
                  src={"/personal.png"}
                  width={240}
                  style={{ width: 240 }}
                  alt="Go To Personal"
                ></img>
              </Link>
              <Link
                target="_self"
                to="/login?type=professional"
                rel="noopener noreferrer"
              >
                <img
                  className="sm:w-60 w-80"
                  src={"/professional.png"}
                  width={240}
                  style={{ width: 240 }}
                  alt="Go To Professional"
                ></img>
              </Link>
            </div>
          </div>
          <div className={Style.heroImage}>
            <img
              src={"/mobileapp-10.png"}
              width={1200}
              height={40}
              style={{ width: 1200 }}
              alt="mobile Screen"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
