import { Button, Checkbox, Grid, Input, Stack, Typography } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import LogoComponent from "../AltLandingPage/components/LogoComponent";
import { GrGoogle } from "react-icons/gr";
import LoadingModal from "../components/LoadingModal";
import { FcGoogle } from "react-icons/fc";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveUser } from "../Redux/slices/AuthSlice";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from "@leecheuk/react-google-login";


const LoginPage = () => {
  useValidateWithoutLogin();

  const gLoginRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [Pass, setPass] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");

  async function postdata(url = "", data = {}, methods = "") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: methods,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  function loginHandler() {
    if (email == "" || Pass == "")
      return toast.warn("Please Enter Correct Credentials");

    setLoader(true);

    let data = {
      email: email,
      password: Pass,
      user_type: type,
    };

    postdata(`${process.env.REACT_APP_BASE_URL}/auth/login`, data, "POST").then(
      (data) => {
        console.log(data);
        if (data.status == true) {
          setLoader(false);
          if (data.data.is_verified != undefined && data.data.is_verified === 1) {
            dispatch(saveUser({ ...data, rememberMe }));
            window.open("/vodcast", "_self");
          }
          localStorage.setItem('login_email', email);
          window.open("/verification", "_self");
        } else {
          setLoader(false);
          toast.error("incorrect credentials");
        }
      }
    );
  }

  const [eyeV, setEyeV] = useState("password");
  function visible() {
    if (eyeV == "password") setEyeV("text");
    else setEyeV("password");
  }

  function handelCallBackResponse(response) {
    let userObject = jwt_decode(response.credential);
    let data = {
      email: userObject.email,
      password: userObject.jti,
      ...(userObject.family_name
        ? { full_name: userObject.given_name + " " + userObject.family_name }
        : { full_name: userObject.given_name }),
      image: userObject.picture,
      user_type: type,
    };
    postdata(
      `${process.env.REACT_APP_BASE_URL}/auth/google-register`,
      data,
      "POST"
    ).then((data) => {
      if (data.status == true) {

        dispatch(saveUser({ ...data, rememberMe: true }));
        console.log(data);
        // navigate("../professional-home", { replace: true });
        window.open("/vodcast", "_self");
      } else {
        console.log(data);
        toast.warn("incorrect credentials");
      }
    });
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handelCallBackResponse,
      });

      window?.google?.accounts?.id?.renderButton(
        document.getElementById("signInDiv"),
        {
          theme: "dark",
          size: "large",
          width: gLoginRef.current.offsetWidth,
        }
      );
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Grid
      container
      sx={{ background: "#09041C", height: "100%", width: "100%" }}
    >
      <LoadingModal open={loader} />
      <Grid md={4} xs={0} sm={0} lg={6} sx={{ height: "100%" }}>
        <img
          src="/auth_image.png"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        container
        md={8}
        lg={6}
        sm={12}
        xs={12}
        sx={{
          height: "100%",
          px: { xs: 2, sm: 4, md: 8 },
          py: { xs: 2, sm: 3, md: 3 },
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <Grid xs={12} md={10}>
          <Stack sx={{ height: "100%" }}>
            <LogoComponent />
            <Stack
              sx={{ flex: 1, justifyContent: "center" }}
              spacing={{ xs: 4, md: 4 }}
            >
              <Stack>
                <Typography
                  textColor={"#fff"}
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: { xs: "16px", md: "20px" },
                  }}
                >
                  LOGIN TO YOUR ACCOUNT
                </Typography>
                <Typography
                  textColor={"#fff"}
                  sx={{
                    py: { xs: 2, md: 3 },
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    fontSize: { xs: "30px", md: "40px" },
                    lineHeight: { xs: "30px", md: "40px" },
                  }}
                >
                  Welcome back, to
                  <br />
                  Music book
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  size="lg"
                  type="email"
                  placeholder="Enter your email"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />
                <Input
                  size="lg"
                  type={eyeV}
                  endDecorator={
                    eyeV === "password" ? (
                      <RiEyeOffLine size={18} onClick={visible} />
                    ) : (
                      <RiEyeLine size={18} onClick={visible} />
                    )
                  }
                  onChange={(e) => setPass(e.target.value)}
                  value={Pass}
                  placeholder="Password"
                  variant="outlined"
                  slotProps={{
                    input: {
                      sx: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #09041C inset",
                          WebkitTextFillColor: "#fff",
                        },
                      },
                    },
                  }}
                  sx={{
                    background: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                />
                <Stack py={1} direction="row" justifyContent="space-between">
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    label="Remember Me"
                    variant="outlined"
                    slotProps={{
                      label: {
                        sx: {
                          fontFamily: "Poppins",
                          color: "#fff",
                        },
                      },
                      checkbox: {
                        sx: {
                          background: "transparent",
                          borderColor: "#68A1F5",
                        },
                      },
                    }}
                  />
                  {<Button
                    onClick={() => {
                      navigate("/forget-password");
                    }}
                    /* slotProps={{
                      root: {
                        sx: {
                          padding: 0,
                          margin: 0,
                          minHeight: 0,
                          color: "#64676A",
                          ":hover": {
                            background: "transparent",
                            color: "#ffffff90",
                          },
                        },
                      },
                    }} */
                    sx={{
                      padding: 0,
                      margin: 0,
                      minHeight: 0,
                      color: "#64676A",
                      ":hover": {
                        background: "transparent",
                        color: "#ffffff90",
                      },
                      "&.MuiButton-root": {  // This targets the root class of the Button component
                        // Additional styles for the root class if needed
                      },
                    }}
                    variant="plain"
                  >
                    Forgot Password?
                  </Button>}
                </Stack>
                <Stack spacing={1.5}>
                  <Button
                    onClick={loginHandler}
                    color="neutral"
                    sx={{
                      background: "#471CF1",
                      borderRadius: "10px",
                      fontSize: { xs: 14 },
                    }}
                    size="lg"
                  >
                    SIGN IN TO ACCOUNT
                  </Button>
                  <Typography
                    color="neutral"
                    sx={{
                      fontSize: 12,
                      textAlign: "center",
                      lineHeight: "12px",
                    }}
                  >
                    OR
                  </Typography>
                  <Button
                    onClick={() => {
                      document.getElementById("signInDiv")?.click();
                    }}
                    slotProps={{
                      root: {
                        ref: gLoginRef,
                      },
                    }}
                    startDecorator={<FcGoogle />}
                    color="neutral"
                    sx={{
                      background: "#160B42",
                      borderRadius: "10px",
                      fontSize: { xs: 14 },
                      position: "relative",
                    }}
                    size="lg"
                  >
                    <div
                      id="signInDiv"
                      style={{
                        // width: gLoginRef.current?.offsetWidth  || "100%",
                        position: "absolute",
                        opacity: 0,
                      }}
                    />
                    SIGN IN WITH GOOGLE
                  </Button>
                </Stack>
              </Stack>
              <Typography
                color="neutral"
                sx={{ fontSize: { xs: 14, md: 12 }, textAlign: "center" }}
              >
                New to Music book ?
                <Button
                  onClick={() => {
                    navigate("/register");
                  }}
                  /* slotProps={{
                    root: {
                      sx: {
                        padding: 0,
                        paddingLeft: "5px",
                        margin: 0,
                        minHeight: 0,
                        color: "#FFFFFFdd",
                        ":hover": {
                          background: "transparent",
                          color: "#ffffff90",
                        },
                      },
                    },
                  }} */
                  sx={{
                    padding: 0,
                    paddingLeft: "5px",
                    margin: 0,
                    minHeight: 0,
                    color: "#FFFFFFdd",
                    ":hover": {
                      background: "transparent",
                      color: "#ffffff90",
                    },
                    "&.MuiButton-root": {  // This targets the root class of the Button component
                      // Additional styles for the root class if needed
                    },
                  }}
                  variant="plain"
                >
                  Create Account
                </Button>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default LoginPage;
