import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCreateSongMutation } from "../../Redux/api/albumApi";
import { setCreated } from "../../Redux/slices/AlbumSlice";
import frame from "../../images/Frame.png";
import arrowIcon from "../../images/arrowIcon.png";
import uploadIcon from "../../images/uploadIcon.png";
import { createNotification } from "../../components/Hooks/CreateNotification";

const AddSong = ({ setShowAddSongPopup, setShowAlbumPopup, createdAlbumId }) => {
	const { userId } = useSelector((state) => state.auth);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [coverPic, setCoverPic] = useState("");
	const [file, setFile] = useState(null);
	const [disableBtn, setDisableBtn] = useState(false);
	const [uploadStatus, setUploadStatus] = useState(0);
	const [videoAudioFile, setVideoAudioFile] = useState(null);
	const coverPicRef = useRef();
	const videoAudioRef = useRef();

	const [createSong] = useCreateSongMutation();
	const { uploadProgress } = useSelector((state) => state.albumSlice);
	const dispatch = useDispatch();

	const handelAddSong = async () => {
		if (title === "" || description === "" || file === null || videoAudioFile === null)
			return toast.warn("Please fill all the field");
		setDisableBtn(true);

		dispatch(setCreated(false));

		const { data } = await createSong({
			album_id: createdAlbumId,
			title: title,
			file: file,
			videoAudioFile: videoAudioFile,
			description: description,
		});
		if (data.status === true) {
			createNotification(userId, "Album Song", `You added ${title} song`);
			toast.success("Song added successfully");
			setShowAddSongPopup(false);
			setShowAlbumPopup(true);
			setDisableBtn(false);
		} else {
			toast.error("Something went wrong !");
		}
	};

	const handelVideoAudio = (e) => {
		const file = e.target.files[0];
		if (file) {
			setVideoAudioFile(file);
			e.target.value = null;
		}
	};

	const handelCoverPic = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
			setCoverPic(URL.createObjectURL(file));
			e.target.value = null;
		}
	};

	return (
		<div className="albumPopup">
			<button className="closeAlbumBtn" onClick={() => setShowAddSongPopup(false)}>
				<img src={arrowIcon} alt="" />
			</button>
			<div className="innerAlbumPopup">
				<h1 className="albumHeading">Add Song</h1>
				<div className="albumCoverPictureDiv" onClick={() => coverPicRef.current.click()}>
					{coverPic ? (
						<img src={coverPic} alt="" className="coverPic" />
					) : (
						<>
							<img src={frame} alt="" />
							<h3>Upload Song's Thumbnail</h3>
						</>
					)}
					<input type="file" accept="image/*" ref={coverPicRef} style={{ display: "none" }} onChange={handelCoverPic} />
				</div>

				<div className="inputGroup">
					<label htmlFor="Title">Song Title</label>
					<input
						type="text"
						id="Title"
						placeholder="Place enter a title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>

				<div className="inputGroup">
					<label htmlFor="Info">Song Description</label>
					<textarea
						placeholder="Place enter info"
						id="Info"
						value={description}
						onChange={(e) => setDescription(e.target.value)}></textarea>
				</div>

				<div className="albumVideoAudio" onClick={() => videoAudioRef.current.click()}>
					<img src={uploadIcon} alt="" />
					<h3>{videoAudioFile ? videoAudioFile.name : "Upload Audio File"}</h3>
					<input
						type="file"
						accept=".mp3,audio/*"
						ref={videoAudioRef}
						style={{ display: "none" }}
						onChange={handelVideoAudio}
					/>
				</div>

				<div className="btnContainer">
					<button type="button" className="createAlbumBtn" onClick={handelAddSong} disabled={disableBtn}>
						<span style={{ width: `${uploadProgress}%` }}></span>
						<p>{uploadProgress === 0 ? "Add Song" : uploadProgress + "%"}</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddSong;
