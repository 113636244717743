import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useUserValidate = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem("auth_token")) {
			return navigate("/");
		}
		// if (localStorage.getItem("user_type") != "user") {
		// 	return navigate("/vodcast");
		// }
	}, []);
};

export default useUserValidate;
