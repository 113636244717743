import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

import React from "react";

export const videoListState = atom({
  key: "videoListState",
  default: [],
});

export const textListState = atom({
  key: "textListState",
  default: [],
  // default: [
  //   {
  //     id: 0,
  //     group: "audio",
  //     tag: null,
  //     text: " new text ",
  //     x: 0,
  //     y: 0,
  //     startTime: 0.0,
  //     endTime: 2000.0,
  //     posX: 0,
  //     color: "#F8E71C",
  //     backgroundColor: "rgba(0,0,0,0.7)",
  //     size: 40,
  //     visible: false,
  //     font: "Arial",
  //   },
  //   {
  //     id: 1,
  //     group: 0,
  //     text: " new text ",
  //     x: 0,
  //     y: 0,
  //     startTime: 3000.0,
  //     endTime: 5000.0,
  //     posX: 0,
  //     color: "#000000",
  //     backgroundColor: "rgba(248,231,28,0.8)",
  //     size: 40,
  //     visible: false,
  //     font: "Arial",
  //   },
  // ],
});

export const imageListState = atom({
  key: "imageListState",
  default: [],
});

export const audioListState = atom({
  key: "audioListState",
  default: [],
});

export const playState = atom({
  key: "playState",
  default: false,
});

export const timeState = atom({
  key: "timeState",
  default: 1000,
});

export const editState = atom({
  key: "editState",
  default: "upload",
});

export const elementState = atom({
  key: "elementState",
  default: 0,
});

export const scaleState = atom({
  key: "scaleState",
  default: 1,
});

export const zoomState = atom({
  key: "zoomState",
  default: 15000,
});

export const zoomInState = atom({
  key: "zoomInState",
  default: 0,
});

export const zoomOutState = atom({
  key: "zoomOutState",
  default: 0,
});

export const scrollState = atom({
  key: "scrollState",
  default: 0,
});

export const subtitleDefaultXState = atom({
  key: "subtitleDefaultXState",
  default: 0,
});

export const subtitleDefaultYState = atom({
  key: "subtitleDefaultYState",
  default: 0,
});

export const subtitleDefaultSizeState = atom({
  key: "subtitleDefaultSizeState",
  default: 20,
});

export const subtitleDefaultColorState = atom({
  key: "subtitleDefaultColorState",
  default: "#000000",
});

export const cursorUpdatedState = atom({
  key: "cursorUpdatedState",
  default: "0",
});

export const canvasState = atom({
  key: "canvasState",
  default: { width: 0, height: 0, color: "black" },
});
