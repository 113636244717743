import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

function EditPostPopup({ setEditPostShow, val, setCaption }) {
	let imgUrl = "";
	if (val.media[0]) {
		imgUrl = val.media[0].file;
	}

	const [CaptionText, setCaptionText] = useState(val.captions);
	const [isLoading, setIsLoading] = useState(false);

	function UpdateData() {
		setIsLoading(true);
		let formData = new FormData();
		formData.append("caption", CaptionText);
		formData.append("user_id", localStorage.getItem("user_id"));

		axios
			.post(`https://musicbook.co.in/api/v1/post/edit/${val._id}`, formData, {
				headers: {
					authorization: localStorage.getItem("auth_token"),
					"Content-Type": "multipart/form-data",
				},
			})
			.then((success) => {
				setIsLoading(false);
				toast.success("Updated Successfully");
				setCaption(CaptionText);
				setEditPostShow(false);
			})
			.catch((error) => {
				toast.error("error");
				setIsLoading(false);
			});
	}

	return (
		<div className="post-three-dot-popup">
			<div className="swal-overlay swal-overlay--show-modal ">
				<div
					style={{ border: "0" }}
					className="swal-modal edit-post-three-dot-popup editContainer"
					role="dialog"
					aria-modal="true">
					<h5>Edit</h5>
					<div className="display-flex" style={{ border: "0" }}>
						{val.media.length != 0 ? (
							<div className="mediaCont">
									{val.media[0].type == "image" ? (
										<img src={imgUrl} alt="" />
									) : (
										<video src={imgUrl} alt="" controls />
									)}
							</div>
						) : (
							<></>
						)}
						<div style={{ border: "0", width: "100%", display: "flex", flexDirection: "column" }}>
							<h5>Caption</h5>
							<h6>
								<textarea
									name=""
									id=""
									cols="30"
									rows="3"
									defaultValue={CaptionText}
									onChange={(e) => setCaptionText(e.target.value)}
									style={{
										width: "100%",
									}}></textarea>
							</h6>
						</div>
					</div>
					{isLoading ? <div className="ring"></div> : <></>}
					<center style={{ border: "0" }}>
						<button className="btn btn-outline-info" onClick={UpdateData}>
							Save
						</button>
					</center>
					<h5 className="close-popup-edit-post" onClick={() => setEditPostShow(false)}>
						X
					</h5>
				</div>
			</div>
		</div>
	);
}
export default EditPostPopup;
