// import { motion } from "framer-motion";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Support = () => {
  return (
    <>
      <div className="w-full h-screen flex justify-evenly items-center bg-gray-900 ">
        <div clasName="w-full h-screen flex justify-center items-center">
          <img
            src="https://cdni.iconscout.com/illustration/premium/thumb/support-8743185-7050116.png"
            width={450}
            height={140}
            alt="support image"
            srcset=""
          />
        </div>
        <div>
          <div className="support-header">
            <a href="/" className="text-xs uppercase text-gray-400">
              Back to home
            </a>
            <h2>Contact Support</h2>
            <p className="text-gray-500">
              Feel free to write us your quires and feedback
            </p>
          </div>
          <form
            action="https://api.form-data.com/f/9qahuptovmvwb441hzkrni"
            // onSubmit={handleSubmit}
            method="post"
            autoComplete="false"
            className="flex flex-col w-full gap-6 h-auto py-4 justify-start items-center"
          >
            <input
              type="text"
              // value={name}
              name="name"
              id="name"
              // autoComplete="false"
              required
              placeholder="Enter your full name"
              className="w-96 h-12 bg-transparent border-2 border-gray-600 rounded-lg active:text-white active:bg-transparent px-6"
            />
            <input
              type="email"
              name="email"
              // onChange={handleChange}
              // value={email}
              id="email"
              // autoComplete="false"
              placeholder="Enter your email"
              required
              className="w-96 h-12 bg-transparent border-2 border-gray-600 rounded-lg active:text-white active:bg-transparent px-6"
            />
            <input
              type="text"
              name="message"
              id="message"
              // onChange={handleChange}
              // value={message}
              // autoComplete="false"
              placeholder="Enter your message"
              // required

              className="w-96 h-12 bg-transparent border-2 border-gray-600 rounded-lg active:text-white active:bg-transparent px-6"
            />
            <button
              type="submit"
              className="w-96 h-12 bg-blue-800 text-white rounded-lg"
            >
              Submit Ticket
            </button>
          </form>
          <div className="support-details flex gap-8">
            <div>
              <p className="uppercase text-xs text-gray-500">Write a mail</p>
              <a
                className="uppercase text-base tracking-wide"
                // target="_blank"
                // href="mailto:support@musicbook.co.in"
                href="mailto:support@musicbook.co.in"
              >
                Support@musicbook.co.in
              </a>
            </div>
            <div>
              <p className="uppercase text-xs text-gray-500">Call us</p>
              <a
                className="uppercase text-base tracking-wide"
                href="tel:9019187374"
              >
                +91 9019187374
              </a>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Support;
