import { Box } from "@mui/joy";
import React from "react";

function PaddingContainer({ children }) {
  return (
    <Box sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 1 }}>{children}</Box>
  );
}

export default PaddingContainer;
