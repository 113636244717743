import React from "react";

const VideoFolderIcon = () => {
	return (
		<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.60751 9.93999C6.8324 9.93999 7.02105 9.86379 7.17345 9.71139C7.32585 9.55899 7.40179 9.37061 7.40126 9.14625V7.55874H8.98876C9.21365 7.55874 9.4023 7.48254 9.5547 7.33014C9.7071 7.17774 9.78304 6.98936 9.78251 6.76499C9.78251 6.5401 9.70631 6.35145 9.55391 6.19905C9.40151 6.04665 9.21313 5.97072 8.98876 5.97124H7.40126V4.38374C7.40126 4.15885 7.32506 3.9702 7.17266 3.8178C7.02026 3.6654 6.83188 3.58947 6.60751 3.58999C6.38261 3.58999 6.19396 3.66619 6.04156 3.81859C5.88916 3.97099 5.81323 4.15938 5.81376 4.38374V5.97124H4.22626C4.00136 5.97124 3.81272 6.04744 3.66032 6.19984C3.50792 6.35224 3.43198 6.54063 3.43251 6.76499C3.43251 6.98989 3.50871 7.17854 3.66111 7.33094C3.81351 7.48334 4.00189 7.55927 4.22626 7.55874H5.81376V9.14625C5.81376 9.37114 5.88996 9.55979 6.04236 9.71219C6.19476 9.86459 6.38314 9.94052 6.60751 9.93999ZM1.84501 13.115C1.40845 13.115 1.03459 12.9594 0.72344 12.6483C0.41229 12.3371 0.25698 11.9635 0.257509 11.5275V2.00249C0.257509 1.56593 0.413084 1.19208 0.724234 0.880926C1.03538 0.569776 1.40898 0.414465 1.84501 0.414995H11.37C11.8066 0.414995 12.1804 0.570569 12.4916 0.881719C12.8027 1.19287 12.958 1.56646 12.9575 2.00249V5.57437L15.4578 3.07406C15.5769 2.95499 15.719 2.9251 15.8841 2.98436C16.0492 3.04363 16.132 3.16613 16.1325 3.35187V10.1781C16.1325 10.3633 16.0497 10.4858 15.8841 10.5456C15.7184 10.6054 15.5764 10.5755 15.4578 10.4559L12.9575 7.95562V11.5275C12.9575 11.9641 12.8019 12.3379 12.4908 12.6491C12.1796 12.9602 11.806 13.1155 11.37 13.115H1.84501Z"
				fill="#7E7E7E"
			/>
		</svg>
	);
};

export default VideoFolderIcon;
