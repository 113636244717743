import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import axios from "../components/Hooks/axios";
import AudioPost from "./AudioPost";
import CaptionPost from "./CaptionPost";
import ImagePost from "./ImagePost";
import VideoPost from "./VideoPost";
import MessagePopup from "./MessagePopup";
import { useSelector } from "react-redux";

function PostForUser({ page, setPage, reload, setReload, profileId }) {
	const { isMessageOpen } = useSelector((state) => state.temp);
	const [show, setShow] = useState(false);
	const [postId, setPostId] = useState("");

	const [post, setPost] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [noPost, setNoPost] = useState(false);
	const [visible, setVisible] = useState("none");
	const [otherUserPopup, setOtherUserPopup] = useState(false);

	useEffect(() => {
		if (reload) {
			setPage(0);
			setPost([]);
		}
		setIsLoading(true);
		axios.get(`post/get-post-by-user/${profileId}?offset=${reload ? 0 : page}&type=`).then(({ data }) => {
			if (data.status == true) {
				setPost((prev) => [...prev, ...data.data.data]);
				setReload(false);
				setIsLoading(false);
				// console.log("other user Post",data)
				if (data.data.data.length == 0) setNoPost(true);
			} else {
				console.log("incorrect");
			}
		});
	}, [page,reload]);

	let i = 0;
	function NCards(val) {
		let imgUrl = "";
		let postType = "";
		const mediaArr = [];

		for (let i = 0; i < val.media.length; i++) {
			mediaArr.push(val.media[i].file);
		}

		if (val.media[0]) {
			imgUrl = val.media[0].file;
			postType = val.media[0].type;
		}

		function Visible() {
			setPostId(val._id);
			if (visible == "block") setVisible("none");
			else setVisible("block");
		}

		if (val?.created_by != null) {
			if (val.media[0]) {
				if (postType == "image") {
					return (
						<ImagePost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							mediaArr={mediaArr}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				} else if (postType == "video") {
					return (
						<VideoPost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							imgUrl={imgUrl}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				} else if (postType == "audio") {
					return (
						<AudioPost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							imgUrl={imgUrl}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				}
			} else {
				return (
					<CaptionPost
						key={val._id + i++}
						val={val}
						visible={visible}
						Visible={Visible}
						post={post}
						show={show}
						setShow={setShow}
						postId={postId}
						setPostId={setPostId}
						setReload={setReload}
						setPage={setPage}
						otherUserPopup={otherUserPopup}
						setOtherUserPopup={setOtherUserPopup}
					/>
				);
			}
		}
	}

	return (
		<>
			<>
				{post.map(NCards)}
				<div style={{ height: "80px", display: "flex", alignItems: "center", justifyContent: "center"}}>
					{isLoading ? (
						<ColorRing
							visible={true}
							height="70"
							width="70"
							ariaLabel="blocks-loading"
							wrapperClass="blocks-wrapper"
							colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
						/>
					) : (
						noPost && <h5>No More Post Available</h5>
					)}
				</div>

				{isMessageOpen && <MessagePopup />}
			</>
		</>
	);
}
export default PostForUser;
