"use client";

import React from "react";
import Style from "../style/about.module.css";
import Navbar from "../components/navbar";
import ContactSection from "../components/contact";
import { useMediaQuery } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:940px)");
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      <div className={Style.Container}>
        <Navbar
          gotoDownload={() => {
            navigate("/?show=download");
          }}
        />
        <div className={Style.Banner}>
          <img
            src={"/AboutBanner.png"}
            style={{ width: 1000 }}
            width={1000}
            height={0}
            sizes="fit"
            alt="Banner About"
          ></img>
        </div>
        <div className={Style.innerContainer}>
          <div className="pt-22">
            {/* <Image src={'/musiclogo.png'} width={200} height={120} alt={'Music Book Logo'}></Image> */}
            <h2 className="text-3xl text-center pb-4">
              Welcome to Music book™: Where Music Unites and Flourishes
            </h2>
            <p className="text-gray-200 max-w-fit text-center">
              At Music book™, we.re more than a platform; we.re a vibrant
              community of music enthusiasts. Our language is music, and our
              mission is to connect, create, and share the magic of sound. With
              us, you can learn various instruments, master the art of singing,
              and even express your poetic side through lyrics, Shayari, and
              more. It.s all at your fingertips on our tech-enabled social media
              app.
            </p>
          </div>
          <br />
          <div className="pt-4 sm:pl-10 pl-4">
            <h2 className="text-2xl">
              <b>Highlights</b>
            </h2>
            <div className=" w-full flex sm:flex-nowrap flex-wrap justify-evenly gap-6 align-middle pt-14 pb-14">
              <div className="flex flex-col gap-4 sm:w-1/2 w-auto">
                <h4>
                  <b>Karaoke Without Boundaries:</b>{" "}
                </h4>
                <p className="text-gray-200 w-fit text-left">
                  Record your favorite songs, with or without a camera, while
                  the lyrics scroll. It.s your stage to shine.
                </p>
              </div>
              <div className="flex flex-col gap-4 sm:w-1/2 w-auto">
                <h4 className="flex flex-col gap-4">
                  <b>Elevate Your Music:</b>
                </h4>
                <p className="text-gray-200 w-fit  text-left">
                  Share your musical creations, and watch them earn views,
                  stars, and shares – because your talent deserves the
                  spotlight.
                </p>
              </div>
              <div className="flex flex-col gap-4 sm:w-1/2 w-auto">
                <h4 className="flex flex-col gap-4">
                  <b>Collaborate and Celebrate</b>
                </h4>
                <p className="text-gray-200 w-fit text-left">
                  Join the Music book™ community of collaborative musicians
                  through our digital studio. Connect with friends and fellow
                  music lovers, and perform live 24/7, transcending geographical
                  boundaries.
                </p>
              </div>
            </div>
          </div>
          <div className={Style.innerContainer}>
            <div className="pt-4 sm:pl-10 pl-4">
              <h2 className="text-2xl">
                <b>Discover What Sets Us Apart:</b>
              </h2>
            </div>
            <div className="w-fill flex sm:flex-shrink-1 gap-y-4 flex-wrap justify-evenly align-middle pt-10">
              <div className="flex flex-col gap-4 w-80 px-6 py-6 border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Millions of Musical Choices:</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  {" "}
                  Choose from millions of your favorite songs spanning diverse
                  genres.
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Expert Guidance</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  {" "}
                  Learn from well-trained instructors to hone your musical
                  skills
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Versatile Performances</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  {" "}
                  Go solo, perform a duet, or collaborate with a group using our
                  digital studio.
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Social Media Integration:</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Seamlessly share your work on platforms like TikTok,
                  Instagram, Facebook, Snapchat, Twitter, and WhatsApp
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Live Events:</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Take the stage anytime, day or night, with our live event
                  feature
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Visual Magic</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Add engaging visual effects and filters to your audio
                  performanc
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Exciting Challenges</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Participate in monthly Music book™ challenges and competitions
                  to win prizes and gain recognition.
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Educational Resources</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Improve your music knowledge with courses and on-screen pitch
                  guidance.
                </p>
              </div>
              <div className="flex flex-col gap-4 w-80 px-6 py-6  border-gray-400 border-2 rounded  hover:bg-blue-800 hover:border-none">
                <h4 className="flex flex-col gap-4">
                  <b>Collaboration Hub:</b>
                </h4>
                <p className="text-gray-200 w-18 text-left">
                  Explore our digital studio to collaborate with fellow
                  musicians.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Banner Image  */}
        <div className={Style.MusicBanner}>
          <div
            className="h-auto flex sm:justify-evenly justify-between align-middle "
            style={{
              flexWrap: isMobile ? "wrap" : "nowrap",
            }}
          >
            <div className={Style.ContentBanner}>
              <div>
                <h1 className="text-4xl font-semibold sm:px-0 px-6">
                  Let{"'"}s Create Music Together
                </h1>
                <p>
                  Sing your heart out in Punjabi, pop, R&B, rock, rap, and more.
                  Discover your favorite songs and karaoke hits, with new
                  additions regularly, at Music book™.
                </p>
              </div>
            </div>
            <img
              src={"/Girlsimage.png"}
              style={{ width: 900, display: isMobile ? "none" : "flex" }}
              alt="Banner Image"
            ></img>
          </div>
        </div>
        <ContactSection />
      </div>
    </div>
  );
}

export default About;
