import { Box, Button, IconButton, Stack } from "@mui/joy";
import React from "react";
import TimelineEditor from "./TimelineEditor";
import "./videoEditorStyles.css";
import VodcastPlayer from "../VodcastPlayer/index.js";
import SeekBar from "../VodcastPlayer/Seekbar";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiCursorLine,
  RiFileAddLine,
  RiImageAddLine,
  RiScissorsCutLine,
  RiText,
  RiVideoAddLine,
} from "react-icons/ri";
import EditorPlayer from "./EditorPlayer";
import CodinnoVideoEditor from "../../../modules/CodinnoVideoEditor";

function VideoEditor({ thumbnail, video }) {
  console.log("thumbail", thumbnail);
  return (
    <CodinnoVideoEditor videoFile={video} />

    // <Box
    //   sx={{
    //     width: { xs: "100%", sm: "80%", md: "70%", lg: "50%" },
    //     height: "100%",
    //     maxHeight: "70vh",
    //     borderWidth: 1,
    //   }}
    // >

    //   <Box sx={{ width: "100%", aspectRatio: 16 / 9 }}>
    //     <EditorPlayer
    //       controls={false}
    //       autoPlay={true}
    //       video={video}
    //       thumbnail={thumbnail}
    //     />
    //   </Box>
    //   <Box
    //     sx={{
    //       width: "100%",
    //       my: 1,
    //     }}
    //   >
    //     <Stack
    //       direction="row"
    //       justifyContent="space-between"
    //       alignItems="center"
    //     >
    //       <Stack direction="row" spacing={1}>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiScissorsCutLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiFileAddLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiVideoAddLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiImageAddLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiCursorLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiText />
    //         </IconButton>
    //       </Stack>
    //       <Stack direction="row">
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiArrowGoBackLine />
    //         </IconButton>
    //         <IconButton sx={{ minWidth: "auto", minHeight: "auto", p: 0.5 }}>
    //           <RiArrowGoForwardLine />
    //         </IconButton>
    //       </Stack>
    //     </Stack>
    //   </Box>
    //   <Box
    //     sx={{
    //       width: "100%",
    //       zIndex: 3,
    //       // marginTop: 2,
    //     }}
    //   >
    //     <SeekBar media={{ duration: 100, currentTime: 50 }} />
    //   </Box>
    //   <Box sx={{ width: "100%", height: 90, zIndex: 1 }}>
    //     <TimelineEditor />
    //   </Box>
    //    <CodinnoVideoEditor videoFile={video} />
    // </Box>
  );
}

export default VideoEditor;
