import { createSlice } from "@reduxjs/toolkit";
import { createNotification } from "../../components/Hooks/CreateNotification";
import axios from "../../components/Hooks/axios";

const initialState = {
	isFollow: false,
	albumChange: false,
	search: false,
	searchType: "postCaption",
	isMessageOpen: false,
	messageUserId: "",
};

const TempSlice = createSlice({
	name: "TempSlice",
	initialState,
	reducers: {
		setIsFollow: (state, { payload }) => {
			state.isFollow = payload;
		},
		setAlbumChange: (state, { payload }) => {
			state.albumChange = payload;
		},
		setSearch: (state, { payload }) => {
			state.search = payload.search;
			state.searchType = payload.type;
		},
		followFunction: (state, { payload: { user, profile } }) => {
			axios
				.post("/auth/follow-unfollow-user", {
					user_id: profile._id,
				})
				.then(({ data }) => {
					if (data.status) {
						if (data.message === "unfollow") {
							createNotification(user._id, "Follow", `You unfollow ${profile.full_name}`);
						} else if (data.message === "follow") {
							createNotification(profile._id, "Follow", `${user.full_name} started following you`);
							createNotification(user._id, "Follow", `You started following ${profile.full_name}`);
						}
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		setMessage: (state, { payload: { open, id } }) => {
			state.isMessageOpen = open;
			state.messageUserId = id;
		},
	},
});
export const { setIsFollow, setAlbumChange, setSearch, followFunction, setMessage } = TempSlice.actions;
export default TempSlice.reducer;
