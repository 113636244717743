import React from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric"; // this also installed on your project
import { useEffect, useRef, useState, useContext } from "react";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  editState,
  elementState,
  textListState,
  imageListState,
  timeState,
  zoomState,
  canvasState,
  scaleState,
} from "../atoms";

import cloneDeep from "lodash/cloneDeep";

const Canvas = () => {
  const fabricRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const [textList, _setTextList] = useRecoilState(textListState);
  const [imageList, _setImageList] = useRecoilState(imageListState);

  const textListRef = React.useRef(textList);
  const imageListRef = React.useRef(imageList);

  const [edit, setEdit] = useRecoilState(editState);
  const [element, setElement] = useRecoilState(elementState);

  const [time, setTime] = useState(0);

  const setTextList = (data) => {
    textListRef.current = data;
    _setTextList(data);
  };

  const setImageList = (data) => {
    imageListRef.current = data;
    _setImageList(data);
  };

  useEffect(() => {
    textListRef.current = cloneDeep(textList);
  }, [textList]);

  useEffect(() => {
    imageListRef.current = cloneDeep(imageList);
  }, [imageList]);

  useEffect(() => {
    let intervalId = null;
    intervalId = setInterval(() => {
      let video = document.getElementById("editorTimer");
      setTime(video.currentTime * 1000);
    }, 20);

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    for (let i = 0; i < textList.length; i++) {
      if (time > textList[i].startTime && time < textList[i].endTime) {
        if (textList[i].visible === false) {
          let tL = cloneDeep(textList);
          tL[i].visible = true;
          setTextList(tL);
        }
      } else {
        if (textList[i].visible === true) {
          let tL = cloneDeep(textList);
          tL[i].visible = false;
          setTextList(tL);
        }
      }
    }

    for (let i = 0; i < imageList.length; i++) {
      if (time > imageList[i].startTime && time < imageList[i].endTime) {
        if (imageList[i].visible === false) {
          let iL = cloneDeep(imageList);
          iL[i].visible = true;
          setImageList(iL);
        }
      } else {
        if (imageList[i].visible === true) {
          let iL = cloneDeep(imageList);
          iL[i].visible = false;
          setImageList(iL);
        }
      }
    }
  }, [time]);

  function modifyImage(id, modifiedObject) {
    imageListRef.current[id].x = modifiedObject.get("left");
    imageListRef.current[id].y = modifiedObject.get("top");
    imageListRef.current[id].width =
      modifiedObject.get("width") * modifiedObject.get("scaleX");
    imageListRef.current[id].height =
      modifiedObject.get("height") * modifiedObject.get("scaleY");

    setImageList(imageListRef.current);
    setEdit("image");
    setElement(id);
  }

  function modifyText(id, modifiedObject) {
    let fontSize = modifiedObject.fontSize * modifiedObject.scaleX;

    const newArr = JSON.parse(JSON.stringify(textListRef.current));
    textListRef.current[id].x = modifiedObject.get("left");
    textListRef.current[id].y = modifiedObject.get("top");
    textListRef.current[id].size = fontSize;
    textListRef.current[id].text = modifiedObject.text;

    setTextList(textListRef.current);
    setEdit("text");
    setElement(id);
  }

  function modified(evt) {
    var modifiedObject = evt.target;

    let id = modifiedObject.id;
    let type = modifiedObject.type;

    if (type === "text") {
      modifyText(id, modifiedObject);
    }
    if (type === "image") {
      modifyImage(id, modifiedObject);
    }
  }

  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
      fabricRef.current.setWidth(1000);
      fabricRef.current.setHeight(1000);

      fabricRef.current.on({
        "object:modified": modified,
      });
    };
    initFabric();
  }, []);

  useEffect(() => {
    fabricRef.current.clear();
    const addTexts = () => {
      for (let i = 0; i < textList.length; i++) {
        const text = new fabric.IText(textList[i].text, {
          id: i,
          type: "text",
          fill: textList[i].color,
          fontSize: textList[i].size,
          fontFamily: textList[i].font,
          editable: true,
          left: textList[i].x,
          top: textList[i].y,
          visible: textList[i].visible,
        });

        const bg = new fabric.Rect({
          left: textList[i].x,
          top: textList[i].y,
          fill: textList[i].backgroundColor,
          width: text.width,
          height: text.height,
          visible: textList[i].visible,
          fill: textList[i].backgroundColor,
        });
        fabricRef.current.add(bg);

        fabricRef.current.add(text);
      }
    };

    const addImages = () => {
      for (let i = 0; i < imageList.length; i++) {
        const image = new fabric.Image.fromURL(imageList[i].src, function (
          oImg
        ) {
          oImg.set("id", imageList[i].id);
          oImg.set("left", imageList[i].x);
          oImg.set("top", imageList[i].y);
          oImg.scaleToWidth(imageList[i].width);
          oImg.scaleToHeight(imageList[i].height);
          oImg.set("visible", imageList[i].visible);

          fabricRef.current.add(oImg);
        });
      }
    };

    const disposeFabric = () => {
      fabricRef.current.dispose();
    };

    addTexts();
    addImages();
  }, [textList, imageList]);

  return (
    <div
      style={{
        position: "absolute",
        maxWidth: "100%",
        maxHeight: "100%",
        aspectRatio: 16 / 9,
      }}
    >
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Canvas;
