import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "../css/ReportPopup.module.scss";
import axios from "./Hooks/axios";

const ReportPopup = ({ setReportPopupShow, postId }) => {
	const [reportText, setReportText] = useState("");
	const [disableBtn, setDisableBtn] = useState(false);

	const handelReport = () => {
		if (reportText === "") return toast.warn("Please Select Something !");
		setDisableBtn(true);

		let raw = JSON.stringify({
			report_text: reportText,
			report_type: reportText,
			type: "Post",
			report_id: postId,
		});

		axios
			.post("report/add-report", raw, { headers: { "Content-Type": "application/json" } })
			.then(({ data }) => {
				console.log(data);
				if (data.status) {
					toast.success("Successfully Reported ! \n Admin will look into this");
					setDisableBtn(false);
					setReportPopupShow(false);
				}
			})
			.catch((e) => {
				setDisableBtn(false);
				console.log(e.message);
				toast.error(e.message);
			});
	};

	return (
		<div className={styles.ReportPopup} onClick={() => setReportPopupShow(false)}>
			<div className={styles.ReportPopupBox} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Content}>
					<h2>Report Post</h2>
					<div className={styles.radioDiv}>
						<input
							type="radio"
							id="spam"
							name="report"
							value="spam"
							onChange={(e) => setReportText(e.currentTarget.value)}
						/>
						<label htmlFor="spam">Spam</label>
					</div>
					<div className={styles.radioDiv}>
						<input
							type="radio"
							id="misinformation"
							name="report"
							value="misinformation"
							onChange={(e) => setReportText(e.currentTarget.value)}
						/>
						<label htmlFor="misinformation">Misinformation</label>
					</div>
					<div className={styles.radioDiv}>
						<input
							type="radio"
							id="harmful"
							name="report"
							value="harmful"
							onChange={(e) => setReportText(e.currentTarget.value)}
						/>
						<label htmlFor="harmful">Harmful</label>
					</div>
					<div className={styles.radioDiv}>
						<input
							type="radio"
							id="misleading"
							name="report"
							value="misleading"
							onChange={(e) => setReportText(e.currentTarget.value)}
						/>
						<label htmlFor="misleading">Misleading</label>
					</div>
					<div className={styles.radioDiv}>
						<input
							type="radio"
							id="hateful"
							name="report"
							value="hateful"
							onChange={(e) => setReportText(e.currentTarget.value)}
						/>
						<label htmlFor="hateful">Hateful</label>
					</div>
				</div>
				<div className={styles.ButtonContainer}>
					<button disabled={disableBtn} onClick={handelReport}>
						Ok
					</button>
					<button onClick={() => setReportPopupShow(false)}>Cancel</button>
				</div>
			</div>
		</div>
	);
};

export default ReportPopup;
