import {
  Box,
  CircularProgress,
  DialogContent,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import React from "react";
import constants from "../../configs/constants";
import Lottie from "lottie-react";

function LoadingView({ onClose }) {
  return (
    <div
      style={{
        zIndex: 9999,
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: constants.colors.secondary,
          opacity: 0.7,
        }}
      />

      <ModalDialog
        slotProps={{
          root: {
            style: {
              top: "45%",
            },
          },
        }}
        sx={{
          maxWidth: { xs: 400, sm: 500 },
          minWidth: { xs: "80%", sm: 400, md: 500, lg: 500 },
          minHeight: 300,
          borderRadius: "xs",
          p: 3,
          boxShadow: "xs",
          background: "#222020",
          outlineWidth: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogContent sx={{ width: "100%" }}>
          {/* <ModalClose onClick={onClose} /> */}

          <Box
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <CircularProgress />
            <Typography level="body-lg" fontSize={14}>
              Please wait ...
            </Typography>
          </Box>
        </DialogContent>
      </ModalDialog>
    </div>
  );
}

export default LoadingView;
