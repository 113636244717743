export async function getData(url = "", methods = "") {
  const response = await fetch(url, {
    method: methods,
    headers: {
      "Content-Type": "application/json",
      authorization:
        localStorage.getItem("auth_token") ||
        sessionStorage.getItem("auth_token"),
    },
  });
  return response.json();
}

export async function postData(url = "", data) {
  const response = await fetch(url, {
    method: "POST",
    body: data,
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization:
        localStorage.getItem("auth_token") ||
        sessionStorage.getItem("auth_token"),
    },
  });
  return response.json();
}

export async function postData1(url = "", data) {
  const response = await fetch(url, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
      authorization:
        localStorage.getItem("auth_token") ||
        sessionStorage.getItem("auth_token"),
    },
  });
  return response.json();
}
