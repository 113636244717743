import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
	user: null,
	userId: "",
	token: null,
	userType: "",
	isLoading: false,
	isSuccess: false,
	isError: false,
	message: "",
};

const AuthSlice = createSlice({
	name: "AuthSlice",
	initialState,
	reducers: {
		setInLocalStorage: (state) => {
			state.user = JSON.parse(localStorage.getItem("user_data")) || JSON.parse(sessionStorage.getItem("user_data"));
			state.token = localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token");
			state.userId = localStorage.getItem("user_id") || sessionStorage.getItem("user_id");
			state.userType = localStorage.getItem("user_type") || sessionStorage.getItem("user_type");
			state.isSuccess = true;
		},
		saveUser: (state, { payload }) => {
			if (payload.rememberMe) {

				localStorage.setItem("auth_token", payload.token);
				localStorage.setItem("user_data", JSON.stringify(payload.data));
				localStorage.setItem("user_id", payload.data._id);
				localStorage.setItem("user_type", payload.data.user_type);
			} else {
				sessionStorage.setItem("auth_token", payload.token);
				sessionStorage.setItem("user_data", JSON.stringify(payload.data));
				sessionStorage.setItem("user_id", payload.data._id);
				sessionStorage.setItem("user_type", payload.data.user_type);
			}
			state.user = payload.data;
			state.token = payload.token;
			state.userId = payload.data._id;
			state.userType = payload.data.user_type;
			state.isSuccess = true;
			toast.success("Successfully logged in");
		},
		updateUserData: (state, { payload }) => {
			if (localStorage.getItem("user_data")) {
				localStorage.setItem("user_data", JSON.stringify(payload));
			} else {
				sessionStorage.setItem("user_data", JSON.stringify(payload));
			}
			state.user = payload;
		},
		logout: (state) => {
			localStorage.removeItem("auth_token");
			localStorage.removeItem("user_id");
			localStorage.removeItem("user_type");
			localStorage.removeItem("user_data");
			sessionStorage.removeItem("auth_token");
			sessionStorage.removeItem("user_id");
			sessionStorage.removeItem("user_type");
			sessionStorage.removeItem("user_data");
			state.user = null;
			state.userId = "";
			state.token = null;
			state.userType = "";
			state.isSuccess = false;
			state.message = "";
			toast.success("Successfully logged out");
		},
	},
});

export const { saveUser, setInLocalStorage, updateUserData, logout } = AuthSlice.actions;
export default AuthSlice.reducer;
