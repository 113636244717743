import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useProfessionalValidate from "../components/Hooks/useProfessionalValidate";
import { CropEasy } from "../components/crop/utils/CropEasy";
import editPic from "../images/edit-pic.png";
import userDefaultImage from "../images/userDefaultImage.jpg";
import Header from "./Professional_header";

function ProfessionalProfileUpdate() {
	// useProfessionalValidate();
	const navigate = useNavigate();
	const fileInputRef = useRef(null);
	const handleDivClick = () => {
		fileInputRef.current.click();
	};
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [Mobile, setMobile] = useState("");
	const [PinCode, setPinCode] = useState("");
	const [State, setState] = useState("");
	const [Password, setPassword] = useState("");
	const [About, setAbout] = useState("");
	const [Add, setAdd] = useState("");
	const [City, setCity] = useState("");
	const [image, setImage] = useState("");
	const [file, setFile] = useState([]);
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [gender, setGender] = useState("");

	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	useEffect(() => {
		getData(`${process.env.REACT_APP_BASE_URL}/auth/get-user-details?user_id=` + localStorage.getItem("user_id"), "GET").then(
			(data) => {
				if (data.status == true) {
					setName(data.data.data.full_name);
					setEmail(data.data.data.email);
					setPhotoURL(data.data.data.pic);
					setMobile(data.data.data.mobile_number);
					setAdd(data.data.data.address);
					setCity(data.data.data.city);
					setState(data.data.data.state);
					setPinCode(data.data.data.pincode);
					setPassword(data.data.data.password);
					setAbout(data.data.data.description);
					setGender(data.data.data.gender);
				} else {
					console.log("incorrect");
				}
			}
		);
	}, []);

	async function Update() {
		console.log("up");
		let formData = new FormData();
		formData.append("pic", file);
		formData.append("full_name", name);
		formData.append("email", email);
		formData.append("mobile_number", Mobile);
		formData.append("address", Add);
		formData.append("city", City);
		formData.append("state", State);
		formData.append("pincode", PinCode);
		formData.append("description", About);
		formData.append("gender", gender);

		let response = await fetch(
			`${process.env.REACT_APP_BASE_URL}/auth/update-user-details/${
				localStorage.getItem("user_id") || sessionStorage.getItem("user_id")
			}`,
			{
				method: "PUT",
				body: formData,
				headers: { authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token") },
			}
		);

		let result = await response.json();
		toast.error(result.message);

		if (result.code == 200) {
			toast.success("Profile updated");
			navigate("/Professional-account");
		}
	}

	function onImageChange(e) {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
			setImage(URL.createObjectURL(file));
			setPhotoURL(URL.createObjectURL(file));
			setOpenCrop(true);
		}
		e.target.value = null;
	}

	const handleGenderChange = (event) => {
		setGender(event.target.value);
	};

	return (
		<div className="MainContainer">
			<Header />
			{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
			<div className="BasicStyle home accountContainer Professional-profile-update">
				<div className="account">{/* for bg img */}</div>

				<div className="home-right-mid">
					<div className="profilePicContainer">
						<div className="pictureContainer">
							<img src={photoURL.length == 0 ? userDefaultImage : photoURL} alt="" />
						</div>
						<div className="upload-btn-wrapper" onClick={handleDivClick}>
							<img src={editPic} alt="" /> <hr />
							<input type="file" name="myfile" ref={fileInputRef} onChange={onImageChange} />
						</div>
					</div>
				</div>

				<div className="profile-edit-card">
					<div style={{ maxWidth: "50%", width: "50%" }}>
						<input type="text" placeholder="✎ Name" onChange={(e) => setName(e.target.value)} value={name} /> <br />
						<input type="text" placeholder="✉ Email" onChange={(e) => setEmail(e.target.value)} value={email} />
						<br />
						<input
							type="text"
							placeholder="✆ Mobile Number"
							onChange={(e) => setMobile(e.target.value)}
							value={Mobile}
						/>
						<br />
						<input type="text" placeholder="📌 Address" onChange={(e) => setAdd(e.target.value)} value={Add} />
						<br />
					</div>
					<div style={{ maxWidth: "50%", width: "50%" }}>
						<div className="justify-space" style={{ width: "90%" }}>
							<input
								type="text"
								placeholder=" City "
								onChange={(e) => setCity(e.target.value)}
								value={City}
								style={{ width: "45%" }}
							/>
							<br />
							<input
								type="text"
								placeholder=" State "
								onChange={(e) => setState(e.target.value)}
								value={State}
								style={{ width: "45%" }}
							/>
						</div>
						<input type="text" placeholder="* Pincode" onChange={(e) => setPinCode(e.target.value)} value={PinCode} />
						<br />
						<input type="text" placeholder=" About You" onChange={(e) => setAbout(e.target.value)} value={About} />
						<br />
						<div className="display-flex" style={{ width: "90%" }}>
							<input
								type="text"
								className="text-white"
								placeholder=" Select Gender"
								style={{ width: "45%" }}
								disabled
								value={gender ? gender.toUpperCase() : "Select Gender"}
							/>
							<br />
							<div className="gender-selector display-flex m-2">
								<label>
									<input type="radio" value="male" checked={gender === "male"} onChange={handleGenderChange} />
									Male
								</label>
								<label>
									<input
										type="radio"
										value="female"
										checked={gender === "female"}
										onChange={handleGenderChange}
									/>
									Female
								</label>
								<label>
									<input
										type="radio"
										value="other"
										checked={gender === "other"}
										onChange={handleGenderChange}
									/>
									Other
								</label>
								{/* <p>You selected: {gender}</p> */}
							</div>
						</div>
						<button className="btn btn-primary" onClick={Update}>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ProfessionalProfileUpdate;
