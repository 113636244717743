import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import DocumentFolderIcon from "../../components/svgCom/DocumentFolderIcon";
import VideoFolderIcon from "../../components/svgCom/VideoFolderIcon";
import CrossIcon from "../../images/CrossIcon.png";

const AddLecture = ({ setReloadUseEffect, setAddLectureShow, classId }) => {
	const [uploadPer, setUploadPer] = useState(0);
	const [VideoFile, setVideoFile] = useState(null);
	const [videoDuration, setVideoDuration] = useState(0);
	const [PDFFile, setPDFFile] = useState(null);
	const [Title, setTitle] = useState("");
	const [CourseBtn, setCourseBtn] = useState(false);
	const videoRef = useRef();
	const pdfRef = useRef();

	const handelAddLecture = () => {
		if (Title == "") return toast.error("Please add a title");
		if (VideoFile == null) return toast.error("Please add a video");
		if (PDFFile == null) return toast.error("Please add a document");

		setCourseBtn(true);
		const id = toast.loading("Please wait...");

		let formData = new FormData();
		formData.append("class_id", classId);
		formData.append("video", VideoFile);
		formData.append("document", PDFFile);
		formData.append("lectureTitle", Title);
		formData.append("lectureDuration", videoDuration);

		axios
			.put("/class/addLacture", formData, {
				onUploadProgress: (data) => {
					setUploadPer(Math.round((data.loaded / data.total) * 100));
				},
			})
			.then(({ data }) => {
				if (data.status == true) {
					toast.update(id, {
						render: "Lecture Added Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
					setReloadUseEffect(Math.random());
					setCourseBtn(false);
					setAddLectureShow(false);
					setUploadPer(0);
					setVideoFile(null);
					setVideoDuration(0);
					setPDFFile(null);
					setTitle("");
				} else {
					toast.update(id, {
						render: "Something wrong",
						type: "error",
						isLoading: false,
						autoClose: 2000,
					});
					setCourseBtn(false);
				}
			})
			.catch((error) => {
				toast.update(id, { render: error, type: "error", isLoading: false, autoClose: 2000 });
				setCourseBtn(false);
				console.log("error =: ", error);
			});
	};

	return (
		<div className="CreateClassContainer">
			<div className="CreateClassMain Lecture">
				<div className="CreateClassTitle">
					<h1>Add Lecture</h1>
					<h4>Please fill all the required fields to create Lecture</h4>
					<button onClick={() => setAddLectureShow(false)}>
						<img src={CrossIcon} alt="" />
					</button>
				</div>
				<div className="ContentFields">
					<input type="text" placeholder="Lecture Title" value={Title} onChange={(e) => setTitle(e.target.value)} />
				</div>
				<div className="ContentFields c3">
					<button
						type="button"
						onClick={() => videoRef.current.click()}
						style={{
							background: VideoFile ? "#471CF1" : "",
							color: VideoFile ? "#fff" : "",
						}}>
						<VideoFolderIcon /> Upload Video
					</button>
					<button
						type="button"
						onClick={() => pdfRef.current.click()}
						style={{
							background: PDFFile ? "#471CF1" : "",
							color: PDFFile ? "#fff" : "",
						}}>
						<DocumentFolderIcon /> Upload pdf
					</button>
					{VideoFile || PDFFile ? (
						<button
							type="button"
							onClick={() => {
								setPDFFile(null);
								setVideoFile(null);
							}}
							style={{
								background: VideoFile || PDFFile ? "#471CF1" : "",
								color: VideoFile || PDFFile ? "#fff" : "",
							}}>
							Clear
						</button>
					) : null}
					<input
						type="file"
						accept="video/*"
						ref={videoRef}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							setVideoFile(file);
							let video = document.createElement("video");
							video.src = URL.createObjectURL(file);
							video.onloadedmetadata = function () {
								setVideoDuration(Math.round(video.duration));
							};
							e.target.value = null;
						}}
					/>
					<input
						type="file"
						accept="pdf/*"
						ref={pdfRef}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							setPDFFile(file);
							e.target.value = null;
						}}
					/>
				</div>
				<button type="button" disabled={CourseBtn} className="CourseBtn" onClick={handelAddLecture}>
					<span style={{ width: `${uploadPer}%` }}></span>
					<p>{uploadPer != 0 ? `${uploadPer}%` : "Add Lecture"}</p>
				</button>
			</div>
		</div>
	);
};

export default AddLecture;
