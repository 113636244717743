import {
  Box,
  DialogContent,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import React from "react";
import constants from "../../configs/constants";
import Lottie from "lottie-react";

function UploadingView({ onClose, uploadSpeed = '', visibleProgress = "0%", uploadingPercent = 0, isDraft = false }) {
  return (
    <div
      style={{
        zIndex: 9999,
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: constants.colors.secondary,
          opacity: 0.7,
        }}
      />

      <ModalDialog
        slotProps={{
          root: {
            style: {
              top: "45%",
            },
          },
        }}
        sx={{
          maxWidth: { xs: 400, sm: 500 },
          minWidth: { xs: "80%", sm: 400, md: 500, lg: 500 },
          minHeight: 300,
          borderRadius: "xs",
          p: 3,
          boxShadow: "xs",
          background: "#222020",
          outlineWidth: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogContent sx={{ width: "100%" }}>
          {/* <ModalClose onClick={onClose} /> */}

          <Box
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Lottie
              loop={true}
              animationData={require("../../assets/lottie/uploading.json")}
              style={{ width: 100, height: 100 }}
            />
            <Typography level="body-lg" fontSize={14}>
              {!isDraft
                ? "Uploading Vodcast . Please wait ..."
                : "Saving to draft . Please wait ..."}
            </Typography>

            <div className="progress" style={{ opacity: visibleProgress }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadingPercent}%` }}
                aria-valuenow={uploadingPercent}
                aria-valuemin="0"
                aria-valuemax="100">
                {uploadingPercent}%
              </div>
            </div>
            <p>{uploadSpeed}</p>

          </Box>

        </DialogContent>
      </ModalDialog>
    </div>
  );
}

export default UploadingView;
