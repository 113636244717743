import { Skeleton, Typography } from "@mui/joy";
import React from "react";

function BigTitle({ children, shimmer = false }) {
  return (
    <Typography
      fontWeight={"bold"}
      fontSize={{ xs: 30, sm: 40, md: 50, lg: 70 }}
      sx={{ color: "#fff" }}
    >
      <Skeleton loading={shimmer}>{children} </Skeleton>
    </Typography>
  );
}

export default BigTitle;
