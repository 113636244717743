import React, { useEffect, useState, useRef, useContext } from "react";
import * as ReactDOM from "react-dom";
import {
  imageListState,
  videoListState,
  textListState,
  playState,
  timeState,
  editState,
  zoomState,
  elementState,
  cursorUpdatedState,
  audioListState,
  zoomInState,
  zoomOutState,
} from "../atoms";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

import Timeline from "react-visjs-timeline";
import "./TimelineStyle.css";

import cloneDeep from "lodash/cloneDeep";
import { Box, Stack, IconButton } from "@mui/joy";
import {
  RiEyeOffLine,
  RiMusic2Line,
  RiMusicLine,
  RiText,
  RiVidiconLine,
  RiVolumeMuteLine,
} from "react-icons/ri";

const TimePanel = (props) => {
  const [textList, setTextList] = useRecoilState(textListState);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [imageList, setImageList] = useRecoilState(imageListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);

  const [play, setPlay] = useRecoilState(playState);
  const [edit, setEdit] = useRecoilState(editState);
  const [element, setElement] = useRecoilState(elementState);
  const [cursorUpdated, setCursorUpdated] = useRecoilState(cursorUpdatedState);

  const [zoomIn, setZoomIn] = useRecoilState(zoomInState);
  const [zoomOut, setZoomOut] = useRecoilState(zoomOutState);

  const [zoom, setZoom] = useRecoilState(zoomState);

  const timelineref = React.createRef();

  let totalTimeMS = videoList?.[videoList.length - 1]?.endTime;

  useEffect(() => {
    let intervalId = null;
    intervalId = setInterval(() => {
      let time = document.getElementById("editorTimer").currentTime * 1000;
      if (
        videoList[videoList.length - 1] !== undefined &&
        time > videoList[videoList.length - 1].endTime
      ) {
        document.getElementById("editorTimer").currentTime = 0;
      }
      timelineref.current.$el.setCustomTime(time, "currenttime");
    }, 20);

    return () => clearInterval(intervalId);
  });

  function convertAtomsToDataset() {
    var dataset = [];
    var groups = [];

    groups.push({ id: "video", content: "&#160" });
    console.log(videoList, "HERE");
    for (let i = 0; i < videoList.length; i++) {
      let video = videoList[i];
      dataset.push({
        id: video.id + textList.length,
        className: "video12",
        start: video.startTime,
        end: video.endTime,
        content: "video",
        group: "video",
      });
    }

    // groups.push({ id: "image", content: "&#160" });
    for (let i = 0; i < imageList.length; i++) {
      let image = imageList[i];
      dataset.push({
        id: image.id + textList.length + videoList.length,
        className: "image",
        start: image.startTime,
        end: image.endTime,
        content: "image",
        group: "image",
      });
    }

    groups.push({ id: "audio", content: "&#160" });
    for (let i = 0; i < videoList.length; i++) {
      let audio = videoList[i];
      dataset.push({
        id: audio.id + textList.length + videoList.length + imageList.length,
        className: "audio",
        start: audio.startTime,
        end: audio.endTime,
        content: "audio",
        group: "audio",
      });
    }

    /*
    groups.push({ id: 0, content: "&#160" })
    for (let i = 0; i < textList.length; i++) {
      let text = textList[i]
      let newGroup = false;
      for(let e = 0; e < textList.length; e++){
        if(((textList[e].startTime > textList[i].startTime)  && (textList[e].startTime < textList[i].endTime) || (textList[e].endTime > textList[i].startTime)  && (textList[e].endTime < textList[i].endTime)) && ){
          newGroup = true;
        }
      }
      
      if(newGroup){
        groups.push({ id: groups.length-1, content: "&#160" })
      }
      dataset.push({ id: i, className: "text", start: text.startTime, end: text.endTime, content: text.text, group:groups.length-2})

    }
*/
    groups.push({ id: 0, content: "&#160" });
    groups.push({ id: 1, content: "&#160" });
    for (let i = 0; i < textList.length; i++) {
      let text = textList[i];

      groups.push({ id: groups.length - 1, content: "&#160" });
      dataset.push({
        id: i,
        className: "text",
        start: text.startTime,
        end: text.endTime,
        content: text.text,
        group: text.group,
      });
    }

    timelineref.current.$el.setItems(dataset);
    timelineref.current.$el.setGroups(groups);
  }

  function changestartinatomtext(id, newstart, newend, group) {
    let newList = [...textList].map((item) => {
      let newData = { ...item };
      if (item.id === id) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      } else return item;
    });
    setTextList(newList);
  }

  function changestartinatomaudio(id, newstart, newend, group) {
    let newList = [...audioList].map((item) => {
      let newData = { ...item };
      if (
        item.id ===
        id - textList.length - videoList.length - imageList.length
      ) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      } else return item;
    });
    setAudioList(newList);
  }

  function changestartinatomimage(id, newstart, newend, group) {
    let newList = [...imageList].map((item) => {
      let newData = { ...item };
      if (item.id === id - textList.length - videoList.length) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      } else return item;
    });
    setImageList(newList);
  }

  function deletetextinatom(id) {
    setTextList((textList) =>
      textList
        .filter((_, index) => index !== id)
        .map((item, index) => ({ ...item, id: index }))
    );
  }

  function deleteimageinatom(id) {
    id = id - textList.length - videoList.length;
    setImageList((imageList) =>
      imageList.filter((value, index) => value !== imageList[id])
    );
  }

  function deleteaudioinatom(id) {
    id = id - textList.length - videoList.length - imageList.length;
    setAudioList((audioList) =>
      audioList
        .filter((_, index) => index !== id)
        .map((item, index) => ({ ...item, id: index }))
    );
  }

  function deletevideoinatom(id) {
    id = id - textList.length;

    const filteredList = videoList.filter(
      (value, index) => value !== videoList[id]
    );

    // Update the IDs of the remaining objects
    const updatedList = filteredList.map((value, index) => {
      let newStartTime = value.startTime;
      let newEndTime = value.endTime;

      if (index === 0 && newStartTime > 0) {
        const timeDifference = newStartTime;
        newStartTime = 0;
        newEndTime -= timeDifference;
      } else if (index > 0) {
        newStartTime = filteredList[index - 1].endTime;
        const duration = newEndTime - value.startTime;
        newEndTime = newStartTime + duration;
      }

      return {
        ...value,
        id: index,
        startTime: newStartTime,
        endTime: newEndTime,
      };
    });

    setVideoList(updatedList);
  }

  async function changestartinatomvideo(id, newstart, newend) {
    id = id - textList.length - imageList.length;
    const vl = cloneDeep(videoList);
    //change range fully
    /* if ((Math.round(vl[id].endTime) - Math.round(vl[id].startTime)) === (Math.round(newend) - Math.round(newstart))) {
       vl[id].startTime = newstart;
       vl[id].endTime = newend;
 
     }*/

    /* if(vl[id].startTime < vl[id-1].endTime){
     }*/
    /*
        //Change range start
        if ((Math.round(vl[0].startTime) !== newstart) && (Math.round(vl[0].endTime) === newend)) {
          let relativechange = newstart - vl[0].startTime
          if (vl[0].trimmStart += relativechange > 0) {
            vl[0].trimmStart += relativechange
          }
          vl[0].startTime = 0;
          vl[0].endTime = vl[0].endTime - relativechange
    
        }
    
        //Change range end
        if (Math.round(vl[0].endTime) !== newend && (Math.round(vl[0].startTime) == newstart)) {
          if (newend - newstart < vl[0].duration) {
            let relativechange = vl[0].endTime - newend
            if (vl[0].trimmEnd += relativechange > 0) {
              vl[0].trimmEnd += relativechange
              vl[0].endTime = newend
            }
          }
        }*/

    setVideoList(vl);
  }

  const [range, setRange] = useState(null);

  useEffect(() => {
    //if(document.getElementsByClassName("vis-vertical-scroll") != null){
    //scrollTop = document.getElementsByClassName("vis-vertical-scroll")[0].scrollTop;
    //}*/
    /*let timeouttwo = setTimeout(() => {
      if(document.getElementsByClassName("vis-vertical-scroll") != null){
        scrollTop = document.getElementsByClassName("vis-vertical-scroll")[0].scrollTop;
      }
    }, 0);*/

    setRange(timelineref.current.$el.range);
    convertAtomsToDataset();

    if (range != null) {
      timelineref.current.$el.setWindow(range.start, range.end);
    }

    // Restore the saved time and zoom level

    //setZoom(videoList[0].duration)
  }, [
    imageList,
    videoList,
    textList,
    play,
    edit,
    zoom,
    element,
    cursorUpdated,
    audioList,
    timelineref,
  ]);

  useEffect(() => {
    timelineref.current.$el.zoomIn(0);
  }, [zoomIn]);

  useEffect(() => {
    timelineref.current.$el.zoomOut(0);
  }, [zoomOut]);

  useEffect(() => {
    timelineref.current.$el.addCustomTime(0, "currenttime");

    timelineref.current.$el.on("timechange", function (properties) {
      setCursorUpdated((e) => e + 1);
      document.getElementById("editorTimer").currentTime =
        properties.time.getTime() / 1000;
    });

    //let id = properties.items[0]
    //let type = this.itemSet.items[id].data.className
  }, []);

  useEffect(() => {
    timelineref.current.$el.on("select", function (properties) {
      let id = properties.items[0];
      let type = this.itemSet.items[id].data.className;

      setEdit(type);
      if (type === "text") {
        setElement(id);
      }

      if (type === "image") {
        setElement(id - textList.length - videoList.length);
      }

      if (type === "video") {
        // setElement(id - textList.length);
      }
    });
  }, [videoList, imageList, textList]);

  useEffect(() => {
    convertAtomsToDataset();
  }, [videoList]);

  /*useEffect(() => {
    timelineref.current.$el.setCustomTime(time, "currenttime")
  }, [time])*/

  var items = [
    {
      start: new Date(2010, 7, 15),
      end: new Date(2010, 8, 2), // end is optional
      content: "Trajectory A",
      // Optional: fields 'id', 'type', 'group', 'className', 'style'
    },
    // more items...
  ];

  function caluc(date) {
    return "test";
  }

  const ItemTemplate = (props) => (
    <div style={{ paddingLeft: "20px" }}>
      {/* <Box sx={{ width: 50, height: 50, background: "red" }}>HELLO</Box> */}
      {/* {props.item.content === "video" ? (
        <div style={{ display: "flex" }}>
          {videoList[-1].previewImages !== undefined ? (
            videoList[
              props.item.id - textList.length - imageList.length
            ].previewImages.map((src) => (
              <img style={{ height: props.element.height }} src={src} />
            ))
          ) : (
            <h1>Video</h1>
          )}
          <p>Video</p>
        </div>
      ) : (
        props.item.content
      )} */}
    </div>
  );

  useEffect(() => {
    convertAtomsToDataset();
    convertAtomsToDataset();
    convertAtomsToDataset();
  }, []);

  const options = {
    min: 0,
    start: 0,
    verticalScroll: false,
    // end: totalTimeMS?.toFixed(0),
    end: videoList?.[videoList.length - 1]?.duration,
    // end: 15000,
    // max: 20000,
    max: videoList?.[videoList.length - 1]?.duration,
    snap: null,
    width: "100%",
    // height: 200,
    orientation: "top",
    stack: false,
    stackSubgroups: true,
    scroll: false,

    // showMinorLabels: false,
    showMajorLabels: false,
    showCurrentTime: true,
    // zoomMax: totalTimeMS?.toFixed(0),
    zoomMin: videoList?.[videoList.length - 1]?.duration,
    zoomMax: videoList?.[videoList.length - 1]?.duration,

    // type: "background",

    zoomable: false,
    itemsAlwaysDraggable: {
      item: true,
      range: true,
    },
    template: (item, element, data) => {
      // ReactDOM.render(<ItemTemplate item={item} element={element} />, element);
      return <ItemTemplate item={item} element={element} />;
    },

    maxHeight: 200,

    editable: {
      remove: true,
      updateGroup: false,
      updateTime: true,
    },

    onMove: function (item, callback) {
      if (item.className == "image") {
        changestartinatomimage(
          item.id,
          item.start.getTime(),
          item.end.getTime(),
          item.group
        );
      }

      if (item.className == "text") {
        changestartinatomtext(
          item.id,
          item.start.getTime(),
          item.end.getTime(),
          item.group
        );
      }

      if (item.className == "video") {
        changestartinatomvideo(
          item.id,
          item.start.getTime(),
          item.end.getTime()
        );
      }

      if (item.className == "audio") {
        changestartinatomaudio(
          item.id,
          item.start.getTime(),
          item.end.getTime()
        );
      }
    },

    onRemove: function (item, callback) {
      deletetextinatom(item.id);
      deleteimageinatom(item.id);
      deletevideoinatom(item.id);
      deleteaudioinatom(item.id);
    },
  };

  return (
    <div id="timepanel" style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          {/* <Slider waria-label="Volume" value={zoom} onChange={e => changeZoom(e.target.value)} onChangeCommitted={() => resetTimeline()} min={1000} max={100000} style = {{margin:"1.5vh", width:"30%"}}/> */}
        </div>

        <div
          style={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", left: 0, top: "10px" }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: 70,
                  height: 45,
                  backgroundColor: "#16202D",
                }}
              >
                <IconButton size="sm" disabled sx={{ opacity: 0.5 }}>
                  <RiVidiconLine />
                </IconButton>
                {/* <IconButton size="sm" sx={{ opacity: 0.3 }}>
                  <RiEyeOffLine />
                </IconButton> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: 70,
                  height: 45,
                  backgroundColor: "#1A0B2D",
                  marginTop: "7px",
                }}
              >
                <IconButton size="sm" disabled sx={{ opacity: 0.5 }}>
                  <RiMusic2Line />
                </IconButton>
                {/* <IconButton size="sm" sx={{ opacity: 0.3 }}>
                  <RiVolumeMuteLine />
                </IconButton> */}
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: 70,
                  height: 45,
                  backgroundColor: "#1A0B2D",
                  marginTop: "7px",
                }}
              >
                <IconButton size="sm" disabled sx={{ opacity: 0.5 }}>
                  <RiText />
                </IconButton>
                <IconButton size="sm" sx={{ opacity: 0.3 }}>
                  <RiVolumeMuteLine />
                </IconButton>
              </Box> */}
            </div>
          </div>
          <div style={{ overflow: "hidden", paddingLeft: 55 }}>
            <Timeline ref={timelineref} options={options} animation={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePanel;
