import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { ProgressBar } from "react-loader-spinner";
import swal from "sweetalert";
import { CropEasy } from "../components/crop/utils/CropEasy";
import PinIcon from "../images/PinIcon.png";
import addressIcon from "../images/addressIcon.png";
import EmailIcon from "../images/emailIcon.png";
import userIcon from "../images/ic_outline-account-circle.png";
import imgOfImg from "../images/imgOfImg.png";
import CallIcon from "../images/phone-fill.png";
import profileUpdateCover from "../images/profileUpdateCover.jpg";
import userDefaultImage from "../images/userDefaultImage.jpg";
import Header from "./header.component";
import useUserValidate from "../components/Hooks/useUserValidate";

function ProfileUpdate() {
	useUserValidate();
	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [Mobile, setMobile] = useState("");
	const [PinCode, setPinCode] = useState("");
	const [State, setState] = useState("");
	const [Password, setPassword] = useState("");
	const [About, setAbout] = useState("");
	const [Add, setAdd] = useState("");
	const [City, setCity] = useState("");
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [file, setFile] = useState([]);
	const [gender, setGender] = useState("");
	const [uploadBtnDisable, setUploadBtnDisable] = useState(false);
	const [coverFile, setCoverFile] = useState([]);
	const [coverPhoto, setCoverPhoto] = useState("");

	const profileInput = useRef();
	const coverInput = useRef();

	useEffect(() => {
		getData(`${process.env.REACT_APP_BASE_URL}/auth/get-user-details?user_id=` + localStorage.getItem("user_id"), "GET").then(
			(data) => {
				if (data.status == true) {
					// console.log(data)
					setName(data.data.data.full_name);
					setEmail(data.data.data.email);
					setPhotoURL(data.data.data.pic);
					setMobile(data.data.data.mobile_number);
					setAdd(data.data.data.address);
					setCity(data.data.data.city);
					setState(data.data.data.state);
					setPinCode(data.data.data.pincode);
					setPassword(data.data.data.password);
					setAbout(data.data.data.description);
					setGender(data.data.data.gender);
					// console.log(data.data.data)
				} else {
					console("incorrect");
				}
			}
		);
	}, []);

	// console.log(localStorage.getItem("user_id"));
	// console.log(localStorage.getItem("auth_token"));

	async function Update() {
		setUploadBtnDisable(true);
		let formData = new FormData();
		formData.append("pic", file);
		formData.append("full_name", name);
		formData.append("email", email);
		formData.append("mobile_number", Mobile);
		formData.append("address", Add);
		formData.append("city", City);
		formData.append("state", State);
		formData.append("pincode", PinCode);
		formData.append("description", About);
		formData.append("gender", gender);

		let response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/update-user-details/${localStorage.getItem("user_id")}`, {
			method: "PUT",
			body: formData,
			headers: { authorization: localStorage.getItem("auth_token") },
		});
		let result = await response.json();
		console.log(result);
		if (result.status === false) {
			swal(result.message);
			setUploadBtnDisable(false);
		}

		if (result.code == 200) {
			swal("profile updated");
			setUploadBtnDisable(false);
			window.open("/account", "_self");
		}
	}

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
			setPhotoURL(URL.createObjectURL(file));
			setOpenCrop(true);
		}
		e.target.value = null;
	};

	const handleGenderChange = (event) => {
		setGender(event.target.value);
	};

	const handleCoverInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setCoverFile(file);
			setCoverPhoto(URL.createObjectURL(file));
		}
		e.target.value = null;
	};

	const RemoveCoverPhoto = () => {
		console.log("RemoveCoverPhoto");
		// const byteCharacters = atob(userDefaultImage.split(",")[1]);
		// const byteArrays = [];

		// for (let i = 0; i < byteCharacters.length; i++) {
		// 	byteArrays.push(byteCharacters.charCodeAt(i));
		// }

		// const blobData = new Blob([new Uint8Array(byteArrays)], { type: "image/png" });
		// blobData.name = "UserDefaultImage.png";
		setCoverFile([]);
		setCoverPhoto("");
	};


	return (
		<div className="ProfileUpdateUserMain">
			<Header />
			{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
			<div className=" ProfileUpdateUser">
				<div className="coverImage">
					<img
						src={coverPhoto.length == 0 ? profileUpdateCover : coverPhoto}
						alt="profileUpdateCover"
						className="coverImg"
					/>
					<input type="file" ref={coverInput} style={{ display: "none" }} onChange={handleCoverInputChange} />
					<button onClick={RemoveCoverPhoto}>Remove photo</button>
					<div className="changeCoverPhoto" onClick={() => coverInput.current.click()}>
						<img src={imgOfImg} alt="" />
						<p>Change photo</p>
					</div>
				</div>
				<div className="updateFieldsMainContainer">
					<h1>My Profile</h1>
					<div className="updateTopSection">
						<div className="profileImage">
							<img src={photoURL.length == 0 ? userDefaultImage : photoURL} alt="" />
							<input type="file" ref={profileInput} style={{ display: "none" }} onChange={handleFileInputChange} />
						</div>
						<p>Profile Photo</p>
						<div className="changeProfileBtnContainer">
							<button type="button" onClick={() => profileInput.current.click()}>
								Change photo
							</button>
						</div>
					</div>

					<div className="updateBottomSection">
						<div className="updateLeft">
							<div className="groupContainer">
								<div className="inputParent">
									<label htmlFor="name">Name</label>
									<img src={userIcon} alt="" />
									<input
										type="text"
										id="name"
										placeholder="Name"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
							</div>

							<div className="groupContainer">
								<div className="inputParent">
									<label htmlFor="email">Email</label>
									<img src={EmailIcon} alt="" />
									<input
										type="email"
										id="email"
										placeholder="Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>

							<div className="groupContainer">
								<div className="inputParent">
									<label htmlFor="mobile">Mobile Number</label>
									<img src={CallIcon} alt="" />
									<input
										type="text"
										id="mobile"
										placeholder="Mobile Number"
										value={Mobile}
										onChange={(e) => setMobile(e.target.value)}
									/>
								</div>
							</div>

							<div className="groupContainer">
								<div className="inputParent">
									<label htmlFor="address">Address</label>
									<img src={addressIcon} alt="" />
									<input
										type="text"
										id="address"
										placeholder="Address"
										value={Add}
										onChange={(e) => setAdd(e.target.value)}
									/>
								</div>
							</div>

							<div className="groupContainer twoItems">
								<div className="inputParent">
									<label htmlFor="City">City</label>
									<input
										type="text"
										id="City"
										placeholder="City"
										value={City}
										onChange={(e) => setCity(e.target.value)}
									/>
								</div>

								<div className="inputParent rightInput">
									<label htmlFor="State">State</label>
									<input
										type="text"
										id="State"
										placeholder="State"
										value={State}
										onChange={(e) => setState(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className="updateRight">
							<div className="groupContainer">
								<div className="inputParent">
									<label htmlFor="Pin">Pin</label>
									<img src={PinIcon} alt="" />
									<input
										type="text"
										id="Pin"
										placeholder="Pin"
										value={PinCode}
										onChange={(e) => setPinCode(e.target.value)}
									/>
								</div>
							</div>

							<div className="groupContainer AboutYou">
								<div className="inputParent">
									<label htmlFor="About">About You</label>
									<textarea
										id="About"
										placeholder="About You"
										defaultValue={About}
										onChange={(e) => setAbout(e.target.value)}></textarea>
								</div>
							</div>

							<div className="groupContainer twoItems">
								<div className="inputParent">
									<label htmlFor="Gender">Gender</label>
									<input
										type="text"
										id="Gender"
										placeholder="Select Gender"
										value={gender ? gender : "Select Gender"}
										disabled
										onChange={(e) => setPinCode(e.target.value)}
									/>
								</div>
								<div className="labelContainer">
									<label>
										<input
											type="radio"
											value="Male"
											checked={gender === "Male"}
											onChange={handleGenderChange}
										/>
										Male
									</label>
									<label>
										<input
											type="radio"
											value="Female"
											checked={gender === "Female"}
											onChange={handleGenderChange}
										/>
										Female
									</label>
								</div>
							</div>

							<div className="groupContainer">
								<button
									type="button"
									onClick={Update}
									className="uploadProfileBtn"
									disabled={uploadBtnDisable}
									style={{ background: uploadBtnDisable ? "#3200fc" : "" }}>
									{uploadBtnDisable ? "" : "Update"}
									<ProgressBar
										visible={uploadBtnDisable}
										height="80"
										width="80"
										ariaLabel="progress-bar-loading"
										wrapperStyle={{}}
										wrapperClass="progress-bar-wrapper"
										borderColor="#F4442E"
										barColor="#51E5FF"
									/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ProfileUpdate;
