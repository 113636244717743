import React, { useEffect, useState, useRef, createContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./Sidebar";
import TimePanel from "./TimePanel";
import VideoView from "./VideoView";
import Editor from "./Editor";

import ControlBar from "./ControlBar";

import {
  audioListState,
  canvasState,
  editState,
  elementState,
  imageListState,
  videoListState,
} from "../atoms";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { cloneDeep } from "lodash";
import { AspectRatio } from "@mui/joy";
import { useMediaQuery } from "@material-ui/core";

function VideoEditorWrapper({ videoFile = null }) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const isMD = useMediaQuery("(max-width:1200px)");

  const grid = {
    display: "grid",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",

    gridTemplateColumns: isMobile ? "0% 100%" : "20% 80%",
    gridTemplateRows: isMD
      ? "20% 20% 10% 8% 10%"
      : isMobile
      ? "20% 20% 0% 8% 0%"
      : "20% 20% 25% 8% 10%",
    gridTemplateAreas: `"editor video " 
                        "editor video " 
                        "editor video " 
                         "controlbar controlbar"
                        "timeline timeline "`,
  };

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [imageList, setImageList] = useRecoilState(imageListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);
  const [edit, setEdit] = useRecoilState(editState);
  const [element, setElement] = useRecoilState(elementState);

  const [canvas, setCanvas] = useRecoilState(canvasState);

  async function setVideo(e) {
    var video = document.createElement("video");
    video.src = URL.createObjectURL(e);

    function setDuration() {
      let duration = video.duration * 1000;

      let vl = cloneDeep(videoList);

      let starttime = 0;
      if (videoList.length > 0) {
        starttime = videoList[videoList.length - 1].endTime;
      }

      let c = cloneDeep(canvas);
      console.log(c, video, "VIDEO ANDCANVAS");
      if (c.width < video.videoWidth) {
        c.width = video.videoWidth;
      }
      if (c.height < video.videoHeight) {
        c.height = video.videoWidth * (9 / 16);
      }

      setCanvas(c);

      vl.push({
        id: videoList.length,
        src: URL.createObjectURL(e),
        file: e,
        duration: duration,
        x: null,
        y: null,

        startTime: starttime,
        endTime: starttime + duration,
        trimmStart: 0.0,
        trimmEnd: 0.0,
      });

      setVideoList(vl);
      setEdit("video");
      setElement(videoList.length);
    }
    video.addEventListener("loadedmetadata", setDuration);
  }

  useEffect(() => {
    if (videoFile) {
      setVideo(videoFile);
    }
  }, [videoFile]);

  /*
    useEffect(() => {
      let intervalId = null
      if (play) {
        intervalId = setInterval(() => {
         l+=20;
        }, 20);
      }
      return () => clearInterval(intervalId);
    }, [play]);*/

  return (
    <div style={grid}>
      <div
        style={{
          gridArea: "video",
          overflow: "auto",
          margin: "0px",
          marginLeft: "5px",
        }}
      >
        {/* <AspectRatio ratio={16 / 9} sx={{ width: "100%", height: "100%" }}> */}
        <VideoView />
        {/* </AspectRatio> */}
      </div>

      <div style={{ gridArea: "controlbar", paddingLeft: "5px" }}>
        <ControlBar />
      </div>

      <div style={{ gridArea: "timeline", margin: "5px" }}>
        <TimePanel />
      </div>

      <div style={{ gridArea: "editor", overflow: "hidden", margin: "0px" }}>
        <Editor />
      </div>
    </div>
  );
}

export default VideoEditorWrapper;
