import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../Redux/slices/AuthSlice";

function HeaderProfessional({
  show,
  setShow,
  show2,
  setShow2,
  isVodcast = false,
  seenNotification
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!show) {
    return null;
  } else
    return (
      <>
        <div className="responsive-popup2">
          <button className="close-popup " onClick={() => setShow(false)}>
            X
          </button>

          <div className="responsive-popup-box2 ">
            {!isVodcast && (
              <button
                className="responsive-threeDotBox"
                onClick={() => {
                  setShow(false);
                  navigate("/vodcast");
                }}
              >
                <p>Home</p>
              </button>
            )}
            {/* {isVodcast && <button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-home");
							}}>
							<p>Home</p>
						</button>} */}
            <button
              className="responsive-threeDotBox flex gap-6 justify-start items-center"
              onClick={() => {
                setShow(false);
                setShow2(true);
              }}
            >
              <p>Notification</p>
              <div className="notification-dot text-lg bg-red-600 w-4 h-4 px-3 py-3 flex justify-center align-middle items-center rounded-full text-white">
                {seenNotification.length}
              </div>
            </button>

            {/* <button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-wallet");
							}}>
							<p>Wallet</p>
						</button> */}

            <button
              className="responsive-threeDotBox"
              onClick={() => {
                setShow(false);
                navigate("/professional-account");
              }}
            >
              <div className="display-flex">
                <p>My Profile</p>
              </div>
            </button>

            {/* <button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-MyClasses");
							}}>
							<div className="display-flex">
								<p>My Class</p>
							</div>
						</button> */}

            {/* <button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-ActiveHours");
							}}>
							<div className="display-flex">
								<p>Active Hours </p>
							</div>
						</button> */}
            {/* 
						<button className="responsive-threeDotBox">
							<p>Chat</p>
						</button> */}

            {/* <button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-support");
							}}>
							<div className="display-flex">
								<p>Support & Help</p>
							</div>
						</button> */}

            <button
              className="responsive-threeDotBox"
              onClick={() => {
                setShow(false);
                navigate("/professional-privacy");
              }}
            >
              <div className="display-flex">
                <p>Privacy Policy</p>
              </div>
            </button>

            <button
              className="responsive-threeDotBox"
              onClick={() => {
                setShow(false);
                navigate("/professional-aboutUs");
              }}
            >
              <div className="display-flex">
                <p>About Us</p>
              </div>
            </button>
            <button
              className="responsive-threeDotBox"
              style={{ backgroundColor: "#FF000070" }}
              onClick={() => {
                setShow(false);
                dispatch(logout());
                navigate("/login");
              }}
            >
              <div className="display-flex">
                <p>Logout</p>
              </div>
            </button>
          </div>
        </div>
      </>
    );
}
export default HeaderProfessional;
