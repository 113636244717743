import { extendTheme } from "@mui/joy";
import constants from "./constants";

const theme = extendTheme({
  components: {
    JoyTooltip: {
      styleOverrides: {
        root: {
          zIndex: 9999999,
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        listbox: { zIndex: 99999 },
        root: {
          minHeight: 40,
          background: "#242424",
          // border: "1px #414040",
          boxShadow: "0.5px",
          color: "#ffffff80",
          marginBottom: 5,
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          minHeight: 80,
          background: "#242424",
          // border: "1px #414040",
          boxShadow: "0.5px",
          color: "#ffffff80",
          marginBottom: 5,
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          minHeight: 40,
          background: "#242424",
          // border: "1px #414040",
          boxShadow: "0.5px",
          color: "#ffffff80",
          marginBottom: 5,
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          minHeight: 50,
          ":hover": {
            color: "#fff",
            backgroundColor: constants.colors.primary,
          },
        },
      },
    },
    JoySkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;
