import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  CssVarsProvider,
  Grid,
  IconButton,
  Input,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos } from "../../services/pexels";
import {
  RiArrowLeftLine,
  RiBox1Line,
  RiLineChartLine,
  RiSearchLine,
  RiUpload2Line,
  RiUploadLine,
  RiVideoLine,
} from "react-icons/ri";
import VideoThumbnailCard from "../components/VideoThumbnailCard";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { humanizeString } from "../../utils/humanizeString";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../utils/httpMethods";
import { useSelector } from "react-redux";

function VodcastSearchPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [videosData, setVideosData] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [totalCount, setTotalCount] = useState(-1);
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);

  const [activeCategory, setActiveCategory] = useState("Music Learning");

  const { user } = useSelector((state) => state.auth);

  const isPro = user?.user_type === "professional";

  const [loadingMore, setLoadingMore] = useState(false);
  const [title, setTitle] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = () => {
    setLoading(true);
    getData(
      `${process.env.REACT_APP_BASE_URL
      }/vodcast/search?value=${searchParams.get("value")}`,
      "GET"
    ).then((res) => {
      setLoading(false);
      if (res.status == true) {
        setData(res?.data);
      } else {
        console.log("incorrect");
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [searchParams.get("value"), page]);

  const loadMore = () => {
    // if (totalCount > (page - 1) * 10) {
    //   return;
    // }
    // !loading && setPage((p) => p + 1);
  };

  return (
    <CommonLayout showUpload={showUpload} setShowUpload={setShowUpload}>
      <InfiniteScroll
        dataLength={Object.values(data)?.flat().length}
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <Grid
          container
          lg={12}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Grid xs={6} sm={6} md={6} lg={8}>
            <Stack direction={"row"}>
              <IconButton
                onClick={() => navigate("/vodcast")}
                sx={{ ":hover": { background: "transparent" } }}
              >
                <RiArrowLeftLine size={30} />
              </IconButton>
              <Breadcrumbs>
                <Link
                  fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}
                  color="neutral"
                  href="/vodcast"
                >
                  Home
                </Link>
              </Breadcrumbs>
            </Stack>
            <BigTitle shimmer={loading}>{"Search"}</BigTitle>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={4}
            sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}
          >
            <Skeleton
              variant="inline"
              sx={{ borderRadius: 30, my: { xs: 1 } }}
              loading={loading}
            >
              <Grid xs={12} sm={6} md={6} lg={6}>
                <Input
                  defaultValue={searchParams.get("value")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value !== "") {
                        setSearchParams({ value: e.target.value });
                      }
                      console.log(e);
                    }
                  }}
                  variant="outlined"
                  placeholder="Search"
                  sx={{
                    borderRadius: { xs: 16, sm: 16 },
                    background: "transparent",
                    borderColor: "#565656",
                    color: "#fff",
                    fontSize: "sm",
                    "--Input-placeholderOpacity": 0.2,
                  }}
                  startDecorator={<RiSearchLine />}
                />
              </Grid>
            </Skeleton>
          </Grid>
        </Grid>
        <Grid xs={12}></Grid>

        <Grid
          container
          lg={12}
          marginY={3}
          alignItems="center"
          sx={{ overflow: "hidden", overflowX: "scroll" }}
        // paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          {!loading && data && Object.values(data)?.flat().length === 0 && (
            <Stack
              sx={{
                opacity: 0.5,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                py: 5,
              }}
            >
              <RiVideoLine size={"10%"} />
              <Typography>No Data</Typography>
            </Stack>
          )}
          <Stack
            direction="row"
            spacing={2}
            paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
            sx={{}}
          >
            {loading &&
              [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                <Skeleton key={index} variant="inline" loading={loading}>
                  <VideoThumbnailCard data={item} key={index} />
                </Skeleton>
              ))}
            {!loading &&
              data &&
              Object.values(data)
                ?.flat()
                .map((item, index) => (
                  <VideoThumbnailCard data={item} key={index} />
                ))}
          </Stack>
        </Grid>

        <PaddingContainer>
          <Grid container spacing={2}>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard shimmer={loading} data={item} key={index} />
              ))}
            {!loading &&
              data &&
              Object.values(data)
                ?.flat()
                ?.map((item, index) => (
                  <VideoListCard data={item} key={index} />
                ))}
          </Grid>
        </PaddingContainer>
        {totalCount == 0 && (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              py: 4,
            }}
          >
            <RiVideoLine size={80} style={{ opacity: 0.3 }} />
            <Typography level="title-lg" sx={{ color: "#FFFFFF4A", mt: 5 }}>
              NO RELATED VODCAST FOUND
            </Typography>
          </Container>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
          }}
        >
          {loadingMore ? (
            <CircularProgress variant="soft" />
          ) : (
            Object.values(data)?.flat().length < totalCount && (
              <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                END OF CONTENT
              </Typography>
            )
          )}
        </Container>
      </InfiniteScroll>
    </CommonLayout>
  );
}

export default VodcastSearchPage;
