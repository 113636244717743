import { Button, IconButton, Skeleton } from "@mui/joy";
import React from "react";
import constants from "../../configs/constants";
import { useMediaQuery } from "@material-ui/core";
import { isMobile } from "react-device-detect";

function ActionButton({
  title,
  shimmer = false,
  sx,
  showIconForMobile,
  ...props
}) {
  const matches = useMediaQuery("(max-width:500px)");
  if (matches && showIconForMobile) {
    return (
      <Skeleton variant="inline" loading={shimmer}>
        <IconButton
          sx={{
            borderRadius: 5,
            backgroundColor: constants.colors.primary,
            ":hover": { backgroundColor: constants.colors.primary },
            ...sx,
          }}
          {...props}
        >
          {props.startDecorator}
        </IconButton>
      </Skeleton>
    );
  }

  return (
    <Skeleton variant="inline" loading={shimmer}>
      <Button
        sx={{
          fontSize: { xs: 10, sm: 12, md: 14, lg: 14 },
          borderRadius: 5,
          backgroundColor: constants.colors.primary,
          ":hover": { backgroundColor: constants.colors.primary },
          ...sx,
        }}
        {...props}
      >
        {title}
      </Button>
    </Skeleton>
  );
}

export default ActionButton;
