import React, { useEffect, useRef, useState } from "react";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const Player = () => {
	const { currentSelectedSong } = useSelector((state) => state.albumSlice);
	const audioRef = useRef(null);
	const barRef = useRef();
	const [palyState, setPalyState] = useState(true);
	const [time, setTime] = useState({ h: 0, m: 0, s: 0, dh: 0, dm: 0, ds: 0 });
	const [progress, setProgress] = useState(0);
	const [ct, setCt] = useState(0);
	const [duration, setDuration] = useState(0);
	const [pauseTime, setPauseTime] = useState(0)

	useEffect(() => {
		setPalyState(true);
	},[currentSelectedSong])

	const onPlaying = () => {
		const duration = Math.round(audioRef.current.duration);
		const currentTime = Math.round(audioRef.current.currentTime);
		setDuration(duration);
		setCt(currentTime);

		const dTotalMinutes = Math.floor(duration / 60);
		let ds = duration % 60;
		let dm = dTotalMinutes % 60;
		let dh = Math.floor(dTotalMinutes / 60);

		const totalMinutes = Math.floor(currentTime / 60);
		let s = currentTime % 60;
		let m = totalMinutes % 60;
		let h = Math.floor(totalMinutes / 60);

		setTime({ h, m, s, dh, dm, ds });
		setProgress((currentTime / duration) * 100);
	};

	const handelSkip = (e) => {
		let width = barRef.current.clientWidth;
		const offset = e.nativeEvent.offsetX;

		const divProgress = (offset / width) * 100;
		audioRef.current.currentTime = (divProgress / 100) * duration;
	};

	const handelEnd = () => {
		setPalyState(false);
		setPauseTime(0);
	};

	return (
		<>
			{currentSelectedSong && (
				<div className="ThirdContainer">
					<div className="musicBar" onClick={handelSkip} ref={barRef}>
						<div className="playerProgress" style={{ width: progress + "%" }} />
						<div className="musicBarLeft">
							<div className="musicBarLeftImg">
								<img
									src={currentSelectedSong?.album_id?.cover_photo}
									alt="thumbnail"
									id={palyState ? "thumbnailPic" : ""}
								/>
							</div>
							<div className="musicBarLeftText">
								<h5>{currentSelectedSong?.title}</h5>
								<p>by {currentSelectedSong?.created_by?.full_name}</p>
							</div>
						</div>
						{palyState && (
							<div className="musicBarMiddle">
								<p>{`${time.h != 0 ? time.h + ":" : ""}${time.m}:${time.s}`}</p>
								<p>/</p>
								<p>{`${time.dh != 0 ? time.dh + ":" : ""}${time.dm}:${time.ds}`}</p>
							</div>
						)}
						<div className="musicBarRight">
							{/* <div><img src={paly1} alt="" /></div> */}
							<div className="palyPause">
								{palyState ? (
									<BsPauseFill
										size={26}
										onClick={(e) => {
											e.stopPropagation();
											audioRef.current.pause();
											setPalyState(false);
											setPauseTime(ct);
										}}
									/>
								) : (
									<BsFillPlayFill
										size={26}
										onClick={(e) => {
											e.stopPropagation();
											setPalyState(true);
											if (pauseTime > 0) audioRef.current.currentTime = pauseTime;
											else audioRef.current.play();
										}}
									/>
								)}
								<audio
									autoPlay
									ref={audioRef}
									src={palyState ? currentSelectedSong?.file : ""}
									onEnded={handelEnd}
									onTimeUpdate={onPlaying}></audio>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Player;
