// import { Slider, Stack, Typography } from "@mui/joy";
// import moment from "moment";
// import React, { useState } from "react";

// function Seekbar({ duration = 0, currentTime = 0, onCurrentTimeChange }) {
//   // const [currentTime, setCurrentTime] = useState(0);
//   function millisecondsToTime(s) {
//     var ms = s % 1000;
//     s = (s - ms) / 1000;
//     var secs = s % 60;
//     s = (s - secs) / 60;
//     var mins = s % 60;
//     var hrs = (s - mins) / 60;

//     return hrs + ":" + mins + ":" + secs;
//   }
//   console.log(currentTime, duration);
//   return (
//     <Stack
//       direction="row"
//       sx={{
//         justifyContent: "space-between",
//         gap: 0.5,
//         alignItems: "center",
//         paddingX: 0.5,
//       }}
//     >
//       <Typography level="body-xs">
//         {moment.utc(currentTime).format("mm:ss")}
//       </Typography>
//       <Slider
//         sx={{ py: 0 }}
//         onChange={(e) =>
//           onCurrentTimeChange && onCurrentTimeChange(e.target.value)
//         }
//         value={(currentTime / duration) * 100}
//         // defaultValue={50}
//         slotProps={{
//           thumb: { style: { display: "none" } },
//           track: {
//             sx: {
//               background: "#7544FF",
//             },
//           },
//         }}
//       />
//       <Typography level="body-xs">
//         {moment.utc(duration).format("mm:ss")}
//       </Typography>
//     </Stack>
//   );
// }

// export default Seekbar;

import { Slider, Stack, Typography } from "@mui/joy";
import moment from "moment";
import React, { useState } from "react";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";

function Seekbar({
  duration = 0,
  currentTime = 0,
  onCurrentTimeChange,
  playerRef,
}) {
  function millisecondsToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs;
  }

  const rewindHandler = () => {
    // Rewinds the video player reducing 5
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };

  const fastForwardHandler = () => {
    // FastForwards the video player by adding 10
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        gap: 0.5,
        alignItems: "center",
        paddingX: 0.5,
      }}
    >
      <div className="icon__btn" onClick={rewindHandler}>
        <IoIosSkipBackward fontSize="medium" />
      </div>
      <Typography level="body-xs">
        {moment.utc(currentTime).format("mm:ss")}
      </Typography>
      <Slider
        sx={{ py: 0 }}
        onChange={(e) =>
          onCurrentTimeChange && onCurrentTimeChange(e.target.value)
        }
        value={(currentTime / duration) * 100}
        slotProps={{
          thumb: { style: { display: "none" } },
          track: {
            sx: {
              background: "#7544FF",
            },
          },
        }}
      />
      <Typography level="body-xs">
        {moment.utc(duration).format("mm:ss")}
      </Typography>
      <div className="icon__btn" onClick={fastForwardHandler}>
        <IoIosSkipForward fontSize="medium" />
      </div>
    </Stack>
  );
}

export default Seekbar;
