import React, { useEffect, useState } from "react";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

import { editState, imageListState, elementState } from "../atoms";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { createClient } from "pexels";

import CustomModal from "./CustomModal";

function ImageEditor(props) {
  const [imageList, setImageList] = useRecoilState(imageListState);
  const [element, setElement] = useRecoilState(elementState);
  const [edit, setEdit] = useRecoilState(editState);
  const [isRemoving, setIsRemoving] = useState(false);
  const [photoList, setPhotoList] = useState(0);
  const [query, setQuery] = useState("animals");
  const client = createClient(
    "563492ad6f9170000100000182262b7b341e4afb906168f00ead247c"
  );
  const [loadImage, setLoadImage] = useState(false);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  function changeImage(newImg) {
    let newList = [...imageList].map((item) => {
      let newData = { ...item };
      if (item.id === element) {
        newData.src = newImg;
        return newData;
      } else return item;
    });
    setImageList(newList);
  }

  async function removeBackground() {
    setIsRemoving(true);
    let blob = await fetch(imageList[element].src).then((r) => r.blob());
    var formData = new FormData();
    formData.append("file", blob, "image.jpg");
    axios
      .post(BASE_URL + "/rbimg", formData, { responseType: "blob" })
      .then((response) => {
        changeImage(URL.createObjectURL(response.data));
        setIsRemoving(false);
      });
  }

  function closeModal() {
    setIsRemoving(false);
  }

  return (
    <div style={{ padding: "1vh" }}>
      <CustomModal
        isOpen={isRemoving}
        onRequestClose={closeModal}
        title="Removing Image Background"
        message="Unlike other platforms, we provide this service completely free of charge. If you appreciate our service, consider sharing our website on social media!"
        estimatedTime={5}
      />
      {imageList[element] != undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ maxWidth: "80%" }} src={imageList[element].src}></img>
          <Button onClick={() => removeBackground()} variant="primary">
            {<div>Remove Background</div>}
          </Button>{" "}
        </div>
      ) : null}
    </div>
  );
}

export default ImageEditor;
