import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Checkbox, Grid, Input, Stack, Typography } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import LogoComponent from "../AltLandingPage/components/LogoComponent";
import { GrGoogle } from "react-icons/gr";
import LoadingModal from "../components/LoadingModal";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RiEyeLine, RiEyeOffLine, RiArrowLeftLine } from "react-icons/ri";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";


function ForgetPasswordOTPPage() {
	useValidateWithoutLogin();

	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [otp, setOTP] = useState("");


	async function postdata(url = "", data = {}, methods = "") {
		// Default options are marked with *
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	function forgotPasswordHandler() {

		if (otp == "")
			return toast.warn("Please Enter OTP");

		setLoader(true);

		let data = {
			email: localStorage.getItem('forgot-email'),
			otp: otp,
		};

		postdata(`${process.env.REACT_APP_BASE_URL}/auth/verify-otp`, data, "POST").then(
			(data) => {
				if (data.status == true) {
					setLoader(false);
					localStorage.setItem("forgot-otp", otp);
					window.open("/changeForgotPassword", "_self");
				} else {
					setLoader(false);
					toast.error(data.message);
				}
			}
		);
	}

	return (
		<Grid
			container
			sx={{ background: "#09041C", height: "100%", width: "100%" }}
		>
			<LoadingModal open={loader} />
			<Grid md={4} xs={0} sm={0} lg={6} sx={{ height: "100%" }}>
				<img
					src="/auth_image.png"
					style={{ width: "100%", height: "100%", objectFit: "cover" }}
				/>
			</Grid>
			<Grid
				container
				md={8}
				lg={6}
				sm={12}
				xs={12}
				sx={{
					height: "100%",
					px: { xs: 2, sm: 4, md: 8 },
					py: { xs: 2, sm: 3, md: 3 },
					overflowX: "hidden",
					overflowY: "scroll",
				}}
			>
				<Grid xs={12} md={10}>
					<Stack sx={{ height: "100%" }}>
						<LogoComponent />
						<Stack
							sx={{ flex: 1, justifyContent: "center" }}
							spacing={{ xs: 4, md: 4 }}
						>
							<Stack>
								<Typography
									textColor={"#fff"}
									sx={{
										fontFamily: "Poppins",
										fontWeight: 500,
										fontSize: { xs: "16px", md: "20px" },
									}}
								>
									VERIFY OTP
								</Typography>
								<Typography
									textColor={"#fff"}
									sx={{
										py: { xs: 2, md: 3 },
										fontFamily: "Poppins",
										fontWeight: 300,
										fontSize: { xs: "30px", md: "40px" },
										lineHeight: { xs: "30px", md: "40px" },
									}}
								>
									OTP sent to {localStorage.getItem('forgot-email')}
								</Typography>
							</Stack>
							<Stack spacing={2}>
								<Input
									onChange={(e) => setOTP(e.target.value)}
									value={otp}
									size="lg"
									type="number"
									placeholder="Enter your OTP"
									variant="outlined"
									slotProps={{
										input: {
											sx: {
												"&:-webkit-autofill": {
													WebkitBoxShadow: "0 0 0 1000px #09041C inset",
													WebkitTextFillColor: "#fff",
												},
											},
										},
									}}
									sx={{
										background: "transparent",
										borderColor: "#fff",
										color: "#fff",
										fontSize: { xs: 12, md: 14, lg: 16 },
									}}
								/>

								<Stack spacing={1.5}>
									<Button
										onClick={forgotPasswordHandler}
										color="neutral"
										sx={{
											background: "#471CF1",
											borderRadius: "10px",
											fontSize: { xs: 14 },
										}}
										size="lg"
									>
										VERIFY OTP
									</Button>

								</Stack>
							</Stack>
							<Typography
								color="neutral"
								sx={{ fontSize: { xs: 14, md: 12 }, textAlign: "center" }}
							>

								<Button
									onClick={() => {
										navigate("/login");
									}}
									/* slotProps={{
									  root: {
										sx: {
										  padding: 0,
										  paddingLeft: "5px",
										  margin: 0,
										  minHeight: 0,
										  color: "#FFFFFFdd",
										  ":hover": {
											background: "transparent",
											color: "#ffffff90",
										  },
										},
									  },
									}} */
									sx={{
										padding: 0,
										paddingLeft: "5px",
										margin: 0,
										minHeight: 0,
										color: "#FFFFFFdd",
										":hover": {
											background: "transparent",
											color: "#ffffff90",
										},
										"&.MuiButton-root": {  // This targets the root class of the Button component
											// Additional styles for the root class if needed
										},
									}}
									variant="plain"
								>
									<RiArrowLeftLine size={18} />
									Back to Login
								</Button>
							</Typography>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Grid >
	);
}

export default ForgetPasswordOTPPage;
