import React from "react";
import Style from "../style/third.module.css";
import { Link } from "react-router-dom";

function ThirdSection({ gotoDownload }) {
  return (
    <div
      className="w-full sm:h-screen h-auto flex justify-center align-middle "
      style={{ marginBottom: "10%" }}
    >
      <div className="grid sm:grid-rows-10 grid-rows-1 sm:gap-0 gap-14 px-1 md:px-20">
        <div className="h-auto sm:px-0 px-6 flex flex-col gap-1 pt-8 sm:flex-nowrap flex-wrap">
          <p className="text-2lg uppercase font-medium tracking-wide">
            About listener
          </p>
          <h1 className="text-4xl font-semibold pt-2">
            Your artistic skills are set to flourish <br /> with our creative
            features
          </h1>
          <div className="flex gap-6 pt-6">
            <Link
              className={Style.NavButtonA}
              target="_self"
              to="/login"
              rel="noopener noreferrer"
            >
              Get Started
            </Link>
            <div className={Style.NavButton} onClick={gotoDownload}>
              Download
            </div>

            {/* <Link className={Style.NavButton} to={'https://play.google.com/store/apps/details?id=com.music.book&pcampaignid=web_share'}>Download</Link> */}
          </div>
        </div>
        <div className="w-full flex justify-evenly pt-6 sm:gap-12 gap-4 align-middle sm:flex-nowrap flex-wrap">
          <div className="flex flex-col gap-6">
            <p>
              Access to the large music lovers
              <br />
              community
            </p>
            <p>
              Collaborate with other <br /> musicians worldwide
            </p>
          </div>
          <div style={{}}>
            <img
              src={"/personal_img1.png"}
              style={{ width: 350 }}
              width={400}
              height={120}
              alt="Personal"
            ></img>
          </div>
          <div className="flex flex-col gap-6">
            <p>
              Learn various new <br /> instruments
            </p>
            <p>
              Practice & Master your <br /> artistry skills
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
