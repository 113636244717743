import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import CrossIcon from "../../images/CrossIcon.png";

const EditClass = ({ setShowEditClass, setReloadUseEffect, classId }) => {
	const [ImageFile, setImageFile] = useState(null);
	const [imageURL, setImageURL] = useState("");
	const [Title, setTitle] = useState("");
	const [Description, setDescription] = useState("");
	const [Tag, setTag] = useState("");
	const [courseFee, setCourseFee] = useState("")
	const [CourseBtn, setCourseBtn] = useState(false);
	const [uploadPer, setUploadPer] = useState(0);
	const imageRef = useRef();

	useEffect(() => {
		axios
			.get("class/getOneClass/" + classId)
			.then(({ data }) => {
				if (data.status == true) {
					setImageURL(data.data.coverpic);
					setTitle(data.data.courseTitle);
					setDescription(data.data.courseDescription);
					setTag(data.data.tag);
					setCourseFee(data.data.courseFees);
				} else {
					setCourseBtn(false);
				}
			})
			.catch((error) => {
				setCourseBtn(false);
				console.log("error =: ", error);
			});
	}, []);

	const handelCreateClass = () => {
		setCourseBtn(true);
		const id = toast.loading("Please wait...");

		const formData = new FormData();
		formData.append("courseTitle", Title);
		formData.append("class_id", classId);
		formData.append("tag", Tag);
		formData.append("courseDescription", Description);
		formData.append("coverpic", ImageFile);
		formData.append("courseFees", courseFee);

		axios
			.put("/class/editClass", formData, {
				onUploadProgress: (data) => {
					setUploadPer(Math.round((data.loaded / data.total) * 100));
				},
			})
			.then(({ data }) => {
				console.log(data);
				if (data.status === true) {
					toast.update(id, {
						render: "Class Updated Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
					setCourseBtn(false);
					setUploadPer(0);
					setShowEditClass(false);
					setReloadUseEffect(Math.random());
				} else {
					toast.update(id, {
						render: "Something wrong !!",
						type: "error",
						isLoading: false,
						autoClose: 2000,
					});
					setCourseBtn(false);
				}
			})
			.catch((error) => {
				toast.update(id, { render: error, type: "error", isLoading: false, autoClose: 2000 });
				setCourseBtn(false);
				console.log("error =: ", error);
			});
	};

	return (
		<div className="CreateClassContainer">
			<div className="CreateClassMain">
				<div className="CreateClassTitle">
					<h1>Create Class</h1>
					<h4>Please fill all the required fields to create class</h4>
					<button onClick={() => setShowEditClass(false)}>
						<img src={CrossIcon} alt="" />
					</button>
				</div>
				<div className="ContentFields c1" onClick={() => (CourseBtn ? null : imageRef.current.click())}>
					{imageURL ? <img src={imageURL} alt="" /> : <h5>Upload Cover</h5>}
					<input
						type="file"
						accept="image/*"
						ref={imageRef}
						readOnly={CourseBtn}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							setImageFile(file);
							setImageURL(URL.createObjectURL(file));
						}}
					/>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="Course Title"
						value={Title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
				<div className="ContentFields c2">
					<textarea
						readOnly={CourseBtn}
						value={Description}
						placeholder="Description"
						onChange={(e) => setDescription(e.target.value)}></textarea>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="Tag"
						value={Tag}
						onChange={(e) => setTag(e.target.value)}
					/>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="course Fee"
						value={courseFee}
						onChange={(e) => setCourseFee(e.target.value)}
					/>
				</div>
				<button type="button" disabled={CourseBtn} className="CourseBtn" onClick={handelCreateClass}>
					<span style={{ width: `${uploadPer}%` }}></span>
					<p>{uploadPer != 0 ? `${uploadPer}%` : "Edit Class"}</p>
				</button>
			</div>
		</div>
	);
};

export default EditClass;
