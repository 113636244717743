import { configureStore } from "@reduxjs/toolkit";
import { albumApi } from "./api/albumApi";
import { authApi } from "./api/authApi";
import AlbumSlice from "./slices/AlbumSlice";
import AuthSlice from "./slices/AuthSlice";
import TempSlice from "./slices/TempSlice";

const store = configureStore({
	reducer: {
		albumSlice: AlbumSlice,
		auth: AuthSlice,
		temp:TempSlice,
		[albumApi.reducerPath]: albumApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([albumApi.middleware, authApi.middleware]),
});

export default store;
