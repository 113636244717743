import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import threeDots from "../images/3dot.png";
// import logo from "../images/MusicBookLogo.png";
import logo2 from "../images/Logomb.png";
import HeaderProfessional from "./hederPopup.component";
import Notification from "./ProfessionalNotification";
import { Box } from "@mui/joy";

import { useSelector } from "react-redux";
import axios from "../components/Hooks/axios";

function Header({ styles, isVodcast, sidebar = true }) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const { userId } = useSelector((state) => state.auth);
  const [seenNotification, setSeenNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (userId === "") return;
    setIsLoading(true);
    axios
      .get(`/notificationUser/notifications/unseen/${userId}`)
      .then(({ data }) => {
        if (data.status) {
          setSeenNotification(data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, [userId, reload]);

  return (
    <>
      <div
        className={
          styles
            ? "text-white professional-header"
            : "bg-black text-white professional-header"
        }
        style={styles}
      >
        <HeaderProfessional
          show={show}
          setShow={setShow}
          show2={show2}
          setShow2={setShow2}
          isVodcast={isVodcast}
          seenNotification={seenNotification}
        />
        {show2 && <Notification setShow2={setShow2} />}
        <div
          className="display-flex"
          onClick={() => window.open("/vodcast", "_self")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={logo2}
            alt=""
            style={{
              borderRadius: "50%",
              height: "50px",
              width: "50px",
              // boxShadow: "0 0 10px 3px rgba(255,255,255,.3)",
            }}
          />
          <div>
            <h5>Music book</h5>
            <p>Live every beat of your life</p>
          </div>
        </div>
        {sidebar && (
          <Box
            className="relative"
            sx={{
              width: { xs: 15, sm: 15, md: 25, lg: 30 },
              marginRight: { xs: 1, sm: 1, md: 0 },
            }}
          >
            <img
              // className="three-dot-img"
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
              src={threeDots}
              onClick={() => setShow(true)}
              alt="menu"
              // style={{ cursor: "pointer" }}
            />

            {/* <div className="notification-dot text-sm bg-red-600 w-6 h-6 px-2 py-2 flex justify-center align-middle items-center rounded-full text-white absolute top-0 left-3 z-10">
            // {seenNotification.}
          </div> */}
          </Box>
        )}
      </div>
    </>
  );
}
export default Header;
