import axios from "axios";

const Instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// baseURL: "http://3.6.9.110/node/api/v1",
	headers: {
		authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
	},
});

export default Instance;
