import React from "react";

const PlayIcon = () => {
	return (
		<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.8691 37.2148C8.80379 37.2148 0.644775 29.0557 0.644775 18.9905C0.644775 8.92518 8.80379 0.766159 18.8691 0.766159C28.9344 0.766159 37.0934 8.92518 37.0934 18.9905C37.0934 29.0557 28.9344 37.2148 18.8691 37.2148ZM18.8691 33.5699C22.7358 33.5699 26.4441 32.0339 29.1783 29.2997C31.9125 26.5655 33.4485 22.8572 33.4485 18.9905C33.4485 15.1237 31.9125 11.4154 29.1783 8.68124C26.4441 5.94706 22.7358 4.41102 18.8691 4.41102C15.0024 4.41102 11.294 5.94706 8.55985 8.68124C5.82568 11.4154 4.28964 15.1237 4.28964 18.9905C4.28964 22.8572 5.82568 26.5655 8.55985 29.2997C11.294 32.0339 15.0024 33.5699 18.8691 33.5699ZM16.3578 12.457L25.2494 18.3836C25.3494 18.4501 25.4314 18.5404 25.4881 18.6462C25.5448 18.7521 25.5745 18.8703 25.5745 18.9905C25.5745 19.1106 25.5448 19.2288 25.4881 19.3347C25.4314 19.4406 25.3494 19.5308 25.2494 19.5973L16.3559 25.5239C16.2463 25.5966 16.119 25.6382 15.9876 25.6444C15.8562 25.6506 15.7255 25.6212 15.6095 25.5591C15.4935 25.4971 15.3964 25.4048 15.3286 25.2921C15.2607 25.1794 15.2247 25.0504 15.2242 24.9188V13.0621C15.2245 12.9302 15.2605 12.8009 15.3284 12.6879C15.3963 12.5749 15.4936 12.4824 15.6099 12.4203C15.7262 12.3583 15.8572 12.3289 15.9889 12.3353C16.1206 12.3418 16.2481 12.3839 16.3578 12.457Z"
				fill="#ECECEC"
			/>
		</svg>
	);
};

export default PlayIcon;
