import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useHomeValidate = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (localStorage.getItem("auth_token")) {
			if (localStorage.getItem("user_type") == "professional") return navigate("/vodcast");
			else if (localStorage.getItem("user_type") == "user") return navigate("/vodcast");
		} else {
			return navigate("/");
		}
	}, []);
};

export default useHomeValidate;
