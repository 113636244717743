import {
  AspectRatio,
  Box,
  Button,
  CssBaseline,
  CssVarsProvider,
  Grid,
  IconButton,
  Input,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos } from "../../services/pexels";
import {
  // RiBox1Line,
  RiHistoryLine,
  RiLineChartLine,
  RiSearchLine,
  RiUpload2Line,
  RiUploadLine,
} from "react-icons/ri";
// import { RiBox2Fill } from "react-icons/ri";
import { FiBox } from "react-icons/fi";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import VideoThumbnailCard from "../components/VideoThumbnailCard";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { motion } from "framer-motion";
import ProgressBar from "../components/VodcastPlayer/ProgressBar";
import ProcessingVideo from "../UploadVideoDialogs/ProcessingVideo";

function VodcastHomePage() {
  const scrollRef = useRef();
  const navigate = useNavigate();
  const [videosData, setVideosData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [loadingRelease, setLoadingRelease] = useState(true);

  const [loadingTrending, setLoadingTrending] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [history, setHistory] = useState([]);
  const [newRelease, setNewRelease] = useState([]);
  const [trendings, setTrendings] = useState([]);

  const [isHovered, setIsHovered] = useState(false);
  const [homeData, setHomeData] = useState({
    new_release: [],
    trending: [],
    popular: [],
  });
  const [category, setCategory] = useState([
    "Music Learning",
    "Office Hour",
    "Music Culture",
    "Music Fest",
  ]);

  const { user } = useSelector((state) => state.auth);

  const [activeCategory, setActiveCategory] = useState("Music Learning");

  const [videoStatus, setVideoStatus] = useState("none");


  const isPro = user?.user_type === "professional";

  async function getData(url = "", methods = "") {
    const response = await fetch(url, {
      method: methods,
      headers: {
        "Content-Type": "application/json",
        authorization:
          localStorage.getItem("auth_token") ||
          sessionStorage.getItem("auth_token"),
      },
    });
    return response.json();
  }

  useEffect(() => {
    setLoadingCategory(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/admin/get-vodcast-categories`,
      "GET"
    ).then((res) => {
      setLoadingCategory(false);
      if (res.status == true) {
        setCategory(res?.data);
      } else {
        console.log("incorrect");
      }
    });
  }, []);

  const fetchData = () => {
    setLoading(true);
    getData(`${process.env.REACT_APP_BASE_URL}/vodcast/home-data`, "GET").then(
      (res) => {
        setLoading(false);
        if (res.status == true) {
          setHomeData(res.data);
        } else {
          console.log("incorrect");
        }
      }
    );
  };
  const fetchNewRelease = () => {
    setLoadingRelease(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/new-release`,
      "GET"
    ).then((res) => {
      setLoadingRelease(false);
      if (res.status == true) {
        setNewRelease(res.data);
      } else {
        console.log("incorrect");
      }
    });
  };

  const fetchTrending = () => {
    setLoadingTrending(true);
    getData(`${process.env.REACT_APP_BASE_URL}/vodcast/trendings`, "GET").then(
      (res) => {
        setLoadingTrending(false);
        if (res.status == true) {
          setTrendings(res.data);
        } else {
          console.log("incorrect");
        }
      }
    );
  };

  const fetchHistory = () => {
    setLoadingHistory(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/get-history`,
      "GET"
    ).then((res) => {
      setLoadingHistory(false);
      if (res.status == true) {
        setHistory(res.data);
      } else {
        console.log("incorrect");
      }
    });
  };

  useEffect(() => {
    fetchData();
    fetchHistory();
    fetchNewRelease();
    fetchTrending();
  }, []);

  const allTags = [
    "Lifestyle",
    "Culture",
    "General Topics",
    "Traditional",
    "Modern",
    "Rap",
    "LoFi",
    "Others",
  ];

  // useEffect(() => {
  //   setLoading(true);
  //   getVideos("").then((vD) => {
  //     // setVideosData(vD);
  //     setLoading(false);
  //   });
  // }, []);

  return (
    <>
      <ProgressBar />
      <CommonLayout
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        setVideoStatus={setVideoStatus}
        onUploadedCallback={() => {
          fetchData();
          fetchHistory();
          fetchNewRelease();
          fetchTrending();
        }}
      >
        <Grid
          container
          lg={12}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Grid xs={6} sm={6} md={6} lg={7}>
            <BigTitle shimmer={loading}>Vodcast</BigTitle>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={5}
            sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}
          >
            <ActionButton
              showIconForMobile
              onClick={() => navigate("/vodcast/history")}
              shimmer={loading}
              title="History"
              sx={{ backgroundColor: "#303030", color: "#A1A1A1" }}
              startDecorator={<RiHistoryLine size={16} />}
            />
            {isPro && (
              <ActionButton
                showIconForMobile
                onClick={() => navigate("/vodcast/manage")}
                shimmer={loading}
                title="Manage Vodcast"
                sx={{ backgroundColor: "#303030", color: "#A1A1A1" }}
                startDecorator={<FiBox scale={16} />}
              // startDecorator={<RiBoxingLine size={16} />}
              />
            )}
            {isPro && (
              <ActionButton
                showIconForMobile
                onClick={() => {
                  setShowUpload(true);
                }}
                shimmer={loading}
                title="Upload Vodcast"
                sx={{
                  backgroundColor: constants.colors.primary,
                  color: "#FFFFFF",
                }}
                startDecorator={<RiUploadLine size={16} />}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          lg={12}
          marginY={5}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mb: { xs: 2, sm: 0 },
              width: { xs: "100%", sm: "80%", md: "70%" },
              overflowY: "scroll",
            }}
          >
            {category?.map((item, index) => (
              <Skeleton
                key={index}
                variant="inline"
                sx={{ borderRadius: 30 }}
                loading={loadingCategory}
              >
                <Button
                  // onClick={() => setActiveCategory(item)}
                  onClick={() => navigate(`/vodcast/${item.name}`)}
                  sx={[
                    {
                      minWidth: { xs: 80, sm: 100, md: 100 },
                      minHeight: { xs: 30, sm: 30, md: 35 },
                      borderRadius: 30,
                      backgroundColor: "#303030",
                      color: "#515151",
                      fontSize: { xs: 10, sm: 12, md: 14, lg: 14 },
                    },
                    activeCategory === item && {
                      backgroundColor: constants.colors.primary,
                      color: "#fff",
                    },
                  ]}
                >
                  {item.name}
                </Button>
              </Skeleton>
            ))}
          </Stack>
          <Skeleton
            variant="inline"
            sx={{ borderRadius: 30, my: { xs: 1 } }}
            loading={loading}
          >
            <Grid xs={12} sm={3} md={3} lg={2.5}>
              <Input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value !== "") {
                      navigate(`/vodcast/search?value=${e.target.value}`);
                    }
                  }
                }}
                variant="outlined"
                placeholder="Search"
                sx={{
                  borderRadius: { xs: 16, sm: 16 },
                  background: "transparent",
                  borderColor: "#565656",
                  color: "#fff",
                  fontSize: "sm",
                  "--Input-placeholderOpacity": 0.2,
                }}
                startDecorator={<RiSearchLine />}
              />
            </Grid>
          </Skeleton>
        </Grid>
        {/*  <Grid
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        container
        lg={12}
        marginY={3}
        alignItems="center"
        sx={{ position: "relative" }}
        // paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
      >
        {isHovered && (
          <IconButton
            onClick={() => {
              scrollRef.current.scrollBy({
                top: 0,
                left: -400,
                behavior: "smooth",
              });
            }}
            sx={{
              ":hover": { background: "#12121250" },
              width: "50px",
              height: "90%",
              display: {
                xs: "none",
                sm: "flex",
              },
              position: "absolute",
              zIndex: 2,
              top: 0,
              bottom: 0,
              background: "#12121210",
              borderRadius: 0,
            }}
          >
            <FaChevronLeft size={20} />
          </IconButton>
        )}
        {isHovered && (
          <IconButton
            onClick={() => {
              scrollRef.current.scrollBy({
                top: 0,
                left: +400,
                behavior: "smooth",
              });
            }}
            sx={{
              ":hover": { background: "#12121290" },
              width: "50px",
              height: "90%",
              display: {
                xs: "none",
                sm: "flex",
              },
              position: "absolute",
              zIndex: 2,
              top: 0,
              bottom: 0,
              right: 0,
              background: "#12121210",
              borderRadius: 0,
            }}
          >
            <FaChevronRight size={20} />
          </IconButton>
        )}
        <Stack
          ref={scrollRef}
          direction="row"
          spacing={2}
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
          sx={{
            position: "relative",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          {loading &&
            [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
              <Skeleton key={index} variant="rectangular" loading={loading}>
                <AspectRatio ratio={16 / 9}>
                  <VideoThumbnailCard data={item} key={index} />
                </AspectRatio>
              </Skeleton>
            ))}
          {!loading &&
            homeData?.popular?.map((item, index) => (
              <VideoThumbnailCard data={item} key={index} />
            ))}
        </Stack>
      </Grid> */}
        <ProcessingVideo
          videoStatus={videoStatus}
        />
        <SectionTitle
          title="New Release"
          headerRight={
            <ShowMoreTextButton
              onClick={() => navigate("/vodcast/new_release")}
            />
          }
        />
        {/* <PaddingContainer> */}
        <Grid
          container
          // spacing={2}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          lg={12}
          marginY={3}
          alignItems="center"
          sx={{ position: "relative" }}
        >
          {/* {loadingRelease &&
            [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
              <VideoListCard shimmer={loading} data={item} key={index} />
            ))}
          {!loadingRelease &&
            newRelease
              ?.slice(0, 8)
              ?.map((item, index) => <VideoListCard data={item} key={index} />)} */}
          {isHovered && (
            <IconButton
              onClick={() => {
                scrollRef.current.scrollBy({
                  top: 0,
                  left: -400,
                  behavior: "smooth",
                });
              }}
              sx={{
                ":hover": { background: "#12121250" },
                width: "50px",
                height: "90%",
                display: {
                  xs: "none",
                  sm: "flex",
                },
                position: "absolute",
                zIndex: 2,
                top: 0,
                bottom: 0,
                background: "#12121210",
                borderRadius: 0,
              }}
            >
              <FaChevronLeft size={20} />
            </IconButton>
          )}
          {isHovered && (
            <IconButton
              onClick={() => {
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +400,
                  behavior: "smooth",
                });
              }}
              sx={{
                ":hover": { background: "#12121290" },
                width: "50px",
                height: "90%",
                display: {
                  xs: "none",
                  sm: "flex",
                },
                position: "absolute",
                zIndex: 2,
                top: 0,
                bottom: 0,
                right: 0,
                background: "#12121210",
                borderRadius: 0,
              }}
            >
              <FaChevronRight size={20} />
            </IconButton>
          )}
          <Stack
            ref={scrollRef}
            direction="row"
            spacing={2}
            paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
            sx={{
              position: "relative",
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            {loading &&
              [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                <Skeleton key={index} variant="rectangular" loading={loading}>
                  <AspectRatio ratio={16 / 9}>
                    <VideoListCard data={item} key={index} />
                  </AspectRatio>
                </Skeleton>
              ))}

            {!loading &&
              newRelease?.map((item, index) => {
                return <VideoThumbnailCard data={item} key={index} />;
              })}
          </Stack>
        </Grid>
        {/* </PaddingContainer> */}
        <SectionTitle
          title="Recently Watched"
          headerRight={
            <ShowMoreTextButton onClick={() => navigate("/vodcast/history")} />
          }
        />
        <PaddingContainer>
          <Grid container spacing={2}>
            {loadingHistory &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard shimmer={loading} data={item} key={index} />
              ))}
            {!loadingHistory &&
              history
                ?.slice(0, 8)
                ?.map((item, index) => (
                  <VideoListCard data={item} key={index} />
                ))}
            {!loadingHistory && history.length === 0 && (
              <Box sx={{ px: 1, marginY: 5 }}>
                <Typography color="neutral" sx={{ opacity: 0.5 }}>
                  No Watched History
                </Typography>
              </Box>
            )}
          </Grid>
        </PaddingContainer>
        {/* <Grid
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 5,
        }}
      >
        <Skeleton
          loading={loading}
          variant="inline"
          sx={{ width: "80%", aspectRatio: 1207 / 338 }}
        >
          <img
            src={constants.dummy.banner}
            style={{ width: "80%", aspectRatio: 1207 / 338 }}
          />
        </Skeleton>
      </Grid> */}
        <SectionTitle
          title="Trending"
          titleRightIcon={<RiLineChartLine style={{ marginLeft: 5 }} />}
          headerRight={
            <ShowMoreTextButton
              onClick={() => navigate("/vodcast/trendings")}
            />
          }
        />
        <PaddingContainer>
          <Grid container spacing={2}>
            {loadingTrending &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard shimmer={loading} data={item} key={index} />
              ))}
            {!loadingTrending &&
              trendings
                ?.slice(0, 8)
                ?.map((item, index) => (
                  <VideoListCard data={item} key={index} />
                ))}
          </Grid>
        </PaddingContainer>
        <Grid
          xs={12}
          sx={{ backgroundColor: "#242424", py: { xs: 4, md: 8 }, my: 5 }}
        >
          <PaddingContainer>
            <Grid container spacing={1.5}>
              {category?.map((item, index) => (
                <Grid xs={4} lg={3} key={index}>
                  <Button
                    onClick={() => navigate(`/vodcast/${item.name}`)}
                    sx={{
                      width: "100%",
                      aspectRatio: 367 / 156,
                      borderRadius: 0,
                      backgroundColor: "#1E1E1E",
                      color: "#FFFFFF",
                      fontWeight: "500",
                    }}
                    key={index}
                  >
                    {item.name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </PaddingContainer>
        </Grid>
        {/* <SectionTitle
        title="Traditional"
        headerRight={
          <ShowMoreTextButton
            onClick={() => navigate("/vodcast/Traditional")}
          />
        }
      /> */}
        {/* <PaddingContainer>
        <Grid container spacing={2}>
          {loading &&
            [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
              <VideoListCard shimmer={loading} data={item} key={index} />
            ))}
          {!loading &&
            homeData.new_release
              ?.slice(0, 8)
              ?.map((item, index) => <VideoListCard data={item} key={index} />)}
        </Grid>
      </PaddingContainer> */}
      </CommonLayout>
    </>
  );
}

export default VodcastHomePage;
