import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import cloneDeep from "lodash/cloneDeep";
import { videoListState, canvasState, zoomState } from "../atoms";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

const AiVoice = () => {
  const [text, setText] = useState("");
  const [voiceType, setVoiceType] = useState("Voice 1");
  const [isGeneratingVoice, setIsGeneratingVoice] = useState(false);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const [zoom, setZoom] = useRecoilState(zoomState);

  const voiceTypes = ["Voice 1", "Voice 2", "Voice 3", "Voice 4"];

  async function setVideo(e) {
    var video = document.createElement("video");
    video.src = e;

    function setDuration() {
      let duration = video.duration * 1000;

      let vl = cloneDeep(videoList);

      let starttime = 0;
      if (videoList.length > 0) {
        starttime = videoList[videoList.length - 1].endTime;
      }

      let c = cloneDeep(canvas);
      if (c.width < video.videoWidth) {
        c.width = video.videoWidth;
      }
      if (c.height < video.videoHeight) {
        c.height = video.videoHeight;
      }

      setCanvas(c);

      vl.push({
        id: videoList.length,
        src: e,
        file: e,
        duration: duration,
        x: null,
        y: null,
        startTime: starttime,
        endTime: starttime + duration,
        trimmStart: 0.0,
        trimmEnd: 0.0,
      });

      setVideoList(vl);
      setZoom(10000);
    }
    video.addEventListener("loadedmetadata", setDuration);
  }

  const generateVoice = () => {
    setIsGeneratingVoice(true);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/tts",
        {
          text,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setVideo(url);
        setIsGeneratingVoice(false);
      })
      .catch((error) => {
        console.error("Error generating voice:", error);
        setIsGeneratingVoice(false);
      });
  };

  return (
    <div style={{ padding: "2vh" }}>
      <Form>
        <Form.Group controlId="formTextInput">
          <Form.Control
            as="textarea"
            rows={3}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your text you want to generate audio for"
          />
        </Form.Group>

        <Button
          style={{ marginTop: "2vh" }}
          variant="primary"
          onClick={generateVoice}
        >
          {isGeneratingVoice ? (
            <Spinner animation="border" size="sm" className="mr-2" />
          ) : null}
          Generate Voice
        </Button>
      </Form>
    </div>
  );
};

export default AiVoice;
