import React from "react";
import styles from "../../css/Payment.module.scss";

const PaymentSuccessful = () => {
	return (
		<div className={styles.PaymentSuccessful}>
			<div className={styles.Container}>
				<svg width="213" height="213" viewBox="0 0 213 213" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="106.5" cy="106.5" r="106.5" fill="url(#paint0_radial_2_293)" fillOpacity="0.2" />
					<circle cx="107" cy="107" r="92" fill="#50B700" fillOpacity="0.23" />
					<circle cx="107" cy="107" r="78" fill="#50B700" fillOpacity="0.23" />
					<g clipPath="url(#clip0_2_293)">
						<rect x="51" y="50" width="115" height="115" rx="57.5" fill="white" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M51 107.5C51 92.25 57.058 77.6247 67.8414 66.8414C78.6247 56.058 93.25 50 108.5 50C123.75 50 138.375 56.058 149.159 66.8414C159.942 77.6247 166 92.25 166 107.5C166 122.75 159.942 137.375 149.159 148.159C138.375 158.942 123.75 165 108.5 165C93.25 165 78.6247 158.942 67.8414 148.159C57.058 137.375 51 122.75 51 107.5ZM105.219 132.11L138.323 90.7253L132.343 85.9413L104.115 121.216L84.12 104.556L79.2133 110.444L105.219 132.118V132.11Z"
							fill="#50B700"
						/>
					</g>
					<defs>
						<radialGradient
							id="paint0_radial_2_293"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(106.5 106.5) rotate(90) scale(106.5)">
							<stop stopColor="#471CF1" stopOpacity="0" />
							<stop offset="1" stopColor="#471CF1" stopOpacity="0.26" />
						</radialGradient>
						<clipPath id="clip0_2_293">
							<rect x="51" y="50" width="115" height="115" rx="57.5" fill="white" />
						</clipPath>
					</defs>
				</svg>

				<h1>Payment Done successfully</h1>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc augue sagittis, ligula ligula mauris diam. Nam
					dolor adipiscing egestas sed amet. Adipiscing nulla massa vel cursus tortor tincidunt sit risus. Bibendum in
					feugiat cursus
				</p>
				<button>Go Back</button>
			</div>
		</div>
	);
};

export default PaymentSuccessful;
