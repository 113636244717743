import { Link, useSearchParams } from "react-router-dom";
import Navbar from "./components/navbar";
import HeroSection from "./components/hero";
import SecondSection from "./components/second";
import ThirdSection from "./components/thrid";
import ForthSection from "./components/forth";
import ContactSection from "./components/contact";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./globals.css";

export default function AltLandingPage() {
  const ref = useRef(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const gotoDownload = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (searchParams.get("show") === "download") {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchParams]);
  return (
    <main
      className="w-full"
      style={{
        width: "100vw",
        height: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      <div className="w-full">
        <Navbar gotoDownload={gotoDownload} navigate={navigate} />
        <HeroSection gotoDownload={gotoDownload} navigate={navigate} />
        <SecondSection gotoDownload={gotoDownload} navigate={navigate} />

        <ThirdSection gotoDownload={gotoDownload} navigate={navigate} />

        <ForthSection gotoDownload={gotoDownload} navigate={navigate} />
        <div className="AppScroller"></div>
        <a id="download" ref={ref} className="w-full h-auto pt-22 pb-22 ">
          <div className="promo" style={{ position: "relative" }}>
            <div
              className="promocontent px-10 md:px-10 h-full display-flex"
              style={{
                height: "100%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="text-2lg uppercase font-medium tracking-wide sm:py-0 py-6 ">
                Download Our App
              </p>
              <h1 className="sm:text-6xl text-3xl font-semibold pt-6">
                Take Your Musical <br /> Universe on the Go
              </h1>
              <br />
              <div className="flex gap-10">
                <Link
                  to={"https://apps.apple.com/in/app/music-book/id1665164896"}
                >
                  <img
                    src={"/Apple.png"}
                    style={{ width: 160 }}
                    width={160}
                    height={80}
                    alt="Apple Store Link"
                  ></img>
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.musicbook.app&pcampaignid=web_share"
                  }
                >
                  <img
                    src={"/Google.png"}
                    style={{ width: 180 }}
                    width={180}
                    height={80}
                    alt="Apple Store Link"
                  ></img>
                </Link>
              </div>
            </div>
            <div
              className="flex justify-center align-bottom"
              style={{ bottom: 0 }}
            >
              <img
                src={"/AppMockup.png"}
                style={{ width: 600 }}
                width={600}
                // height={300}
                alt="Mobile Mock Up"
              ></img>
            </div>
          </div>
        </a>
        <ContactSection navigate={navigate} />
      </div>
    </main>
  );
}
