import { Box, Typography } from "@mui/joy";
import React from "react";
import PaddingContainer from "../../layouts/PaddingContainer";
import { RiLineChartLine } from "react-icons/ri";

function SectionTitle({ title, titleRightIcon, headerRight }) {
  return (
    <PaddingContainer>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography level="h3" sx={{ color: "#fff", fontWeight: "700" }}>
          {title}
          {titleRightIcon}
        </Typography>
        {headerRight}
      </Box>
    </PaddingContainer>
  );
}

export default SectionTitle;
