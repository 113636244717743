import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../components/Hooks/axios";

function ProfessionalNotification({ setShow2 }) {
  const { userId } = useSelector((state) => state.auth);
  const [allNotification, setAllNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (userId === "") return;
    setIsLoading(true);
    axios
      .get(`/notificationUser/notifications/${userId}`)
      .then(({ data }) => {
        if (data.status) {
          setAllNotification(data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, [userId, reload]);

  const handelSeen = (id) => {
    axios
      .put(`/notificationUser/notifications/seen/${id}`)
      .then(({ data }) => {
        if (data.status) {
          setReload(Math.random());
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const handelClear = () => {
    axios
      .delete(`/notificationUser/notifications/${userId}`)
      .then(({ data }) => {
        if (data.status) {
          setReload(Math.random());
          toast.success(data.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  return (
    <div
      className="notificationMainOuterContainer"
      onClick={() => setShow2(false)}
    >
      <div
        className="responsive-notificationContainer bg-blue-950950
        "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="responsive-notification">
          <div className="responsive-popup-header">
            <p>Notifications</p>
            <button
              className="responsive-close-popup2"
              onClick={() => setShow2(false)}
            >
              X
            </button>
          </div>
          <div className="responsive-popup-body">
            {isLoading ? (
              <div className="loading">
                <ColorRing
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#ffc107",
                    "#0dcaf0",
                    "#0d6efd",
                    "#d63384",
                    "#6610f2",
                  ]}
                />
              </div>
            ) : allNotification.length === 0 ? (
              "No Notification"
            ) : (
              [...allNotification].reverse().map((data, index) => (
                <div
                  key={index}
                  onClick={() => handelSeen(data._id)}
                  className="rp-notification"
                  style={{ background: data.is_seen ? "" : "#2d68ff" }}
                >
                  <p>{data.message}</p>

                  {!data.is_seen && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handelSeen(data._id);
                      }}
                    >
                      Mark as Seen
                    </button>
                  )}
                </div>
              ))
            )}
          </div>
          <button className="responsive-clear-btn" onClick={handelClear}>
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
}
export default ProfessionalNotification;
