import { Box, CircularProgress, Modal } from "@mui/joy";
import React from "react";

const LoadingModal = ({ open }) => {
  return (
    <Modal open={open} sx={{ justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          variant="outlined"
          size="lg"
          slotProps={{
            root: {
              sx: {
                borderWidth: 0,
              },
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            width: "50px",
            aspectRatio: 1,
            borderRadius: "25px",
            overflow: "hidden",
            position: "absolute",
          }}
        >
          <img src="logoM.png" style={{ width: "100%", height: "100%" }} />
        </Box>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
