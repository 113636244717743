import { useEffect, useState } from "react";

export default function ProcessingVideo({ videoStatus }) {

    const [text, setText] = useState("none");

    const [success, setSuccess] = useState(false);

    console.log('Processing');
    console.log(videoStatus);



    useEffect(() => {
        if (videoStatus == 'processing') {
            setText("Processing...");
            setSuccess(false);
        } else if (videoStatus == 'completed') {
            setText("Completed");
            setSuccess(true);
        }
    }, [videoStatus])

    return (

        videoStatus != 'none' ?
            <div className={`block py-8 mx-16 px-16  text-white rounded-xl ${success ? 'bg-green-500' : 'bg-gray-500'}`}>
                {text}
            </div>
            : <></>

    );
}