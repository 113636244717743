import { Box, Button } from "@mui/joy";
import React from "react";
import { RiPauseFill, RiPlayFill } from "react-icons/ri";

function BigPlayButton({ onClick, playing }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        minWidth: 40,
        minHeight: 40,
        width: { xs: 40 },
        aspectRatio: 1,
        borderRadius: 100,
        p: 1,
        backgroundColor: "#1C153799",
      }}
    >
      {!playing && <RiPlayFill size={24} />}
      {playing && <RiPauseFill size={24} />}
    </Button>
  );
}

export default BigPlayButton;
