
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/slices/AuthSlice";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    dispatch(logout());
    navigate("/", { replace: true });
  }, []);

  return (<></>);
}

export default LogoutPage;
