import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCreateAlbumMutation } from "../../Redux/api/albumApi";
import frame from "../../images/Frame.png";
import arrowIcon from "../../images/arrowIcon.png";
import { useSelector } from "react-redux";
import { createNotification } from "../../components/Hooks/CreateNotification";
// import uploadIcon from "../../images/uploadIcon.png";

const CreateAlbum = ({ setShowAlbumPopup, setShowCreateAlbum, setCreatedAlbumId }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [coverPic, setCoverPic] = useState("");
	const [file, setFile] = useState([]);
	const coverPicRef = useRef();
	const [disableBtn, setDisableBtn] = useState(false);
	const { userId } = useSelector((state) => state.auth);

	// const [videoAudioFile, setVideoAudioFile] = useState([]);
	// const videoAudio = useRef();
	// const handelVideoAudio = (e) => {
	// 	const file = e.target.files[0];
	// 	if (file) {
	// 		setVideoAudioFile(file);
	// 		console.log(file);
	// 		e.target.value = null;
	// 	}
	// };

	const handelCoverPic = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
			setCoverPic(URL.createObjectURL(file));
			e.target.value = null;
		}
	};

	const [createAlbum] = useCreateAlbumMutation();
	const { uploadProgress } = useSelector((state) => state.albumSlice);

	const handelCreateAlbum = async () => {
		if (title === "" || description === "" || file === []) return toast.warn("Please fill all fields !");
		setDisableBtn(true);

		const { data } = await createAlbum({
			title: title,
			file: file,
			description: description,
		});
		if (data.status === true) {
			createNotification(userId, "Album", `You created ${data.data.title} album`);
			toast.success("Album Created successfully");
			setDisableBtn(false);
			setCreatedAlbumId(data.data._id);
			setShowAlbumPopup(true);
			setShowCreateAlbum(false);
		} else {
			toast.error("Something went wrong !");
		}
	};

	return (
		<div className="albumPopup">
			<button className="closeAlbumBtn" onClick={() => setShowCreateAlbum(false)}>
				<img src={arrowIcon} alt="" />
			</button>
			<div className="innerAlbumPopup">
				<h1 className="albumHeading">Create Album</h1>
				<div className="albumCoverPictureDiv" onClick={() => coverPicRef.current.click()}>
					{coverPic ? (
						<img src={coverPic} alt="" className="coverPic" />
					) : (
						<>
							<img src={frame} alt="" />
							<h3>Upload Cover Picture</h3>
						</>
					)}
					<input type="file" name="" id="" ref={coverPicRef} style={{ display: "none" }} onChange={handelCoverPic} />
				</div>

				<div className="inputGroup">
					<label htmlFor="Title">Title</label>
					<input
						type="text"
						id="Title"
						placeholder="Place enter a title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>

				<div className="inputGroup" id="InfoContainer">
					<label htmlFor="Info">Info</label>
					<textarea
						placeholder="Place enter info"
						id="Info"
						value={description}
						onChange={(e) => setDescription(e.target.value)}></textarea>
				</div>

				{/* <div className="albumVideoAudio" onClick={() => videoAudio.current.click()}>
					<img src={uploadIcon} alt="" />
					<h3>Upload video / Audio File</h3>
					<input type="file" name="" id="" ref={videoAudio} style={{ display: "none" }} onChange={handelVideoAudio} />
				</div> */}

				<div className="btnContainer">
					<button type="button" className="createAlbumBtn" onClick={handelCreateAlbum} disabled={disableBtn}>
						<span style={{ width: `${uploadProgress}%` }}></span>
						<p>{uploadProgress === 0 ? "Create Album" : uploadProgress + "%"}</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default CreateAlbum;
