import React from "react";
import useProfessionalValidate from "../components/Hooks/useProfessionalValidate";
import Header from "./Professional_header";

const ActiveHoursProfessional = () => {
	// useProfessionalValidate();

	return (
		<div className="professionalMainContainer ActiveHours">
			<Header />
			<div className="ProfessionalBody">
				<div className="textContainer">
					<h2>Active Hours</h2>
					<h3>Time you spent on Music book</h3>
					<hr />
					<h1>50 hr</h1>
					<h6>
						Over all time you spent on <br /> Music Book
					</h6>
				</div>
				<div className="ChartContainer">
					<div className="ChartColumnContainer">
						<div className="ChartTime">8 hr</div>
						<div className="ChartDiv" style={{ height: "80px" }}></div>
						<div className="ChartDay">Mon</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">12 hr</div>
						<div className="ChartDiv" style={{ height: "120px" }}></div>
						<div className="ChartDay">Tue</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">15 hr</div>
						<div className="ChartDiv" style={{ height: "150px" }}></div>
						<div className="ChartDay">Wen</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">6 hr</div>
						<div className="ChartDiv" style={{ height: "60px" }}></div>
						<div className="ChartDay">Thu</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">2 hr</div>
						<div className="ChartDiv" style={{ height: "20px" }}></div>
						<div className="ChartDay">Fri</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">10 hr</div>
						<div className="ChartDiv" style={{ height: "100px" }}></div>
						<div className="ChartDay">Sat</div>
					</div>
					<div className="ChartColumnContainer">
						<div className="ChartTime">24 hr</div>
						<div className="ChartDiv" style={{ height: "240px" }}></div>
						<div className="ChartDay">Sun</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ActiveHoursProfessional;
