import { Button } from "@mui/joy";
import React from "react";
import constants from "../../configs/constants";

function ShowMoreTextButton({ onClick }) {
  return (
    <Button
      onClick={onClick}
      variant="plain"
      sx={{
        color: "#FFFFFF73",
        paddingX: 0,
        ":hover": {
          backgroundColor: "transparent",
          color: constants.colors.primary,
        },
      }}
    >
      Show More
    </Button>
  );
}

export default ShowMoreTextButton;
