import Button from 'react-bootstrap/Button';
import { videoListState, textListState, subtitleDefaultXState, subtitleDefaultYState, subtitleDefaultSizeState, subtitleDefaultColorState } from '../atoms';
import { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import axios from "axios";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';
import cloneDeep from 'lodash/cloneDeep';
import { SketchPicker } from 'react-color';








const Subtitles = () => {
  const [textList, setTextList] = useRecoilState(textListState);
  const [subtitleDefaultX, setSubtitleDefaultX] = useRecoilState(subtitleDefaultXState);
  const [subtitleDefaultY, setSubtitleDefaultY] = useRecoilState(subtitleDefaultYState);
  const [subtitleDefaultSize, setSubtitleDefaultSize] = useRecoilState(subtitleDefaultSizeState);
  const [subtitleDefaultColor, setSubtitleDefaultColor] = useRecoilState(subtitleDefaultColorState);
  const [wordCount, setWordCount] = useState(3);


  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false)
  const [showDefault, setShowDefault] = useState(false)

  const [backgroundColor, setBackgroundColor] = useState({
    r: '248',
    g: '231',
    b: '28',
    a: '0.8',
  });







  useEffect(() => {
    if (isGenerating) {
      const interval = setInterval(() => {
        if (progress < 92) {
          setProgress(progress + 1)
        } else {
          setProgress(10)
        }
      }, 100 * videoList[0].duration / 60000);
      return () => clearInterval(interval);
    }
  })

  useEffect(() => {
    let tl = cloneDeep(textList)
    for (let i = 0; i < textList.length; i++) {
      if (tl[i].tag === "subtitle") {
        tl[i].x = subtitleDefaultX;
        tl[i].y = subtitleDefaultY;
        tl[i].size = subtitleDefaultSize;
        tl[i].color = subtitleDefaultColor;
        const rgbaColor = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`
        tl[i].backgroundColor = rgbaColor
      }

    }

    setTextList(tl);
  }, [subtitleDefaultSize, subtitleDefaultX, subtitleDefaultY, subtitleDefaultColor, backgroundColor])


  function mergeWords(arr, wordcount) {
    const result = [];

    for (let i = 0; i < arr.length; i += wordcount) {
      let wordsToMerge = arr.slice(i, i + wordcount);

      let merged = {
        word: wordsToMerge.map(w => w.text).join(' '),
        start: wordsToMerge[0].start,
        end: wordsToMerge[wordsToMerge.length - 1].end
      };

      result.push(merged);
    }

    return result;
  }



  function addSubtitlesToVideo(data) {
    let newArr = [...textList]


    for (let i = 0; i < data.length; i++) {
      let text = data[i].word
      newArr.push({ "id": textList.length + i, group: 1, tag: "subtitle", "text": " " + text + " ", "x": 0, "y": 0, startTime: data[i].start * 1000, endTime: data[i].end * 1000, color:"#000000", backgroundColor:'rgba(248,231,28,0.8)', size: 20, visible: false, font: "Arial" })
    }

    setTextList(newArr);

  }

  function changebackgroundcolor(color) {
    setBackgroundColor({ r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a })

    //newArr[element].backgroundColor = rgbaColor
    //setTextList(newArr);
  }

  function generateSubtitles() {
    setIsGenerating(true)
    let form = new FormData();
    form.append("file", videoList[0].file);

    axios.post(process.env.REACT_APP_BACKEND_URL + "/subtitles", form).then(response => {
      setIsGenerating(false)
      setProgress(0)
      addSubtitlesToVideo(mergeWords(response.data, wordCount))
      setShowDefault(true)
    })
  }

  return (<div style={{ color: "#F7F7F7", padding: "2vh" }}>

    {isGenerating ?
      <div>
        <h3 style={{ display: "flex", color: "white" }}>Generating Subtitles...</h3>
        <ProgressBar now={progress} />
      </div>
      :
      <div>
        <Form.Label>Words per Subtitle Block</Form.Label>
        <Form.Control
          value={wordCount}
          type="number"
          onChange={e => setWordCount(parseInt(e.target.value, 10) || 3)}
        />
        <Button onClick={generateSubtitles}>Generate Subtitles</Button>
      </div>
    }
    <div>

      {showDefault ? <div><Form.Label>Distance from left</Form.Label>
        <Form.Control value={subtitleDefaultX} type="number" onChange={e => (setSubtitleDefaultX(parseInt(e.target.value)))} />
        <br />

        <Form.Label>Distance from top</Form.Label>
        <Form.Control value={subtitleDefaultY} type="number" onChange={e => (setSubtitleDefaultY(parseInt(e.target.value)))} />
        <br />

        <Form.Label>Textsize</Form.Label>
        <Form.Control value={subtitleDefaultSize} type="number" onChange={e => (setSubtitleDefaultSize(parseInt(e.target.value)))} />
        <br />

        <Form.Label>Textcolor</Form.Label>
        <HexColorPicker style={{ width: "100%" }} color={subtitleDefaultColor} onChange={e => setSubtitleDefaultColor(e)} />
        <SketchPicker
          color={backgroundColor} onChange={changebackgroundcolor} />
      </div> : null}

    </div>

  </div>)
}

export default Subtitles;