import React, { useEffect } from "react";

const AdsComponent = ({ dataAdSlot }) => {
  useEffect(() => {
    const loadAdsenseScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.onload = () => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("Adsense script error:", e);
        }
      };
      document.head.appendChild(script);
    };

    if (window.adsbygoogle) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Adsense push error:", e);
      }
    } else {
      loadAdsenseScript();
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", height: "80px" }}
      data-ad-client="ca-pub-2007858501574248"
      data-ad-slot={dataAdSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdsComponent;

// import React, { useEffect } from "react";

// const AdsComponent = (props) => {
//   const { dataAdSlot } = props;

//   useEffect(() => {
//     try {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     } catch (e) {}
//   }, []);

//   return (
//     <>
//       <ins
//         className="adsbygoogle"
//         style={{ display: "block", height: "80px" }}
//         data-ad-client="ca-pub-2007858501574248"
//         data-ad-slot={dataAdSlot}
//         data-ad-format="auto"
//         data-full-width-responsive="true"
//       ></ins>
//     </>
//   );
// };

// export default AdsComponent;
