import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import swal from "sweetalert";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";

function ForgetPasswordOTP() {
	useValidateWithoutLogin();
	async function postdata(url = "", data = {}, methods = "") {
		// Default options are marked with *
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	const [otp, setOtp] = useState("");

	function LoginBtn() {
		let data = {
			// mobile_number: localStorage.getItem("mobile_num"),
			login_email: localStorage.getItem("login_email"),
			otp: otp,
		};

		postdata("https://musicbook.co.in/api/v1/auth/verify-otp", data, "POST").then((data) => {
			if (data.status == true) {
				swal(data.message);

				window.open("/changePassword", "_self");
			} else {
				console.log("incorrect");
				swal(data.message);
			}
		});

		console.log("hiiiiii", data);
	}
	return (
		<div className="forgetpassword">
			<div className="left">
				<div className="leftHeading">Teach your audiance what they want to learn</div>
				<br />
				<br />
				<br />
				<br />
				<div className="dots">
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="card ">
				<div className="card-body fPassOTP">
					<h1 className="card-title">VERIFICATION</h1>
					<p>Your OTP is: {localStorage.getItem("forget_otp")}</p>
					<input type="text" name="" id="" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
					<p className="card-text">
						Remember me &nbsp;&nbsp;&nbsp;&nbsp; <a href="">Forgot password?</a>
					</p>
					<button className="loginWithEmail buttonFull" onClick={LoginBtn}>
						Submit
					</button>
					<button className="loginWithMobile buttonFull" onClick={() => window.open("/forget-password", "_self")}>
						Back
					</button>
					<p className="card-text">
						Resend OTP in <a href="">26 sec</a>
					</p>
				</div>
			</div>
		</div>
	);
}
export default ForgetPasswordOTP;
