import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useState } from "react";
import BigPlayButton from "./BigPlayButton";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import { RiLineChartLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import VideoThumbnail from "react-video-thumbnail";

function VideoThumbnailCard({ data }) {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  // const category = [
  //   { name: "General Topic" },
  //   { name: "Trending", icon: <RiLineChartLine size={10} /> },
  // ];
  return (
    <motion.div
      animate={isHovered ? "zoomed" : "normal"}
      variants={{
        normal: { scale: 1 },
        zoomed: { scale: 1.02, perspective: "2px" },
      }}
    >
      <AspectRatio
        onClick={() => {
          navigate(`/vodcast/player/${data?._id}`);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseOut={() => {
          setIsHovered(false);
        }}
        sx={{
          width: {
            lg: 400,
            md: 300,
            sm: 300,
            xs: 300,
          },
          position: "relative",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <motion.div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            animate={isHovered ? "hide" : "show"}
            variants={{
              show: { opacity: 1 },
              hide: { opacity: 0 },
            }}
          >
            {data?.media?.[0]?.thumbnail ? (
              <img
                src={data?.media?.[0]?.thumbnail}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <VideoThumbnail
                cors={true}
                videoUrl={data?.media?.[0]?.file}
                // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
              />
            )}
          </motion.div>
          <motion.div
            style={{
              display: "flex",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            animate={isHovered ? "show" : "hide"}
            variants={{
              show: { opacity: 1 },
              hide: { opacity: 0 },
            }}
          >
            <ReactPlayer
              muted={true}
              width="100%"
              height="100%"
              playing={isHovered}
              url={data?.media?.[0]?.file}
              style={{ width: "100%", height: "100%" }}
            />
          </motion.div>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%)",
            }}
          />
          <motion.div
            style={{ display: "flex", margin: 0 }}
            animate={isHovered ? "change" : "normal"}
            variants={{
              normal: { opacity: 0.8, scale: 1 },
              change: { opacity: 1, scale: 1.2 },
            }}
          >
            <BigPlayButton />
          </motion.div>
          <Box sx={{ position: "absolute", bottom: 0, left: 0 }}>
            <Stack direction="row" spacing={1} sx={{ px: 1, pb: 0.5 }}>
              {data?.category?.map((item, index) => (
                <Button
                  startDecorator={item?.icon}
                  size="sm"
                  sx={{
                    paddingX: 1,
                    paddingY: 0.8,
                    minHeight: 0,
                    fontSize: "60%",
                    borderRadius: 0,
                    backgroundColor: "#1C1537",

                    color: "#D1C6FF",
                  }}
                  key={index}
                >
                  {item?.name}
                </Button>
              ))}
            </Stack>
            <Typography level="title-sm" sx={{ px: 1, pb: 2, color: "#fff" }}>
              {data.title}
            </Typography>
          </Box>
        </Box>
      </AspectRatio>
    </motion.div>
  );
}

export default VideoThumbnailCard;
