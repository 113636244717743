import React, { useState, useRef } from "react";
import { useRecoilState } from "recoil";
import cloneDeep from "lodash/cloneDeep";
import { audioListState } from "../atoms";
import { Button, Container, Card, Form, Spinner } from "react-bootstrap";
import axios from "axios";

const AudioEditor = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const mediaRecorder = useRef(null);
  const [audioList, setAudioList] = useRecoilState(audioListState);

  const [text, setText] = useState("");
  const [isGeneratingVoice, setIsGeneratingVoice] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(1); // Default is voice 1

  const generateVoice = () => {
    setIsGeneratingVoice(true);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/tts",
        {
          text,
          voice: selectedVoice,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        uploadSound(response.data);
        //setVideo(url);
        setIsGeneratingVoice(false);
      })
      .catch((error) => {
        console.error("Error generating voice:", error);
        setIsGeneratingVoice(false);
      });
  };

  function uploadSound(sound) {
    var audio = document.createElement("audio");
    audio.src = URL.createObjectURL(sound);

    function setDuration() {
      let duration = audio.duration * 1000;

      let al = cloneDeep(audioList);

      let starttime = 0;
      if (audioList.length > 0) {
        starttime = audioList[audioList.length - 1].endTime;
      }

      al.push({
        id: audioList.length,
        src: URL.createObjectURL(sound),
        file: sound,
        duration: duration,
        startTime: starttime,
        endTime: starttime + duration,
        trimmStart: 0.0,
        trimmEnd: 0.0,
      });

      setAudioList(al);
    }
    audio.addEventListener("loadedmetadata", setDuration);
  }

  const startRecording = async () => {
    if (isRecording) return;

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    const recorder = new MediaRecorder(mediaStream);
    mediaRecorder.current = recorder;

    recorder?.addEventListener("dataavailable", (event) => {
      const audioBlob = event.data;
      uploadSound(audioBlob);
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
    });

    recorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (!isRecording) return;

    mediaRecorder.current.stop();
    setIsRecording(false);
  };

  return (
    <Container>
      <Card
        className="mt-3 p-3"
        style={{ paddingTop: "2vh", backgroundColor: "#1E2235" }}
      >
        <h3 style={{ color: "white" }}>Audio Recorder</h3>
        <div className="d-flex justify-content-center">
          <Button
            onClick={startRecording}
            disabled={isRecording}
            className="mx-2"
          >
            Start Recording
          </Button>
          <Button
            onClick={stopRecording}
            disabled={!isRecording}
            className="mx-2"
          >
            Stop Recording
          </Button>
        </div>
        {audioURL && (
          <Card.Body>
            <audio src={audioURL} controls />
          </Card.Body>
        )}
      </Card>

      <Card style={{ marginTop: "2vh", backgroundColor: "#1E2235" }}>
        <div style={{ padding: "2vh" }}>
          <Form>
            <Form.Group controlId="formTextInput">
              <Form.Control
                as="textarea"
                rows={3}
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter your text you want to generate audio for"
              />
            </Form.Group>
            <Form.Group controlId="voiceSelection">
              <Form.Label>Select a Voice</Form.Label>
              <Form.Select
                value={selectedVoice}
                onChange={(e) => setSelectedVoice(Number(e.target.value))}
              >
                {[...Array(109).keys()].map((i) => (
                  <option key={i + 1} value={i + 1}>
                    Voice {i + 1}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button
              style={{ marginTop: "2vh" }}
              variant="primary"
              onClick={generateVoice}
            >
              {isGeneratingVoice ? (
                <Spinner animation="border" size="sm" className="mr-2" />
              ) : null}
              Generate Voice
            </Button>
          </Form>
        </div>
      </Card>
    </Container>
  );
};

export default AudioEditor;
