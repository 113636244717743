import React from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../Redux/slices/TempSlice";

const ThreeDotPopupForOtherUser = ({ setOtherUserPopup, val, reportPopupShow, setReportPopupShow }) => {
	const dispatch = useDispatch();

	return (
		<div className="ThreeDotPopupForOtherUser">
			<button onClick={() => setOtherUserPopup(false)}>X</button>
			<div
				onClick={() => {
					setOtherUserPopup(false);
					setReportPopupShow(!reportPopupShow);
				}}>
				😡
			</div>
			<div
				onClick={() => {
					setOtherUserPopup(false);
					dispatch(setMessage({ open: true, id: val?.created_by?._id }));
				}}>
				Message
			</div>
		</div>
	);
};

export default ThreeDotPopupForOtherUser;
