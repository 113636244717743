import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { setSearch } from "../Redux/slices/TempSlice";
import sidebarImg1 from "../images/Vector (1).png";
import sidebarImg2 from "../images/Vector (2).png";
// import sidebarImg3 from "../images/Vector (3).png";
import sidebarImg4 from "../images/Vector (4).png";
// import sidebarImg5 from "../images/Vector (5).png";
import sidebarImg6 from "../images/Vector (6).png";
import Notification from "./Notification";
import SearchBar from "./SearchBar";

function Header() {
	const dispatch = useDispatch();
	const { search } = useSelector((state) => state.temp);
	const [notificationShow, setNotificationShow] = useState(false);

	return (
		<>
			{search ? <SearchBar /> : null}
			<header>
				<nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse">
					<div className="position-sticky">
						<ul type="none">
							{notificationShow && <Notification setNotificationShow={setNotificationShow} />}

							<li onClick={() => window.open("/dashboard", "_self")}>
								<NavLink to="/dashboard" title="Dashboard">
									<img src={sidebarImg1} alt="" />
								</NavLink>
							</li>
							<li
								onClick={() => dispatch(setSearch({ search: true, type: "postCaption" }))}
								style={{ cursor: "pointer" }}>
								<a title="Search">
									<img src={sidebarImg2} alt="" />
								</a>
							</li>
							<li onClick={() => setNotificationShow(true)} style={{ cursor: "pointer" }}>
								<a title="Notification">
									<img src={sidebarImg4} alt="" />
								</a>
							</li>
							{/* <li>
								<NavLink to="/learning" title="My Learning">
									<img src={sidebarImg3} alt="" />
								</NavLink>
							</li>
							<li>
								<NavLink to="/album" title="Album">
									<img src={sidebarImg5} alt="" />
								</NavLink>
							</li> */}
							<li>
								<NavLink to="/account" title="Account">
									<img src={sidebarImg6} alt="" />
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</>
	);
}
export default Header;
