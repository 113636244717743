import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import { RiArrowLeftLine, RiVideoLine } from "react-icons/ri";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import VodcastPlayer from "../components/VodcastPlayer/index.js";
import { getData } from "../../utils/httpMethods";
import swal from "sweetalert2";
import { ModalDialog } from "react-bootstrap";
import { toast } from "react-toastify";

function DraftPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [videosData, setVideosData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [totalCouunt, setTotalCount] = useState(-1);
  const [current, setCurrent] = useState(null);

  const [isShownMore, setIsShownMore] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const [loadingMore, setLoadingMore] = useState(false);

  const [deleting, setDeleting] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [published, setPublished] = useState(false);

  const [title, setTitle] = useState("");

  const sections = ["New Release", "Trending"];

  let draftData = (data && Object.values(data)?.flat()) || [];
  let active = draftData[0];
  let allData = draftData?.filter((i) => {
    if (current) {
      return i._id !== current?._id;
    } else {
      return i._id !== active?._id;
    }
  });

  useEffect(() => {
    if (active) {
      setCurrent(active);
    }
  }, [active]);

  const loadMore = () => {
    setPage((p) => p + 1);
  };

  const fetchData = useCallback(() => {
    setTotalCount(0);
    page == 1 && setData({});
    page == 1 && setLoading(true);
    page > 1 && setLoadingMore(true);

    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/get-drafts?offset=${(page - 1) * 10
      }`,
      "GET"
    ).then((res) => {
      setLoading(false);
      setLoadingMore(false);
      if (res.status == true) {
        setData((d) => ({ ...d, [page]: res.data }));
        res.data?.length > 0
          ? setTotalCount(res.total_count)
          : setTotalCount(0);

        if (res.total_count === 0) {
          setData({});
          setTotalCount(0);
          setPage(1);
        }
      } else {
        console.log("incorrect");
      }
    });
  }, [page]);

  useEffect(() => {
    fetchData();
  }, []);

  const deleteCurrentDraft = () => {
    setDeleting(true);

    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/delete-draft/${current?._id}`,
      "DELETE"
    ).then((res) => {
      setDeleting(false);
      setData({});
      setCurrent(null);
      fetchData();
    });
  };

  const publishDraft = () => {
    setPublishing(true);

    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/publish-draft/${current?._id}`,
      "GET"
    ).then((res) => {
      console.log(res);
      setPublishing(false);
      if (res.status == true) {
        toast.success("Vodcast published successfully");

        res?.data._id && navigate(`/vodcast/player/${res?.data._id}`);
        fetchData();
      }
    });
  };

  return (
    <CommonLayout>
      <Modal open={deleting}>
        <ModalDialog>
          <Box
            sx={{
              height: "100vh",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircularProgress />
            <Typography>Deleting Draft...</Typography>
          </Box>
        </ModalDialog>
      </Modal>
      <Modal open={publishing}>
        <ModalDialog>
          <Box
            sx={{
              height: "100vh",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircularProgress />
            <Typography>Publishing...</Typography>
          </Box>
        </ModalDialog>
      </Modal>
      <InfiniteScroll
        dataLength={
          (videosData && Object.values(videosData)?.flat()?.length) || 0
        }
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <Grid
          container
          lg={12}
          alignItems="center"
          paddingX={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        >
          <Grid xs={6} sm={6} md={6} lg={8}>
            <Stack direction="row">
              <IconButton
                onClick={() => navigate("/vodcast/manage")}
                sx={{ ":hover": { background: "transparent" } }}
              >
                <RiArrowLeftLine size={30} />
              </IconButton>
              <Breadcrumbs>
                {[
                  { label: "Home", link: "/vodcast" },
                  { label: "My Vodcast", link: "/vodcast/manage" },
                ].map((item) => (
                  <Link
                    key={item.label}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}
                    color="neutral"
                    href={item.link}
                  >
                    {item.label}
                  </Link>
                ))}
                <Typography fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}>
                  Draft Vodcast
                </Typography>
              </Breadcrumbs>
            </Stack>
            <BigTitle shimmer={loading}>Draft Videos</BigTitle>
          </Grid>
        </Grid>
        <PaddingContainer>
          {!loading && !current && (
            <Stack
              sx={{
                opacity: 0.5,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                py: 5,
              }}
            >
              <RiVideoLine size={"10%"} />
              <Typography>No Draft Vodcast found</Typography>
            </Stack>
          )}
          {current && (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                width: { xs: "100%", md: "80%" },
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={3}
            >
              <Grid xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ width: "100%", aspectRatio: 16 / 9 }}>
                  <VodcastPlayer
                    controls={true}
                    autoPlay={true}
                    data={current}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} sx={{}}>
                <Stack sx={{ alignItems: "flex-start" }}>
                  <Typography level="h2" sx={{ mb: 3, mt: 1 }}>
                    {current?.title}
                  </Typography>
                  <Typography
                    fontSize={{ xs: 12, sm: 12, md: 14, lg: 14 }}
                    sx={
                      !isShownMore
                        ? {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        }
                        : null
                    }
                  >
                    {current?.captions}
                  </Typography>
                  {current?.captions.length > 50 && (
                    <Button
                      onClick={() => setIsShownMore((s) => !s)}
                      variant="plain"
                      sx={{
                        py: 0,
                        px: 0,
                        minHeight: 0,
                        my: 1,
                        color: "#FFFFFF",
                        ":hover": {
                          color: "yellow",
                          background: "transparent",
                        },
                      }}
                    >
                      {isShownMore ? "Show Less" : "Read More"}
                    </Button>
                  )}
                  <Stack direction="row" sx={{ mt: 5 }} spacing={2}>
                    <Button
                      // size="sm"
                      onClick={() => {
                        swal
                          .fire({
                            iconColor: "#fff",
                            iconHtml: '<i class="ri-delete-bin-line"></i>',
                            titleText: "Delete Draft",

                            text: "Are you sure you want to delete it?",
                            color: "#fff",
                            confirmButtonText: "Delete",
                            confirmButtonColor: "red",
                            showCancelButton: true,
                            cancelButtonText: "Cancel",
                            reverseButtons: true,
                            background: "#232323",
                            dangerMode: true,
                          })
                          .then((status) => {
                            if (status.isConfirmed) {
                              deleteCurrentDraft();
                            }
                          });
                      }}
                      color="neutral"
                      sx={{
                        borderRadius: 50,
                        fontSize: 10,
                        color: "#fff",
                        minWidth: 100,
                        background: "#2F2F2F",
                      }}
                    >
                      REMOVE
                    </Button>
                    {/* <Button
                      size="sm"
                      color="neutral"
                      sx={{
                        borderRadius: 50,
                        fontSize: 10,
                        color: "#fff",
                        minWidth: 150,
                        background: "#2F2F2F",
                      }}
                    >
                      EDIT VODCAST
                    </Button> */}
                    <Button
                      onClick={publishDraft}
                      size="sm"
                      color="primary"
                      sx={{
                        borderRadius: 50,
                        fontSize: 10,
                        color: "#fff",
                        minWidth: 100,
                        background: constants.colors.primary,
                      }}
                    >
                      PUBLISH
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 6,
            }}
          >
            <Divider sx={{ width: "30%", height: 2, color: "#555555" }} />
          </Container>
        </PaddingContainer>
        {totalCouunt > 0 && (
          <SectionTitle
            title="Other Draft Videos"
            headerRight={
              <ShowMoreTextButton onClick={() => navigate("/vodcast")} />
            }
          />
        )}
        <PaddingContainer>
          <Grid container spacing={2}>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <VideoListCard
                  shimmer={loading}
                  data={item}
                  key={index}
                  disabledClick
                />
              ))}
            {!loading &&
              allData?.map((item, index) => (
                <VideoListCard
                  data={item}
                  key={index}
                  disabledClick
                  action={() => {
                    alert("click");
                    setCurrent(item);
                    window.scrollTo({ top: 0 });
                  }}
                />
              ))}
          </Grid>
        </PaddingContainer>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
          }}
        >
          {loadingMore ? (
            <CircularProgress variant="soft" />
          ) : allData.length < totalCouunt - 1 ? (
            <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
              END OF CONTENT
            </Typography>
          ) : (
            !loading &&
            allData?.length === totalCouunt - 1 && (
              <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                No Data Available
              </Typography>
            )
          )}
        </Container>
      </InfiniteScroll>
    </CommonLayout>
  );
}

export default DraftPage;
